import { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingContext } from '../../../App';
import APIServerPath from '../../Constants/APIServerPath';
import ProjectsTabCard from './ProjectsTabCard';
import axios from 'axios';

function ProjectsTab (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const userPerms = JSON.parse(localStorage.userPermissions);
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = [props.errorMsg, props.setErrorMsg];
    const [activeRows, setActiveRows] = useState([]);
    const [inactiveRows, setInactiveRows] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [newProjectCardOpen, setNewProjectCardOpen] = useState(null);
    const columns = [
        {
          field: 'projectName',
          headerName: 'Name',
          flex: 1,
          editable: false,
        },
        {
          field: 'projectDescription',
          headerName: 'Description',
          flex: 3,
          editable: false,
        }
    ];
    //retrieve project data associated with lab
    useEffect(()=> {
        setLoading(true);
        let formData = new FormData();
        formData.append("lab_ID", localStorage.labID);
        const response = client.post(
            'retrieve_projects', 
            formData,
            {headers}
            ).then(function (response) {
            if (response.data.msg) {//there should be no error messages from this query
                setErrorMsg(response.data.msg);
                setLoading(false);
            } else{//set active and inactive project states
                let allProjects = response.data;
                let activeProjects = [];
                let inactiveProjects = [];
                for (let i = 0; i < allProjects.length; i++){
                    if (allProjects[i].active === 1) {
                        activeProjects.push(allProjects[i]);
                    } else {
                        inactiveProjects.push(allProjects[i]);
                    }
                }
                setActiveRows(activeProjects);
                setInactiveRows(inactiveProjects);
                setLoading(false);
            }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });
    }, [selectedProject]);

    //handling add mode card
    const handlAddProjectCardOpen = (event) => {
        event.preventDefault();
        if (userPerms.projects) {
            setSelectedProject({
                projectID:true,
                projectName:"",
                projectDescription:"",
                active:1
            });
            setNewProjectCardOpen(true);
        }
    };

    //closes all cards
    const handleCardClose = () => {
        setSelectedProject(null);
        setNewProjectCardOpen(false);
    }

    return (
        <div>
            {selectedProject && !newProjectCardOpen && <ProjectsTabCard projectData={selectedProject} close={handleCardClose} mode="update"/>}
            {newProjectCardOpen && <ProjectsTabCard projectData={selectedProject} close={handleCardClose} mode="add"/>}
            <Grid
            container
            justifyContent="right"
            alignItems="right"
            spacing={12}>
                <Grid item xs={9}> 
                    <Typography variant="h6" align="left">
                        Active Projects
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                {userPerms.projects ?
                    <Button variant="outlined" onClick={handlAddProjectCardOpen}>Add New Project</Button>
                : null
                }
                </Grid>
            </Grid>
            <Box sx={{ height: 350, width: '100%' }}>
                <DataGrid
                    rows={activeRows}
                    columns={columns}
                    disableRowSelectionOnClick
                    onCellDoubleClick={(params, event, details)=> {
                        //check permissions to modify project information
                        //set selectedProject
                        if (userPerms.projects) {
                            setSelectedProject(params.row);
                        }
                    }}
                />
            </Box>
            <Grid
            container
            justifyContent="right"
            alignItems="right"
            spacing={12}>
                <Grid item xs={12}> 
                    <Typography variant="h6" align="left">
                        Inactive Projects
                    </Typography>
                </Grid>
            </Grid>
            <Box sx={{ height: 350, width: '100%' }}>
                <DataGrid
                    rows={inactiveRows}
                    columns={columns}
                    disableRowSelectionOnClick
                    onCellDoubleClick={(params, event, details)=> {
                        //check permissions to modify project information
                        //set selectedProject
                        if (userPerms.projects) {
                            setSelectedProject(params.row);
                        }
                    }}
                />
            </Box>
        </div>
    )
}

export default ProjectsTab;