import { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingContext } from '../../../App';
import APIServerPath from '../../Constants/APIServerPath';
import axios from 'axios';
import LabelTemplateTabCard from './LabelTemplateTabCard';

function LabelTemplateTab (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = [props.errorMsg, props.setErrorMsg];
    const [rows, setRows] = useState([]);
    const [rawTemplateData, setRawTemplateData] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [newTemplateCardOpen, setNewTemplateCardOpen] = useState(null);
    const columns = [
        {
          field: 'templateName',
          headerName: 'Name',
          flex: 1,
          editable: false,
        },
        {
          field: 'pageSize',
          headerName: 'Page Size',
          flex: 1,
          editable: false,
        },
        {
          field: 'noColumns',
          headerName: '# Columns',
          flex: 1,
          editable: false,
        },
        {
          field: 'noRows',
          headerName: '# Rows',
          flex: 1,
          editable: false,
        },
        {
          field: 'labelSize',
          headerName: 'Label Size',
          flex: 1,
          editable: false,
        }
    ];
    //retrieve label template data associated with lab
    useEffect(()=> {
        setLoading(true);
        let formData = new FormData();
        formData.append("lab_ID", localStorage.labID);
        const response = client.post(
            'retrieve_label_templates', 
            formData,
            {headers}
            ).then(function (response) {
            if (response.data.msg) {//there should be no error messages from this query
                setErrorMsg(response.data.msg);
                setLoading(false);
            } else{//
                setRows(response.data.rowData);
                setRawTemplateData(response.data.rawTemplateData);
                setErrorMsg(null);
                setLoading(false);
            }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });
    }, [selectedTemplate]);

    //handling add mode card
    const handlAddTemplateCardOpen = (event) => {
        event.preventDefault();
        setSelectedTemplate({
            "templateID" : true,
            "templateName" : "",
            "pageHeight" : 11.7,
            "pageWidth" : 8.3,
            "noColumns" : 5,
            "noRows" : 7,
            "labelHeight" : 1,
            "labelWidth" : 1,
            "columnGap" : 0,
            "rowGap" : 0,
            "leftMargin" : 1,
            "rightMargin" : 1,
            "topMargin" : 1,
            "bottomMargin" : 1,
            "labelLeftPadding" : 5,
            "labelRightPadding" : 5,
            "labelTopPadding" : 5,
            "labelBottomPadding" : 5,
            "cornerRadius" : 0,
            "qrSize" : 0,
            "qrFix" : "bl",
            "units" : "in",
            "border" : 0,
        });
        setNewTemplateCardOpen(true);
    };

    //closes all cards
    const handleCardClose = () => {
        setSelectedTemplate(null);
        setNewTemplateCardOpen(false);
    }

    return (
        <div>
            {selectedTemplate && !newTemplateCardOpen && <LabelTemplateTabCard templateData={selectedTemplate}  close={handleCardClose} mode="update"/>}
            {newTemplateCardOpen && <LabelTemplateTabCard templateData={selectedTemplate}  close={handleCardClose} mode="add"/>}
            <Grid
            container
            justifyContent="right"
            alignItems="right"
            spacing={12}>
                <Grid item xs={9}> 
                    <Typography variant="h6" align="left">
                        Label Templates
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Button variant="outlined" onClick={handlAddTemplateCardOpen}>Add New Template</Button>
                </Grid>
            </Grid>
            <Box sx={{ height: 350, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    onCellDoubleClick={(params, event, details)=> {
                        setSelectedTemplate(rawTemplateData[+params.row.id]);
                    }}
                />
            </Box>
        </div>
    )
}

export default LabelTemplateTab;