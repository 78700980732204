import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { Drawer, Toolbar, Typography, Divider, Box } from '@mui/material';
import { useEffect, useState, useCallback, Element } from 'react';

export default function LocationExplorer(props) {
    const [menuRender, setMenuRender] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const currentLocationID = props.currentLocation;

    useEffect(()=> {
        let parentLocations = [];
        const LocationMenu = (location) => {
            let nestedMenuData = [];
            location.map((subLocation, index) => {
                if (subLocation.children.length > 0) {
                    parentLocations.push(subLocation.locationID);
                    nestedMenuData.push(<TreeItem nodeId={subLocation.locationID} label={subLocation.name} onClick={() => props.setLocation(+subLocation.locationID)}>{LocationMenu(subLocation.children)}</TreeItem>);
                } else {
                    nestedMenuData.push(<TreeItem nodeId={subLocation.locationID} label={subLocation.name} onClick={() => props.setLocation(+subLocation.locationID)}/>);
                }
            })
            return nestedMenuData;
        }
        setMenuRender(LocationMenu(props.locationTree));
        setExpanded(parentLocations);
    }, [props.locationTree]);
  return (
    <div>
        {props.mode === "selector"?
            <Box sx={{ mt:1, minHeight: 180, flexGrow: 1, maxWidth: 400, overflow: 'auto' }}>
                <Box sx={{ m:1}}>
                    <Typography variant="h5" align="center">
                        Lab Locations
                    </Typography>
                </Box>
                <Divider sx={{mb:2}}/>
                <TreeView
                    aria-label="Location Explorer"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    selected={[currentLocationID]}
                    expanded={expanded}
                >
                    {menuRender}
                </TreeView>
            </Box>
        :
            <Drawer 
            anchor="left" 
            open={true}
            variant="permanent"
            sx={{
                width: props.width,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: props.width, boxSizing: 'border-box' },
            }}
            >
                <Toolbar/>
                <Box sx={{ mt:1, minHeight: 180, flexGrow: 1, maxWidth: 400, overflow: 'auto' }}>
                    <Box sx={{ m:1}}>
                        <Typography variant="h5" align="center">
                            Lab Locations
                        </Typography>
                    </Box>
                    <Divider sx={{mb:2}}/>
                    <TreeView
                        aria-label="Location Explorer"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        selected={[currentLocationID]}
                        expanded={expanded}
                    >
                        {menuRender}
                    </TreeView>
                </Box>
            </Drawer>
        }
    </div>
  );
}