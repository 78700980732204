import React, { useState, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Input, Typography } from '@mui/material';

function ItemFilter({ itemData, onFilterChange }) {
    const [searchText, setSearchText] = useState('');
    const [catNoSearchText, setCatNoSearchText] = useState('');
    const [manufacturerSearchText, setManufacturerSearchText] = useState('');
    const [manufacturers, setManufacturers] = useState([]);
    const [selectedManufacturers, setSelectedManufacturers] = useState([]);
    const [manufacturerCounts, setManufacturerCounts] = useState({});

    useEffect(() => {
        const manufacturerMap = itemData.reduce((acc, item) => {
            acc[item.manufacturer] = (acc[item.manufacturer] || 0) + 1;
            return acc;
        }, {});

        setManufacturers(Object.keys(manufacturerMap));
        setManufacturerCounts(manufacturerMap);
    }, [itemData]);

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
        triggerFilterUpdate(event.target.value, catNoSearchText, manufacturerSearchText, selectedManufacturers);
    };

    const handleCatNoSearchChange = (event) => {
        setCatNoSearchText(event.target.value);
        triggerFilterUpdate(searchText, event.target.value, manufacturerSearchText, selectedManufacturers);
    };

    const handleManufacturerSearchChange = (event) => {
        setManufacturerSearchText(event.target.value);
        triggerFilterUpdate(searchText, catNoSearchText, event.target.value, selectedManufacturers);
    }

    const handleManufacturerChange = (manufacturer, isChecked) => {
        const newSelectedManufacturers = isChecked
            ? [...selectedManufacturers, manufacturer]
            : selectedManufacturers.filter(m => m !== manufacturer);
        setSelectedManufacturers(newSelectedManufacturers);
        triggerFilterUpdate(searchText, catNoSearchText, manufacturerSearchText, newSelectedManufacturers);
    };

    const triggerFilterUpdate = (search, catNoSearch, manufacturerSearch, manufacturers) => {
        const filteredData = itemData.filter(item => {
            const manufacturerMatch = manufacturers.length > 0 ? manufacturers.includes(item.manufacturer) : true;
            const searchMatch = item.itemName.toLowerCase().includes(search.toLowerCase());
            const catNoMatch = item.catNo.toLowerCase().includes(catNoSearch.toLowerCase());
            const manufacturerSearchMatch = item.manufacturer.toLowerCase().includes(manufacturerSearch.toLowerCase());
            return manufacturerMatch && searchMatch && catNoMatch && manufacturerSearchMatch;
        });
        onFilterChange(filteredData);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxHeight: '100%', overflowY: 'auto' }}>
            <Typography variant="h6">Filters</Typography>
            <Box sx={{ marginBottom: 2 }}>
                <Input
                    type="text"
                    placeholder="Search by Item Name..."
                    value={searchText}
                    onChange={handleSearchChange}
                    fullWidth
                />
            </Box>
            <Box sx={{ marginBottom: 2 }}>
                <Input
                    type="text"
                    placeholder="Search by Catalog Number..."
                    value={catNoSearchText}
                    onChange={handleCatNoSearchChange}
                    fullWidth
                />
            </Box>
            <Box sx={{ marginBottom: 2 }}>
                <Input
                    type="text"
                    placeholder="Search by Manufacturer..."
                    value={manufacturerSearchText}
                    onChange={handleManufacturerSearchChange}
                    fullWidth
                />
            </Box>
            <Box>
                <Typography variant="subtitle1">Manufacturers</Typography>
                <Grid container spacing={2}>
                    {manufacturers.map(manufacturer => (
                        <Grid item xs={6} key={manufacturer}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedManufacturers.includes(manufacturer)}
                                        onChange={(e) => handleManufacturerChange(manufacturer, e.target.checked)}
                                        size="small"
                                    />
                                }
                                label={
                                    <Typography sx={{ fontSize: '0.775rem' }}>
                                        {manufacturer} ({manufacturerCounts[manufacturer]})
                                    </Typography>
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}

export default ItemFilter;
