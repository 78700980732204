import React, { useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import APIServerPath from "../Constants/APIServerPath";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stepper, 
  Step,
  StepLabel,
  Paper,
  Grid,
  Divider,
  InputAdornment,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Alert,
  Autocomplete
} from "@mui/material";
import axios from "axios";
import { LoadingContext } from "../../App";

const steps = ["Item Information", "Confirm Details"];

function CreateItemCard(props) {
  const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
  const setLoading = useContext(LoadingContext);
  const [errorMsg, setErrorMsg] = useState(null);
  const initialState = {
    itemName: "",
    catNo: "",
    manufacturer: "",
    type: "0",
    quantityUnits: "",
    storageTemp: "",
    minQty: "",
    maxQty: "",
  };

  const [labData, setLabData] = useState(initialState);
  const [activeStep, setActiveStep] = useState(0);
  const {onClose, setAlertMsg, setAlertSeverity, setAlertOpen} = props;
  const [itemType, setItemType] = useState(0);
  const [itemCatalog, setItemCatalog] = useState({"":{}});
  const [catNo, setCatNo] = useState("");
  const [itemName, setItemName] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [storageTemp, setStorageTemp] = useState("");
  const [quantityUnits, setQuantityUnits] = useState("");

  const resetForm = () => {
    setActiveStep(0);
    setLabData(initialState);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const client = axios.create({
    baseURL: APIServerPath,
  });

  //get and set itemCatalog
  useEffect(()=>{
    setLoading(true);
    const response = client.post(
      'retrieve_catalog', 
      {},
      {headers}
      ).then(function (response) {
        if (response.data.msg) {//show error alert
          setErrorMsg(response.data.msg);
          setLoading(false);
        } else{//save token and name to cookies and redirect to dashboard
          setErrorMsg(null);
          setLoading(false);
          let tempItemCatalog = {"":{}};
          let itemCatalogData = response.data.catalog;
          for (let i = 0; i < itemCatalogData.length; i++){
            if (!tempItemCatalog[itemCatalogData[i].manufacturer]){
              tempItemCatalog[itemCatalogData[i].manufacturer] = {};
            }
            let currCatNo = itemCatalogData[i].catNo;
            tempItemCatalog[itemCatalogData[i].manufacturer][currCatNo] = {
              itemName:itemCatalogData[i].itemName,
              defaultQty:itemCatalogData[i].defaultQty,
              qtyUnits:itemCatalogData[i].qtyUnits,
              storageTemp:itemCatalogData[i].temperature,
            };

          }
          setItemCatalog(tempItemCatalog);
        }
      }).catch(function (error) {
      console.log(error, "error")//implement redirecting to error pages
    });
  }, []);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // For integer fields, parse the value to ensure it's stored as an integer
    const intValueFields = ["storageTemp", "minQty", "maxQty"];
    const updatedValue = intValueFields.includes(name)
      ? parseInt(value, 10)
      : value;
    setLabData({ ...labData, [name]: updatedValue });
  };

  const handleSubmit = async () => {
    console.log(labData);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.access_token}` },
    };
    labData.type=itemType;
    const requestData = {
      items: [{ ...labData }],
      labID: `${localStorage.labID}`,
    };
    try {
      await client.post("add_consumables", requestData, config);
      props.onItemAdded();
      setAlertMsg("Item Added Successfully!");
      setAlertSeverity("success");
    } catch (error) {
      setAlertMsg("Failed to add item.");
      setAlertSeverity("error");
    } finally {
      onClose();
      setAlertOpen(true); 

    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 2:
        return (
          <Paper>


          </Paper>
        )
      case 0:
        return (
          <Paper style={{ padding: 16, marginTop: 20 }}>
            <Typography variant="h6" gutterBottom>
              Enter Item Details
            </Typography>
            <Grid container spacing={3}>
              {" "}
              <Grid item xs={4.5} style={{ paddingTop: 36 }}>
                {" "}
                {/* Manufacturer */}
                <Autocomplete
                freeSolo
                id="manufacturer"
                disableClearable
                options={Object.keys(itemCatalog)}
                onChange={(event, newManufacturer) => {
                  setManufacturer(newManufacturer);
                }}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                    <TextField
                        size="small"
                        {...params}
                        label="Manufacturer"
                        InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        }}
                    />
                )}/>
                {/* <TextField
                  label="Manufacturer"
                  name="manufacturer"
                  value={labData.manufacturer}
                  onChange={handleInputChange}
                  fullWidth
                /> */}
              </Grid>
              <Grid item xs={4.5} style={{ paddingTop: 36 }}>
                {" "}
                {/* Catalog Number */}
                <Autocomplete
                freeSolo
                id="catNo"
                disableClearable
                options={Object.keys(itemCatalog[manufacturer])}
                // filterOptions={(options, state) => {
                //   labData.catNo = state.inputValue;
                //   if ((state.inputValue.length > 2)) {
                //     return options.filter((item) =>
                //       String(item.label).toLowerCase().includes(state.inputValue.toLowerCase())
                //     );
                //   }
                //   return options;
                // }}
                onChange={(event, newCatNo) => {
                  setCatNo(newCatNo);
                  setItemName(itemCatalog[manufacturer][newCatNo].itemName);
                  setStorageTemp(itemCatalog[manufacturer][newCatNo].storageTemp);
                  if (itemCatalog[manufacturer][newCatNo].defaultQty) {
                    setItemType(1);
                    setQuantityUnits(itemCatalog[manufacturer][newCatNo].qtyUnits);
                  }
                }}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                    <TextField
                        size="small"
                        {...params}
                        label="Catalog Number"
                        InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        }}
                    />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                      <div>
                          <Typography variant="subtitle1" align="left">
                              {option}
                          </Typography>
                          <div>
                            <Typography variant="subtitle2" align="left">
                                {itemCatalog[manufacturer][option].itemName}
                            </Typography>
                          </div>
                      </div>
                  </li>
                )}
                />
                {/* <TextField
                  label="Catalog Number"
                  name="catNo"
                  value={labData.catNo}
                  onChange={handleInputChange}
                  fullWidth
                /> */}
              </Grid>
              <Grid item xs={3} style={{ paddingTop: 36 }}>
                {" "}
                {/* Storage Temperature */}
                <TextField
                  label="Storage Temp."
                  name="storageTemp"
                  type="number"
                  value={storageTemp}
                  onChange={(event)=>{
                    setStorageTemp(event.target.value);
                  }}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">°C</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <TextField
                  label="Item Name"
                  name="itemName"
                  value={itemName}
                  onChange={(event)=>{
                    setItemName(event.target.value);
                  }}
                  fullWidth
                />
              </Grid>
              {itemType?
              <React.Fragment>
                <Grid item xs={4} style={{ paddingTop: 36 }}>
                  {" "}
                  {/* Quantity Units */}
                  <TextField
                    label="Quantity Units"
                    name="quantityUnits"
                    value={quantityUnits}
                    onChange={(event)=>{
                      setQuantityUnits(event.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} style={{ paddingTop: 36 }}>
                  {" "}
                  {/* Minimum Quantity */}
                  <TextField
                    label="Minimum Quantity"
                    name="minQty"
                    type="number"
                    value={labData.minQty}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} style={{ paddingTop: 36, paddingBottom: 20 }}>
                  {" "}
                  {/* Maximum Quantity */}
                  <TextField
                    label="Maximum Quantity"
                    name="maxQty"
                    type="number"
                    value={labData.maxQty}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
              </React.Fragment>
              :null
              }
              <Grid item xs={12} sx={{p:1}}>
                  <FormControlLabel 
                  control={
                      <Checkbox
                      checked={itemType}
                      onChange={(event)=>{
                        if (!event.target.checked){
                          labData.maxQty = "0";
                          labData.minQty = "0";
                          labData.quantityUnits = "";
                        }
                          setItemType(event.target.checked);
                      }}
                      />
                  } 
                  label="Track item current, minimum, and maximum quantity" 
                  />
              </Grid>
            </Grid>
          </Paper>
        );
      case 1:
        labData.itemName = itemName;
        labData.catNo = catNo;
        labData.manufacturer = manufacturer;
        labData.type = itemType;
        labData.quantityUnits = quantityUnits;
        labData.storageTemp = storageTemp;
        return (
          <Paper style={{ padding: 16, marginTop: 20 }}>
            <Typography variant="h6" gutterBottom>
              Confirm Item Details
            </Typography>
            <Grid container>
              {Object.keys(labData).map((key, index) => (
                <React.Fragment key={key}>
                  <Grid
                    item
                    xs={6}
                    style={{
                      padding: 8,
                      borderBottom: "1px solid #eee",
                      borderRight: "1px solid #eee",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "medium" }}
                    >
                      {key.charAt(0).toUpperCase() +
                        key
                          .slice(1)
                          .replace(/([A-Z])/g, " $1")
                          .trim()}
                      :
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ padding: 8, borderBottom: "1px solid #eee" }}
                  >
                    <Typography variant="body2">
                      {key === "storageTemp"
                        ? `${labData[key]} °C`
                        : labData[key]}
                    </Typography>
                  </Grid>
                  {index < Object.keys(labData).length - 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Paper>
        );
      default:
        return "Internal Error";
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            maxWidth: "900px", // Set the fixed width here
            height: "640px", // Set the fixed height here
            overflow: "auto", // Optional: add overflow auto to enable scrolling if the content exceeds the dialog size
          },
        }}
      >
        <DialogTitle>New Item for Lab</DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>{getStepContent(activeStep)}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            {errorMsg ? 
            <Grid item xs={12} sx={{p:1}}>
                <Alert severity="error">{errorMsg}</Alert>
            </Grid>
            :null
            }
            {activeStep > 0 && (
              <Button onClick={handleBack} style={{ marginRight: 10 }}>
                Back
              </Button>
            )}
            {activeStep === 0 && (
              <Button
                variant="outlined"
                onClick={resetForm}
                style={{ marginRight: 10 }}
              >
                Reset
              </Button>
            )}
            {activeStep < steps.length - 1 ? (
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            ) : (
              // Removed the handleSubmit logic as requested
              <Button variant="contained" onClick={handleSubmit}>
                Finish
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default CreateItemCard;
