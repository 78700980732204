import { useState, useContext } from 'react';
import React from 'react';
import { Box, Typography, Dialog, Alert, DialogTitle, DialogContent, Grid, Button, TextField, Select, MenuItem, Tabs, Tab, FormControlLabel, Checkbox, Autocomplete} from '@mui/material';
import { LoadingContext } from '../../../App';
import APIServerPath from '../../Constants/APIServerPath';
import axios from 'axios';
import PatientSettingsTab from './PatientSettingsTab';


function PatientSettingsTabCard (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const mode = props.mode;//modes are add or update
    const labPatientPropertiesID = props.parameterData.labPatientPropertiesID;
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = useState(null);
    //form data
    const [parameterName, setParameterName] = useState(props.parameterData.parameterName);
    const [parameterDescription, setParameterDescription] = useState(props.parameterData.parameterDescription);

    //handle add or update request
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.currentTarget);
        formData.append("lab_ID", localStorage.labID);
        formData.append("parameterName", parameterName);
        formData.append("parameterDescription", parameterDescription);
        if (mode === "update"){
            formData.append("labPatientPropertiesID", labPatientPropertiesID);
            const response = client.post(
                'update_lab_patient_properties', 
                formData,
                {headers}
                ).then(function (response) {
                    if (response.data.msg) {//show error alert
                        setErrorMsg(response.data.msg);
                        setLoading(false);
                    } else{//save token and name to cookies and redirect to dashboard
                        setErrorMsg(null);
                        setLoading(false);
                        props.close();
                    }
                }).catch(function (error) {
                console.log(error, "error");//implement redirecting to error pages
            });
        } else {
            const response = client.post(
                'add_lab_patient_properties', 
                formData,
                {headers}
                ).then(function (response) {
                    if (response.data.msg) {//show error alert
                        setErrorMsg(response.data.msg);
                        setLoading(false);
                    } else{//save token and name to cookies and redirect to dashboard
                        console.log(response.data);
                        setErrorMsg(null);
                        setLoading(false);
                        props.close();
                    }
                }).catch(function (error) {
                console.log(error, "error");//implement redirecting to error pages
            });
        }
    };


    return (
        <React.Fragment>
            <Dialog
            open={labPatientPropertiesID} 
            onClose={()=>{props.close();}}
            fullWidth
            maxWidth="lg"
            >
                <DialogTitle id="scroll-dialog-title">
                    {(mode === "update") ?
                    "Update Parameter":
                    "Add Parameter"
                    }
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 500, width:'100%'}} component="form" noValidate onSubmit={handleSubmit} justifyContent="center" alignment="center">
                        <Grid 
                        container 
                        justifyContent="center"
                        alignItems="center"
                        sx={{ p: 1}}>
                            {errorMsg ? 
                            <Grid item xs={12} sx={{p:1}}>
                                <Alert severity="error">{errorMsg}</Alert>
                            </Grid>
                            :null}
                            <Grid item xs={4} sx={{p:2}}>
                                <Typography variant="h6" align="center">
                                    Name
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sx={{p:2}}>
                                <TextField
                                id="patient-parameter-name"
                                label="Name"
                                value={parameterName}
                                size="small"
                                onChange={(event) => {
                                    setParameterName(event.target.value);
                                }}
                                fullWidth
                                />
                            </Grid>
                            <Grid item xs={4} sx={{p:2}}>
                                <Typography variant="h6" align="center">
                                    Description
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sx={{p:2}}>
                                <TextField
                                id="patient-parameter-description"
                                label="Description"
                                value={parameterDescription}
                                onChange={(event) => {
                                    setParameterDescription(event.target.value);
                                }}
                                fullWidth
                                multiline
                                />
                            </Grid>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {(mode === "update") ?
                                "Update Parameter":
                                "Add Parameter"
                                }
                            </Button> 
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
)
}

export default PatientSettingsTabCard;