import React, { useState } from 'react';
import { DropzoneArea } from "mui-file-dropzone";
import axios from 'axios';
import APIServerPath from './Constants/APIServerPath';


export default function FileUpload (props) {//
    const client = axios.create({
      baseURL:APIServerPath
    });
    const headers = {
        'Authorization': `Bearer ${localStorage.access_token}`
    };
    const [uploadResponse, setUploadResponse] = useState([]);//response should be URL to image.

    const updateFile = (file) => {
      props.setAlerts(null);
      props.setFiles([file[0]]);
    }
    return (//implement allowed file types
        <div className="App">
            <DropzoneArea 
            filesLimit={1}
            maxFileSize={props.maxFileSize}
            onChange={updateFile}
            initialFiles={props.files}
            acceptedFiles={props.acceptedFiles}
            onDropRejected={(files) => {
              if ((props.setAlerts != null) && (props.invalidMsg != null)) {
                props.setAlerts(props.invalidMsg);
              } else if (props.invalidMsg != null) {
                alert(props.invalidMsg);
              } else {
                alert('Invalid file type!');
              }
            }}
            />
        </div>
    );
}