import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import APIServerPath from '../Constants/APIServerPath';


const ConsumablesLogTable = ({ filters }) => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const client = axios.create({baseURL:APIServerPath});
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`}

    useEffect(() => {
        fetchLogs();
    }, [filters]);

    const fetchLogs = async () => {
        setLoading(true);
        try {
            const response = await client.post('/retrieve_consumables_audit',
                {   
                    labID: localStorage.labID,
                    startDate: filters.startDate,
                    endDate: filters.endDate,
                    actionType: filters.actionType,
                    entityType: filters.entityType,
                }, 
                { headers });
            setLogs(response.data.logs);
        } catch (error) {
            console.error('Error fetching audit logs:', error);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        { field: 'recordDatetime', headerName: 'Timestamp', width: 300 },
        // { field: 'uID', headerName: 'ID', width: 90 },
        { field: 'name', headerName: 'User', width: 150 },
        { field: 'actionType', headerName: 'Action Type', width: 300 },
        { field: 'entityType', headerName: 'Entity Type', width: 150 },
        // { field: 'entityID', headerName: 'Entity ID', width: 100 },
        { field: 'netChange', headerName: 'Net Change', width: 150 },
        { field: 'record', headerName: 'Record', flex: 1 },
    ];

    return (
        <Box sx={{ height: 600, width: '100%' }}>
            {loading ? (
                <CircularProgress />
            ) : (
                <DataGrid
                    rows={logs}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    pagination
                    getRowId={(row) => row.recordID}
                />
            )}
        </Box>
    );
};

export default ConsumablesLogTable;
