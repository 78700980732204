
import React from 'react';
import { Grid, Typography, Link } from '@mui/material';
import VersionControl from '../Constants/VersionControl';

function Copyright(props) {
    return (
      <React.Fragment>
        <Grid
        style={{
            position:"absolute",
            bottom:0,
            width:"100%",
        }}
        justifyContent="center"
        alignItems="center"
        sx={{ p: 1}}>
          <Grid item xs={12} sx={{p:1}}>
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
              {'Copyright © '}
              <Link color="inherit" href="/">
                aioLIMS
              </Link>{' '}
              {new Date().getFullYear()}
              {'. '}
              {VersionControl}
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
export default Copyright;