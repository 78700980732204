import { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingContext } from '../../../App';
import APIServerPath from '../../Constants/APIServerPath';
import axios from 'axios';
import ProtocolsTabCard from './ProtocolsTabCard';
import DownloadIcon from '@mui/icons-material/Download';

function ProtocolsTab (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const userPerms = JSON.parse(localStorage.userPermissions);
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = [props.errorMsg, props.setErrorMsg];
    const [rows, setRows] = useState([]);
    const [selectedProtocol, setSelectedProtocol] = useState(null);
    const [newProtocolCardOpen, setNewProtocolCardOpen] = useState(null);
    const columns = [
        {
            field: 'protocolName',
            headerName: 'Name',
            flex: 1,
            editable: false,
        },
        {
            field: 'protocolDescription',
            headerName: 'Description',
            flex: 2,
            editable: false,
        },
        {
            field: 'revisionID',
            headerName: 'Revision ID',
            flex: 0.5,
            editable: false,
        },
        {
            field: 'lastModification',
            headerName: 'Last Modification',
            flex: 1,
            editable: false,
        },
        {
            field: 'modificationName',
            headerName: 'Modified By',
            flex: 1,
            editable: false,
        },
        {
            field: "download",
            headerName: "Download",
            sortable: false,
            width: 140,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.protocolFileToken?
                        <DownloadIcon color="primary" onClick={event =>  window.location.href=`${APIServerPath}/retrieve_file/${params.row.protocolFileToken}`} />
                        :null}
                    </div>
                );
                }
          }
    ];
    //retrieve protocols associated with lab and which the user has access to
    useEffect(()=> {
        setLoading(true);
        let formData = new FormData();
        formData.append("lab_ID", localStorage.labID);
        const response = client.post(
            'retrieve_lab_protocols', 
            formData,
            {headers}
            ).then(function (response) {
            if (response.data.msg) {//there should be no error messages from this query
                setErrorMsg(response.data.msg);
                setLoading(false);
            } else{
                setRows(response.data.protocolData);
                setLoading(false);
            }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });
    }, [selectedProtocol]);

    //handling add mode card
    const handlAddProtocolCardOpen = (event) => {
        event.preventDefault();
        if (userPerms.protocolModifications) {
            setSelectedProtocol({
                protocolID:true,
                protocolName:"",
                protocolDescription:"",
                revisionID:"1.0",
                modificationComments:"New protocol added.",
                fileToken:null
            });
            setNewProtocolCardOpen(true);
        }
    };

    //closes all cards
    const handleCardClose = () => {
        setSelectedProtocol(null);
        setNewProtocolCardOpen(false);
    }

    return (
        <div>
            {selectedProtocol && !newProtocolCardOpen && <ProtocolsTabCard protocolData={selectedProtocol} close={handleCardClose} mode="update"/>}
            {newProtocolCardOpen && <ProtocolsTabCard protocolData={selectedProtocol} close={handleCardClose} mode="add"/>}
            <Grid
            container
            justifyContent="right"
            alignItems="right"
            spacing={12}>
                <Grid item xs={9}> 
                    <Typography variant="h6" align="left">
                        Protocols
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                {userPerms.protocolModifications ?
                    <Button variant="outlined" onClick={handlAddProtocolCardOpen}>Add New Protocol</Button>
                : null
                }
                </Grid>
            </Grid>
            <Box sx={{ height: 350, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    onCellDoubleClick={(params, event, details)=> {
                        //check permissions to modify project information
                        //set selectedProtocol
                        if (params.row.permissions === 2 || userPerms.protocolModifications) {
                            setSelectedProtocol(params.row);
                        }
                    }}
                />
            </Box>
        </div>
    )
}

export default ProtocolsTab;