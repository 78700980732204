import { useState, useEffect } from 'react';
import {Card, CardContent, Box, Grid, TextField, Autocomplete, Button, Typography, Alert, Backdrop, ClickAwayListener} from '@mui/material';
import axios from "axios";
import APIServerPath from '../Constants/APIServerPath';

function AddInstitutionCard (props) {//props include onClick, open, setInstitution, and timezones
    //Need to fetch list of country and country codes
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}` };
    const [errorMsg, setErrorMsg] = useState(null);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedTimezone, setSelectedTimezone] = useState([]);

    const client = axios.create({
      baseURL:APIServerPath
    });
    
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const submissionData= {
            institution_name: data.get('institutionName'),
            country: selectedCountry.countryCode,
            timezone: selectedTimezone.label,
        };


        const response = client.post(
          'add_institution', 
          submissionData,
          {headers}
          ).then(function (response) {
            if (response.data.msg) {//show error alert
                setErrorMsg(response.data.msg);
            } else{//save token and name to cookies and redirect to dashboard
              props.setInstitution(response.data.institution);
              props.setTimezone(response.data.timezone);
              props.onClick();
            }
          }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
          });
      };

    useEffect(()=> {
        const countries_response = client.post(
        'retrieve_countries', 
        {},
        {headers}
        ).then(
            function (countries_response) {
                setCountries(countries_response.data.country_code_names);
            }
        ).catch(
            function (error) {
                console.log(error, "error");//implement redirecting to error pages
            }
        );
    }, []);

    return (
        <div>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.open}
            >
                <ClickAwayListener onClickAway={props.onClick}>
                    <Card sx={{ minWidth: 250,  maxWidth: 375, textAlign: 'center'  }}>
                        <CardContent>
                            <Box component="form" noValidate onSubmit={handleSubmit} justifyContent="center" alignment="center">
                                <Grid 
                                container 
                                justifyContent="center"
                                alignItems="center"
                                sx={{ p: 2}}>
                                    <Grid item xs={12} sx={{p:1}}>
                                        {errorMsg ? <Alert severity="error">{errorMsg}</Alert>:null}
                                        <Typography variant="h3" align="center">
                                            Add Institution
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{p:2}}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="institutionName"
                                            label="Institution Name"
                                            name="institutionName"
                                            autoComplete="institution-name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{p:2}}>
                                        <Autocomplete
                                        required
                                        fullWidth
                                        disablePortal
                                        id="country"
                                        options={countries}
                                        onChange={(event, newCountry) => {
                                            setSelectedCountry(newCountry);
                                        }}
                                        sx={{}}
                                        renderInput={(params) => <TextField {...params} label="Country" />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{p:2}}>
                                        <Autocomplete
                                        required
                                        fullWidth
                                        disablePortal
                                        id="timezone"
                                        options={props.timezones}
                                        onChange={(event, newTimezone) => {
                                            setSelectedTimezone(newTimezone);
                                        }}
                                        sx={{}}
                                        renderInput={(params) => <TextField {...params} label="Timezone" />}
                                        />
                                    </Grid>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Add Institution
                                    </Button>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </ClickAwayListener>
            </Backdrop>
        </div>
    )
}

export default AddInstitutionCard;
//