import * as React from 'react';
import { useState } from 'react';
import {useNavigate, Navigate} from 'react-router-dom';
import { Avatar, Button, CssBaseline, TextField, LinearProgress, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Container, Alert } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import axios from 'axios';
import Copyright from '../components/layout/Copyright';
import { LoadingContext, IsLoadingContext } from "../App";
import APIServerPath from '../components/Constants/APIServerPath';

export default function SignUp() {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);
  const loading = React.useContext(IsLoadingContext);
  const setLoading = React.useContext(LoadingContext);

  const client = axios.create({
    baseURL:APIServerPath
  });

  const handleSignInRedirect = () =>{
    navigate('/sign-in');
  }

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const submissionData= {
      name: data.get('name'),
      email: data.get('email'),
      password: data.get('password'),
      ver_password: data.get('verPassword'),
    };

    const response = client.post(
      'create_account', 
      submissionData
      ).then(function (response) {
        if (response.data.msg) {//show error alert
          setLoading(false);
          setErrorMsg(response.data.msg);
        } else{//save token and name to cookies and redirect to dashboard
          setLoading(false);
          localStorage.access_token = response.data.access_token;
          localStorage.name = response.data.name;
          setErrorMsg(null);
          navigate('/');
        }
      }).catch(function (error) {
        setLoading(false);
        console.log(error, "error")//implement redirecting to error pages
      });
  };


  if (localStorage.access_token != null){ //already signed in 
    return (<Navigate to='/' />);
  }

  return (
    <div>
      {loading ? 
        <Box>
          <LinearProgress color="secondary" />
        </Box> : null
      }
      <Container component="main" maxWidth="xs">
        {errorMsg ? <Alert severity="error">{errorMsg}</Alert>:null}
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create an aioLIMS Account
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  label="Full Name"
                  name="name"
                  autoComplete="full-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="verPassword"
                  label="Verification Password"
                  type="password"
                  id="verPassword"
                  autoComplete="verification-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive marketing promotions and updates to software development via email."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
          </Box>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link variant="body2" onClick={handleSignInRedirect} component="button">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Copyright sx={{ mt: 5 }} />
    </div>
  );
}