import { useState, useEffect, useContext } from 'react';
import * as React from 'react';
import {Dialog, DialogContent, DialogTitle, Box, Grid, TextField, FormControl, Button, 
    Typography, NativeSelect, InputLabel, Stepper, Step, StepLabel, Alert, Container, CssBaseline} from '@mui/material';
import axios from "axios";
import NxNBox from './NxNBox/NxNBox';
import Rack from './Racks/Rack';
import APIServerPath from '../Constants/APIServerPath';
import FileUpload from '../FileUpload';
import { LoadingContext} from "../../App";

const steps = ['Location Information', 'Location Size/Image', 'Confirm Details'];

function CreateLocationCard (props) {//props include onClick, open
    //Need to fetch list of country and country codes
    const setLoading = useContext(LoadingContext);
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const [errorMsg, setErrorMsg] = useState(null);
    const [locationType, setLocationType] = useState(1);
    const [locationName, setLocationName] = useState("");
    const [locationTemp, setLocationTemp] = useState(4);
    const [limY, setLimY] = useState(0);
    const [limX, setLimX] = useState(0);
    const [files, setFiles] = useState([]);

    const locationTypes = [
        {id:1, name:'General Location'},
        {id:2, name:'Fridge/Freezer'},
        {id:3, name:'Circular Freezer (LN2 Tank)'},
        {id:4, name:'Freezer Rack'},
        {id:5, name:'Box (NxN)'},
    ];

    const client = axios.create({
      baseURL:APIServerPath
    });



    const [activeStep, setActiveStep] = React.useState(0);

    const isStepOptional = (step) => {
        return step === 1;
    };

    const handleNext = () => {
        //checks for required fields
        if ((activeStep === 0) && (locationName.replace(/ /gi, "").length < 1)) {
            setErrorMsg("Location Name is Required!");
        } else {
            setErrorMsg(null);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        };
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const getStepData = () => {//generate rendering and handle submission
        switch (activeStep) {
            case 0://General Location Info
                return (
                    <Grid 
                    container 
                    justifyContent="center"
                    alignItems="center"
                    sx={{ p: 2}}>
                        <Grid item xs={12} sx={{p:1}}>
                            <FormControl fullWidth>
                                <TextField
                                    required
                                    id="location-name"
                                    label="Name"
                                    value={locationName}
                                    onChange={(event) => {
                                    setLocationName(event.target.value);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{p:1}}>
                            <FormControl fullWidth>
                                <InputLabel variant="standard" htmlFor="location-type-select">Location Type</InputLabel>
                                <NativeSelect
                                    inputProps={{
                                        name: 'location-type',
                                        id: 'location-type-select',
                                    }}
                                    value = {locationType}
                                    onChange={(e)=> {setLocationType(e.target.value)}}
                                >
                                    {
                                        locationTypes.map((type)=> <option value={type.id}>{type.name}</option>)
                                    }
                                </NativeSelect>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{p:1}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="location-temp"
                                    type="number"
                                    label="Temperature (°C)"
                                    value={locationTemp}
                                    onChange={(event) => {
                                    setLocationTemp(event.target.value);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                );
            break;
            case 1://Location Representation Data (Size/Image)
                if (locationType == 1) {//General Location for image upload
                    return (
                        <FileUpload
                        files={files}
                        setFiles={setFiles}
                        maxFileSize={5000000}//5MB
                        acceptedFiles={['image/*']}//images only
                        setAlerts={setErrorMsg}
                        invalidMsg="Only image files of sizes < 5 megabytes are allowed!"
                        />
                    );
                } else if ((locationType == 2) || (locationType == 3)) {//Freezer/LN2 Freezer, LimY Only
                    return (
                        <Grid 
                        container 
                        justifyContent="center"
                        alignItems="center"
                        sx={{ p: 2}}>
                            <Grid item xs={12} sx={{p:1}}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="number-rows"
                                        label="# Shelves / Compartments"
                                        value={limY}
                                        onChange={(event) => {
                                            setLimY(event.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    );
                } else {//Racks/Boxes with LimY and LimX
                    return (
                        <Grid 
                        container 
                        justifyContent="center"
                        alignItems="center"
                        sx={{ p: 2}}>
                            <Grid item xs={12} sx={{p:1}}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="number-columns"
                                        label="# Columns"
                                        value={limX}
                                        onChange={(event) => {
                                            setLimX(event.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{p:1}}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="number-rows"
                                        label="# Rows"
                                        value={limY}
                                        onChange={(event) => {
                                            setLimY(event.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    );
                };
                break;
            case 2://review input data and show preview of location if applicable
                let locationPreviewData = {//default empty location data
                    name:locationName,
                    items:{},//format of coordinates in x, y or col, row
                    children:{},
                    parent:[],
                    locationType:parseInt(locationType),
                    locationID:null,
                    limitX:parseInt(limX),
                    limitY:parseInt(limY),
                    locationX:null,
                    locationY:null
                };
                let renderData = [];
                renderData.push(//universal data
                    <Grid container sx={{pl:10, pr:10}}>
                        <Grid item xs={4}
                        sx={{
                            borderRight: "1px solid #DCDCDC",
                            borderBottom: "1px solid #DCDCDC",
                            borderTop: "1px solid #DCDCDC",
                            position: "relative",
                            width: "100%",
                        }}><Typography variant='subtitle1' align="center">Name</Typography></Grid>
                        <Grid item xs={8}
                        sx={{
                            borderLeft: "1px solid #DCDCDC",
                            borderBottom: "1px solid #DCDCDC",
                            borderTop: "1px solid #DCDCDC",
                            position: "relative",
                            width: "100%",
                        }}><Typography variant='subtitle1' align="center">{locationName}</Typography></Grid>
                        <Grid item xs={4}
                        sx={{
                            borderRight: "1px solid #DCDCDC",
                            borderBottom: "1px solid #DCDCDC",
                            position: "relative",
                            width: "100%",
                        }}><Typography variant='subtitle1' align="center">Location Type</Typography></Grid>
                        <Grid item xs={8}
                        sx={{
                            borderLeft: "1px solid #DCDCDC",
                            borderBottom: "1px solid #DCDCDC",
                            position: "relative",
                            width: "100%",
                        }}><Typography variant='subtitle1' align="center">{locationTypes[locationType - 1].name}</Typography></Grid>
                        <Grid item xs={4}
                        sx={{
                            borderRight: "1px solid #DCDCDC",
                            borderBottom: "1px solid #DCDCDC",
                            position: "relative",
                            width: "100%",
                        }}><Typography variant='subtitle1' align="center">Parent Location</Typography></Grid>
                        <Grid item xs={8}
                        sx={{
                            borderLeft: "1px solid #DCDCDC",
                            borderBottom: "1px solid #DCDCDC",
                            position: "relative",
                            width: "100%",
                        }}><Typography variant='subtitle1' align="center">{props.parentLocationName}</Typography></Grid>
                        <Grid item xs={4}
                        sx={{
                            borderRight: "1px solid #DCDCDC",
                            borderBottom: "1px solid #DCDCDC",
                            position: "relative",
                            width: "100%",
                        }}><Typography variant='subtitle1' align="center">Temperature</Typography></Grid>
                        <Grid item xs={8}
                        sx={{
                            borderLeft: "1px solid #DCDCDC",
                            borderBottom: "1px solid #DCDCDC",
                            position: "relative",
                            width: "100%",
                        }}><Typography variant='subtitle1' align="center">{locationTemp}°C</Typography></Grid>
                    </Grid>
                );
                if (locationPreviewData.locationType === 1) {
                    if (files[0] != null) {//include preview
                        renderData.push(
                            <Grid container sx={{pl:10, pr:10}}>
                                <Grid item xs={4}
                                sx={{
                                    borderRight: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center">Location Image</Typography></Grid>
                                <Grid item xs={8}
                                sx={{
                                    borderLeft: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center">{files[0].name}</Typography></Grid>
                            </Grid>
                        );
                        let previewUrL = URL.createObjectURL(files[0])
                        renderData.push(
                        <Grid container justifyContent="center" alignItems="center" sx={{m:2}}>
                            <Grid item xs={12}><Typography variant='subtitle1' align="left" sx={{fontWeight:500}}>Location Preview</Typography></Grid>
                            <Box style={{width:"70%"}} sx={{ border: "1px solid black"}}>
                                <img style={{width:"100%"}} src={previewUrL}/>
                            </Box>
                        </Grid>
                        )
                    } else {
                        renderData.push(
                            <Grid container sx={{pl:10, pr:10}}>
                                <Grid item xs={4}
                                sx={{
                                    borderRight: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center">Location Image</Typography></Grid>
                                <Grid item xs={8}
                                sx={{
                                    borderLeft: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center">N/A</Typography></Grid>
                            </Grid>
                        );
                    } 
                } else if ((locationPreviewData.locationType === 2) || (locationPreviewData.locationType === 3)) {//verical or circular freezer
                    if (locationPreviewData.limitY > 0) {//show preview
                        renderData.push(
                            <Grid container sx={{pl:10, pr:10}}>
                                <Grid item xs={4}
                                sx={{
                                    borderRight: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center"># Shelves / Compartments</Typography></Grid>
                                <Grid item xs={8}
                                sx={{
                                    borderLeft: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center">{limY}</Typography></Grid>
                            </Grid>
                            //include rendering
                        );
                    } else {
                        renderData.push(
                            <Grid container sx={{pl:10, pr:10}}>
                                <Grid item xs={4}
                                sx={{
                                    borderRight: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center"># Shelves / Compartments</Typography></Grid>
                                <Grid item xs={8}
                                sx={{
                                    borderLeft: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center">N/A</Typography></Grid>
                            </Grid>
                            //include rendering here
                        );
                    };
                } else { //rack or box
                    if ((locationPreviewData.limitX > 0) && (locationPreviewData.limitY > 0)) {//include preview
                        renderData.push(
                            <Grid container sx={{pl:10, pr:10}}>
                                <Grid item xs={4}
                                sx={{
                                    borderRight: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center"># Rows</Typography></Grid>
                                <Grid item xs={8}
                                sx={{
                                    borderLeft: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center">{limY}</Typography></Grid>
                                <Grid item xs={4}
                                sx={{
                                    borderRight: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center"># Columns</Typography></Grid>
                                <Grid item xs={8}
                                sx={{
                                    borderLeft: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center">{limX}</Typography></Grid>
                            </Grid>
                        );
                        if (locationPreviewData.locationType  === 4) {//Rack Rendering
                            renderData.push(
                                <Grid container justifyContent="center" alignItems="center" sx={{m:2}}>
                                    <Grid item xs={12}><Typography variant='subtitle1' align="left" sx={{fontWeight:500}}>Location Preview</Typography></Grid>
                                    <Box style={{width:"70%"}}>
                                        <Rack locationData={[locationPreviewData, null]} preview={true} errorState={[errorMsg, setErrorMsg]}/>
                                    </Box>
                                </Grid>
                            );
                        } else {
                            renderData.push(
                                <Grid container justifyContent="center" alignItems="center" sx={{m:2}}>
                                    <Grid item xs={12}><Typography variant='subtitle1' align="left" sx={{fontWeight:500}}>Location Preview</Typography></Grid>
                                    <Box style={{width:"70%"}}>
                                        <NxNBox locationData={[locationPreviewData, null]} preview={true} errorState={[errorMsg, setErrorMsg]}/>
                                    </Box>
                                </Grid>
                            );
                        }
                    } else {
                        renderData.push(
                            <Grid container sx={{pl:10, pr:10}}>
                                <Grid item xs={4}
                                sx={{
                                    borderRight: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center"># Rows</Typography></Grid>
                                <Grid item xs={8}
                                sx={{
                                    borderLeft: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center">N/A</Typography></Grid>
                                <Grid item xs={4}
                                sx={{
                                    borderRight: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center"># Columns</Typography></Grid>
                                <Grid item xs={8}
                                sx={{
                                    borderLeft: "1px solid #DCDCDC",
                                    borderBottom: "1px solid #DCDCDC",
                                    position: "relative",
                                    width: "100%",
                                }}><Typography variant='subtitle1' align="center">N/A</Typography></Grid>
                            </Grid>
                        );
                    }

                }
                return renderData;
                break;
        }
    };



    
    const handleSubmit = () => {//data submission
        setActiveStep(0);//ensures submission occurs only once
        setLoading(true);
        let formData = new FormData();
        formData.append("lab_ID", localStorage.labID);
        formData.append("location_name", locationName);
        formData.append("temperature", locationTemp);
        formData.append("location_type", locationType);
        formData.append("limitX", limX);
        formData.append("limitY", limY);
        formData.append("locationX", 0);//needs to be updated if we allow location X and Y to be set prior to adding location to DB
        formData.append("locationY", 0);//needs to be updated if we allow location X and Y to be set prior to adding location to DB
        formData.append("parentLocationID", props.parentLocationID);
        formData.append("files", files[0]);
        
        const response = client.post(
            'create_location', 
            formData,
            {headers}
            ).then(function (response) {
            if (response.data.msg) {//show error alert
                setActiveStep(2);//reset current step to confirm location details
                setErrorMsg(response.data.msg);
                setLoading(false);
            } else{//save new locationID and reset all form values
                setLoading(false);
                setLocationName("");
                setLocationTemp(4);
                setLocationType(1);
                props.setCurrentLocation(response.data.locationID);
                props.close();
            }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });
        return null;
    };

    return (
        <React.Fragment>
            <Dialog
            open={props.open} 
            onClose={props.close}
            >
                {errorMsg ? 
                <Box sx={{m:1}}>
                    <Alert severity="error" >{errorMsg} </Alert>
                </Box> 
                :null}
                <DialogTitle id="scroll-dialog-title">Create Location</DialogTitle>
                <DialogContent>
                    <Box justifyContent="center" alignment="center">
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                                );
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                            })}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                {handleSubmit()}
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    Submitting data...
                                </Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1} - {steps[activeStep]}</Typography>
                                {getStepData()}
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                    >
                                    Back
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />

                                    <Button onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default CreateLocationCard;
//