import { Grid, SpeedDial, SpeedDialIcon, Typography, CssBaseline, Container, LinearProgress, Box, SpeedDialAction } from '@mui/material';
import LabCardButton from '../components/LabSelection/LabCardButton';
import {useNavigate} from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import BiotechIcon from '@mui/icons-material/Biotech';
import KeyIcon from '@mui/icons-material/Key';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import CreateLabCard from '../components/LabSelection/CreateLabCard';
import { LoadingContext, IsLoadingContext } from "../App";
import APIServerPath from '../components/Constants/APIServerPath';
import Copyright from '../components/layout/Copyright';
import JoinLabCard from '../components/LabSelection/JoinLabCard';


function LabSelection () {
    //Need to fetch # labs associated with user
    const navigate = useNavigate();
    const [labAssociations, setLabAssociations] = useState([]);
    const [addLabCardOpen, setAddLabCardOpen] = useState(false);
    const [joinLabCardOpen, setJoinLabCardOpen] = useState(false);
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}` };

    const loading = useContext(IsLoadingContext);
    const setLoading = useContext(LoadingContext);

    const client = axios.create({
      baseURL:APIServerPath
    });

    function handleSelectLab (labID, labName, userPermissions){
        localStorage.labID = labID;
        localStorage.labName = labName;
        localStorage.userPermissions = JSON.stringify(userPermissions);
        navigate('/');
    }

    const handleAddLabCardClose = () => {
        setAddLabCardOpen(false);
    }

    const handleAddLabCardOpen = () => {
        setAddLabCardOpen(true);
    }

    const handleJoinLabCardClose = () => {
        setJoinLabCardOpen(false);
    }

    const handleJoinLabCardOpen = () => {
        setJoinLabCardOpen(true);
    }


    useEffect(()=> {
        setLoading(true);
        const lab_association_response = client.post(
        'retrieve_lab_associations', 
        {},
        {headers}
        ).then(
            function (lab_association_response) {
                setLoading(false);
                setLabAssociations(lab_association_response.data.labs);
            }
        ).catch(
            function (error) {
                setLoading(false);
                console.log(error, "error");//implement redirecting to error pages
            }
        );
    }, [addLabCardOpen, joinLabCardOpen]);

    return (
        <div>
            {loading ? 
                <Box>
                <LinearProgress color="secondary" />
                </Box> : null
            }
            {addLabCardOpen ? <CreateLabCard onClick={handleAddLabCardClose} open={addLabCardOpen}/>:null}
            {joinLabCardOpen ? <JoinLabCard close={handleJoinLabCardClose} open={handleJoinLabCardOpen}/>:null}
            <Container component="main" maxWidth="xl" sx={{ pl: 7, pr: 7}}>
                <Grid container 
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ pt: 7, pb: 4}}
                >
                    <BiotechIcon style={{color:'DodgerBlue', fontSize: "100px"}} />
                    <Typography variant="h2" align="center">
                        Select Lab
                    </Typography>
                </Grid>
                <CssBaseline />
                <Grid container 
                spacing={6}
                direction="row"
                justifyContent="center"
                alignItems="center"
                >
                    {labAssociations.map((lab) => (
                    <LabCardButton 
                    labName={lab.labName}
                    institutionName={lab.institutionName}
                    country={lab.country}
                    onClick={()=>handleSelectLab(lab.labID, lab.labName, lab.permissions)}
                    />
                    ))}
                    {labAssociations.length ? null: 
                    <Typography variant="h4" align="center" sx={{mt:10}}>
                        No labs are associated with your account.
                    </Typography>
                    }
                </Grid>
                <SpeedDial 
                    ariaLabel="SpeedDial basic example"
                    sx={{ position: 'absolute', bottom: 16, right: 16 }}
                    icon={<SpeedDialIcon />}
                >
                    <SpeedDialAction
                        onClick={handleJoinLabCardOpen}
                        key={"Join Lab"}
                        icon={<KeyIcon/>}
                        tooltipTitle={"Join Lab"}
                    />
                    <SpeedDialAction
                        onClick={handleAddLabCardOpen}
                        key={"Create Lab"}
                        icon={<AddIcon/>}
                        tooltipTitle={"Create Lab"}
                    />
                </SpeedDial>
            </Container>
            <Copyright/>
        </div>
    )
}

export default LabSelection;