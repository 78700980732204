import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import APIServerPath from "../Constants/APIServerPath";
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    List, ListItem, ListItemText, Box, Grid, Button, CircularProgress, Typography, Checkbox, Stepper, Step, StepLabel
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

function DiscardItemCard(props) {
    const { open, onClose, selectedItems, setAlertMsg, setAlertSeverity, setAlertOpen, canModify } = props;
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}` };
    const [loading, setLoading] = useState(false);
    const [stockInstances, setStockInstances] = useState({});
    const [selectedItemID, setSelectedItemID] = useState(null);
    const [selectedStocks, setSelectedStocks] = useState({});
    const [selectAllStocks, setSelectAllStocks] = useState(false);
    const [selectAllItems, setSelectAllItems] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [stockMapping, setStockMapping] = useState({});

    const theme = useTheme();

    useEffect(() => {
        if (open) {
            fetchStockInstances();
        }
    }, [open, selectedItems]);

    useEffect(() => {
        if (Object.keys(stockInstances).length > 0) {
            const mapping = {};
            Object.values(stockInstances).forEach(item => {
                item.stocks.forEach(stock => {
                    mapping[stock.stockID] = stock;
                });
            });
            setStockMapping(mapping);
        }
    }, [stockInstances]);

    const fetchStockInstances = async () => {
        setLoading(true);
        try {
            const client = axios.create({ baseURL: APIServerPath });
            const itemIDs = selectedItems.map(item => item.itemID);
            const requestData = { itemIDs };
            const response = await client.post('/get_inventory', requestData, { headers });
            setStockInstances(response.data.items);
        } catch (error) {
            setAlertMsg("Failed to retrieve Data.");
            setAlertSeverity("error");
            setAlertOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleItemClick = (itemID) => {
        setSelectedItemID(itemID);
        updateSelectAllStocks(itemID);
    };

    const handleStockCheckboxChange = (stockID) => {
        setSelectedStocks(prevState => {
            const newSelectedStocks = { ...prevState, [stockID]: !prevState[stockID] };
            updateSelectAllItems(newSelectedStocks);
            updateSelectAllStocks(selectedItemID, newSelectedStocks);
            return newSelectedStocks;
        });
    };

    const handleSelectAllStocksChange = () => {
        const allSelected = !selectAllStocks;
        setSelectAllStocks(allSelected);
        if (selectedItemID && stockInstances[selectedItemID]) {
            const newSelectedStocks = { ...selectedStocks };
            stockInstances[selectedItemID].stocks.forEach(stock => {
                newSelectedStocks[stock.stockID] = allSelected;
            });
            setSelectedStocks(newSelectedStocks);
            updateSelectAllItems(newSelectedStocks);
        }
    };

    const handleSelectAllItemsChange = () => {
        const allSelected = !selectAllItems;
        setSelectAllItems(allSelected);
        setSelectAllStocks(allSelected);
        const newSelectedStocks = {};
        if (allSelected) {
            Object.keys(stockInstances).forEach(itemID => {
                stockInstances[itemID].stocks.forEach(stock => {
                    newSelectedStocks[stock.stockID] = true;
                });
            });
        } else {
            setSelectedItemID(null);
        }
        setSelectedStocks(newSelectedStocks);
    };

    const updateSelectAllItems = (newSelectedStocks) => {
        const allSelected = Object.keys(stockInstances).every(itemID =>
            stockInstances[itemID].stocks.every(stock => newSelectedStocks[stock.stockID])
        );
        setSelectAllItems(allSelected);
    };

    const updateSelectAllStocks = (itemID, newSelectedStocks = selectedStocks) => {
        if (itemID && stockInstances[itemID]) {
            const allSelected = stockInstances[itemID].stocks.every(stock => newSelectedStocks[stock.stockID]);
            setSelectAllStocks(allSelected);
        }
    };

    const handleNext = () => {
        setActiveStep(prevStep => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevStep => prevStep - 1);
    };

    const handleSubmit = () => {
        let stockIDs = []
        Object.keys(selectedStocks).forEach(stockID => {
            if (selectedStocks[stockID] === true) {
                stockIDs.push(stockID);
            }
        });
        try {
            const client = axios.create({ baseURL: APIServerPath });
            const requestData = {labID: `${localStorage.labID}`, stockIDs};
            const response = client.post('/discard_stocks', requestData, { headers });
        } catch {
            setAlertMsg("Failed to Discard Stock.");
            setAlertSeverity("error");
            setAlertOpen(true);
        }
        finally {
            setAlertMsg("Successfully Discarded Stock.");
            setAlertSeverity("success");
            setAlertOpen(true);
            onClose();
        }
    };

    const steps = ['Select Items and Stocks', 'Review and Confirm'];

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" aria-labelledby="discard-dialog-title">
            <DialogTitle id="discard-dialog-title">Items to Discard</DialogTitle>
            <DialogContent dividers>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === 0 ? (
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Box sx={{ backgroundColor: '#f0f0f0', padding: 2, borderRadius: 2, height: 500, overflow: 'auto' }}>
                                {loading ? (
                                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <>
                                        <ListItem>
                                            <Checkbox
                                                checked={selectAllItems}
                                                onChange={handleSelectAllItemsChange}
                                                inputProps={{ 'aria-label': 'select all items' }}
                                            />
                                            <ListItemText primary="Select All Items" />
                                        </ListItem>
                                        <List dense>
                                            {selectedItems.length > 0 ? selectedItems.map(item => (
                                                <ListItem button key={item.itemID} onClick={() => handleItemClick(item.itemID)} selected={selectedItemID === item.itemID} sx={{ bgcolor: theme.palette.action.hover }}>
                                                    <ListItemText primary={item.itemName} secondary={`ID: ${item.itemID}`} />
                                                </ListItem>
                                            )) : (
                                                <Typography variant="body2" color="textSecondary" align="center">
                                                    No items to display
                                                </Typography>
                                            )}
                                        </List>
                                    </>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <Box sx={{ backgroundColor: '#f0f0f0', padding: 2, borderRadius: 2, height: 500, overflow: 'auto' }}>
                                {selectedItemID && stockInstances[selectedItemID] ? (
                                    <>
                                        <ListItem>
                                            <Checkbox
                                                checked={selectAllStocks}
                                                onChange={handleSelectAllStocksChange}
                                                inputProps={{ 'aria-label': 'select all stocks' }}
                                            />
                                            <ListItemText primary="Select All Stocks" />
                                        </ListItem>
                                        {stockInstances[selectedItemID].stocks.length > 0 ? (
                                            <List dense>
                                                {stockInstances[selectedItemID].stocks.map(stock => (
                                                    <ListItem key={stock.stockID} sx={{ bgcolor: theme.palette.action.hover }}>
                                                        <Checkbox
                                                            checked={selectedStocks[stock.stockID] || false}
                                                            onChange={() => handleStockCheckboxChange(stock.stockID)}
                                                            inputProps={{ 'aria-label': `select stock ${stock.stockID}` }}
                                                        />
                                                        <ListItemText
                                                            primary={`Location: ${stock.locationName}`}
                                                            secondary={`Stock ID: ${stock.stockID}, Quantity: ${stock.quantity}`}
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        ) : (
                                            <Typography variant="body2" color="textSecondary" align="center">
                                                No stocks available for this item.
                                            </Typography>
                                        )}
                                    </>
                                ) : (
                                    <Typography variant="body2" color="textSecondary" align="center">
                                        Select an item to view its stocks.
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Box sx={{ backgroundColor: '#f0f0f0', padding: 2, borderRadius: 2, height: 500, overflow: 'auto' }}>
                        <Typography variant="h6" gutterBottom>
                            Review Selected Stocks
                        </Typography>
                        {Object.keys(selectedStocks).length > 0 ? (
                            <List dense>
                                {Object.entries(selectedStocks).map(([stockID, isSelected]) => {
                                    if (isSelected) {
                                        const stock = stockMapping[stockID];
                                        if (stock) {
                                            return (
                                                <ListItem key={stockID} sx={{ bgcolor: theme.palette.action.hover }}>
                                                    <ListItemText
                                                        primary={`Location: ${stock.locationName}`}
                                                        secondary={`Item ID: ${stock.itemID}, Stock ID: ${stockID}, Quantity: ${stock.quantity}, LocationX: ${stock.locationX}, LocationY: ${stock.locationY}`}
                                                    />
                                                </ListItem>
                                            );
                                        }
                                    }
                                    return null;
                                })}
                            </List>
                        ) : (
                            <Typography variant="body2" color="textSecondary" align="center">
                                No stocks selected.
                            </Typography>
                        )}
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                {activeStep === 0 ? (
                    <>
                        <Button onClick={onClose} color="secondary">Cancel</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={Object.keys(selectedStocks).length === 0}
                        >
                            Next
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={handleBack} color="secondary">Back</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={Object.keys(selectedStocks).length === 0}
                        >
                            Confirm
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default DiscardItemCard;
