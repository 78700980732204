import { useState, useEffect, useContext } from 'react';
import React from 'react';
import { Box, Typography, Dialog, Alert, DialogTitle, DialogContent, Grid, Button, TextField, Select, MenuItem, Tabs, Tab, FormControlLabel, Checkbox, Autocomplete} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingContext } from '../../../App';
import APIServerPath from '../../Constants/APIServerPath';
import FileUpload from '../../FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import TransferList from '../../TransferList';
import axios from 'axios';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import numericInputFilter from '../../../helperFunctions/numericInputFilter';


function SampleGroupsTabCard (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const mode = props.mode;//modes are add or update
    const sampleGroupID = props.sampleGroupData.sampleGroupID;
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = useState(null);
    //form data
    const [sampleGroupName, setSampleGroupName] = useState(props.sampleGroupData.sampleGroupName);
    const [sampleGroupDescription, setSampleGroupDescription] = useState(props.sampleGroupData.sampleGroupDescription);
    const [modificationComments, setModificationComments] = useState(props.sampleGroupData.modificationComments);
    const [revisionID, setRevisionID] = useState(props.sampleGroupData.revisionID);
    const [files, setFiles] = useState([]);
    const [updateFile, setUpdateFile] = useState(0);
    //Update mode data
    const [selectedNoPermissions, setSelectedNoPermissions] = useState([]);
    const [selectedViewPermissions, setSelectedViewPermissions] = useState([]);
    const [selectedEditPermissions, setSelectedEditPermissions] = useState([]);
    const [userTable, setUserTable] = useState({});
    const [selectedAddUserAccess, setSelectedAddUserAccess] = useState(null);

    //Sample group parameters
    const [selectedSampleGroupParameter, setSelectedSampleGroupParameter] = useState(null);
    const [sampleGroupParameterRows, setSampleGroupParameterRows] = useState([]);
    const [parameterName, setParameterName] = useState(null);
    const [parameterDescription, setParameterDescription] = useState(null);


    const handleParameterRemoval = (toBeRemovedID) => {
        let tempParameterRows = sampleGroupParameterRows.slice();
        for (let i=0; i < tempParameterRows.length; i++) {
            if (tempParameterRows[i].sampleGroupPropertiesID === toBeRemovedID){
                let removalIndex = i;
                tempParameterRows.splice(removalIndex, 1);
                break;
            }
        }
        setSampleGroupParameterRows(tempParameterRows);
    }

    const handleAddParameter = () => {
        //find free ids
        let tempParameterRows = sampleGroupParameterRows.slice();;
        let highestID = 0;
        for (let i=0; i<tempParameterRows.length; i++){
            if (+tempParameterRows[i].id > highestID) {
                highestID = +tempParameterRows[i].id;
            }
        }
        highestID++;
        let newParameterRow = {
            id:highestID,
            sampleGroupPropertiesID:highestID, 
            parameterName:"", 
            parameterDescription:""
        };
        tempParameterRows.push(newParameterRow);
        setSampleGroupParameterRows(tempParameterRows);
        setSelectedSampleGroupParameter(newParameterRow);
    }

    const handleSaveParameter = () => {
        //find correct item in array of actions
        let tempParameter = selectedSampleGroupParameter;
        let tempParameterRows = sampleGroupParameterRows.slice();
        tempParameter.parameterName = parameterName;
        tempParameter.parameterDescription = parameterDescription;
        for (let i=0;i<tempParameterRows.length;i++) {
            if (tempParameter.id === tempParameterRows[i].id &&  tempParameter.sampleGroupPropertiesID === tempParameterRows[i].sampleGroupPropertiesID) {
                tempParameterRows[i] = tempParameter;
            break;
            }
        }
        setSampleGroupParameterRows(tempParameterRows);
        setSelectedSampleGroupParameter(null);
        setParameterName(null);
        setParameterDescription(null);
    }
    const sampleGroupParameterColumns = [
        {
            field: 'parameterName',
            headerName: 'Name',
            flex: 1,
            editable: false,
        },
        {
            field: 'parameterDescription',
            headerName: 'Description',
            flex: 2,
            editable: false,
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div>
                        <DeleteForeverIcon color="primary" onClick={event =>  handleParameterRemoval(params.row.sampleGroupPropertiesID)} />
                    </div>
                );
            }
        }
    ];


    //generate tab rendering
    const [currentTab, setCurrentTab] = useState("0");
    const [tabRenderData, setTabRenderData] = useState([]);
    const tabNames = {
        "0" : "General",
        "1" : "Parameters",
        "2" : "Access",
    };

    const handleTabChange = (event, newTab) => {
        setCurrentTab(newTab);
    };

    useEffect(()=> {//tab rendering
        let renderData = [];
        Object.keys(tabNames).forEach(function(key, index) {
            renderData.push(<Tab value={key} label={tabNames[key]}/>);
        });
        setTabRenderData(renderData);
        renderData = [];
    }, [currentTab]);


    useEffect(()=> {//Update array of user access arrays upon selection of new user
        if (selectedAddUserAccess) {
            let tempViewPerms = selectedViewPermissions;
            tempViewPerms.push(selectedAddUserAccess);
            setSelectedViewPermissions(tempViewPerms);
            let tempNoPerms = selectedNoPermissions;
            tempNoPerms.splice(tempNoPerms.indexOf(selectedAddUserAccess), 1);
            setSelectedAddUserAccess("");
        }
    }, [selectedAddUserAccess]);

    //handle add or update request
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.currentTarget);
        formData.append("lab_ID", localStorage.labID);
        formData.append("sampleGroupName", sampleGroupName);
        formData.append("sampleGroupDescription", sampleGroupDescription);
        if (mode === "update"){
            formData.append("sampleGroupID", sampleGroupID);
            formData.append("sampleGroupParameters", JSON.stringify(sampleGroupParameterRows));
            formData.append("permissions", JSON.stringify({
                0: selectedNoPermissions,
                1: selectedViewPermissions,
                2: selectedEditPermissions
            }));
            const response = client.post(
                'update_sample_group', 
                formData,
                {headers}
                ).then(function (response) {
                    if (response.data.msg) {//show error alert
                        setErrorMsg(response.data.msg);
                        setLoading(false);
                    } else{//save token and name to cookies and redirect to dashboard
                        setErrorMsg(null);
                        setLoading(false);
                        props.close();
                    }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        } else {
            const response = client.post(
                'add_sample_group', 
                formData,
                {headers}
                ).then(function (response) {
                    if (response.data.msg) {//show error alert
                        setErrorMsg(response.data.msg);
                        setLoading(false);
                    } else{//save token and name to cookies and redirect to dashboard
                        setErrorMsg(null);
                        setLoading(false);
                        props.close();
                    }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        }
    };

    //retrieve sample group information
    useEffect(()=> {
        if (mode === "update") {
            setLoading(true);
            let formData = new FormData();
            formData.append("lab_ID", localStorage.labID);
            formData.append("sampleGroupID", sampleGroupID);
            formData.append("sampleGroupData", 0);
            formData.append("sampleGroupAccess", 1);
            formData.append("sampleGroupParameters", 1);
            const response = client.post(
                'retrieve_sample_group_data', 
                formData,
                {headers}
                ).then(function (response) {
                if (response.data.msg) {//Error messages
                    setErrorMsg(response.data.msg);
                    setLoading(false);
                } else{//
                    //build data for transfer list of sampleGroup access
                    console.log(response.data);
                    let tempUserTable = {};
                    let tempViewPerms = [];
                    let tempEditPerms = [];
                    let tempNoPerms = [];
                    tempUserTable[""] = {name:""};//prevent no key error in autocomplete when there are no users left
                    for (let i = 0; i < response.data.sampleGroupAccess.length; i++) {
                        tempUserTable[response.data.sampleGroupAccess[i].uID] = {name:response.data.sampleGroupAccess[i].name};
                        if (!response.data.sampleGroupAccess[i].permissions || response.data.sampleGroupAccess[i].permissions === 0){
                            tempNoPerms.push(response.data.sampleGroupAccess[i].uID);
                        } else if (response.data.sampleGroupAccess[i].permissions === 1) {
                            tempViewPerms.push(response.data.sampleGroupAccess[i].uID);
                        } else if (response.data.sampleGroupAccess[i].permissions === 2) {
                            tempEditPerms.push(response.data.sampleGroupAccess[i].uID);
                        }
                    }
                    setSelectedNoPermissions(tempNoPerms);
                    setSelectedViewPermissions(tempViewPerms);
                    setSelectedEditPermissions(tempEditPerms);
                    setUserTable(tempUserTable);
                    setSampleGroupParameterRows(response.data.sampleGroupParameters);
                    setErrorMsg(null);
                    setLoading(false);
                }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        }
    }, [sampleGroupID]);

    return (
        <React.Fragment>
            <Dialog
            open={sampleGroupID} 
            onClose={()=>{props.close();}}
            fullWidth
            maxWidth="lg"
            >
                <DialogTitle id="scroll-dialog-title">
                    {(mode === "update") ?
                    "Update Sample Group":
                    "Add Sample Group"
                    }
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 500, width:'100%'}} component="form" noValidate onSubmit={handleSubmit} justifyContent="center" alignment="center">
                        <Grid 
                        container 
                        justifyContent="center"
                        alignItems="center"
                        sx={{ p: 1}}>
                            {errorMsg ? 
                            <Grid item xs={12} sx={{p:1}}>
                                <Alert severity="error">{errorMsg}</Alert>
                            </Grid>
                            :null}
                            {(mode === "update") ?
                            <Grid item xs={12} sx={{}}>
                                <Tabs 
                                value={currentTab} 
                                onChange={handleTabChange}
                                centered
                                >
                                    {tabRenderData}
                                </Tabs>
                            </Grid>
                            :null}
                            
                            {currentTab==="0" ?
                            <React.Fragment>
                                <Grid item xs={4} sx={{p:2}}>
                                    <Typography variant="h6" align="center">
                                        Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sx={{p:2}}>
                                    <TextField
                                    id="sample-group-name"
                                    label="Name"
                                    value={sampleGroupName}
                                    size="small"
                                    onChange={(event) => {
                                        setSampleGroupName(event.target.value);
                                    }}
                                    fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} sx={{p:2}}>
                                    <Typography variant="h6" align="center">
                                        Description
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sx={{p:2}}>
                                    <TextField
                                    id="sample-group-description"
                                    label="Description"
                                    value={sampleGroupDescription}
                                    onChange={(event) => {
                                        setSampleGroupDescription(event.target.value);
                                    }}
                                    fullWidth
                                    multiline
                                    />
                                </Grid>
                            </React.Fragment>
                            :null}
                            {(mode === "update")&& currentTab==="1" &&
                            <React.Fragment>
                                <Grid 
                                item
                                xs={selectedSampleGroupParameter? 
                                7
                                :12
                                } sx={{p:2}}>
                                    <Box sx={{ height: 350, width: '100%' }}>
                                        <Button 
                                        variant="outlined" 
                                        onClick={()=>{
                                            handleAddParameter();
                                        }}>
                                            Add New Parameter
                                            </Button>
                                        <DataGrid
                                        rows={sampleGroupParameterRows}
                                        columns={sampleGroupParameterColumns}
                                        disableRowSelectionOnClick
                                        onCellDoubleClick={(params, event, details)=> {
                                            //check permissions to modify project information
                                            //set selectedProtocol
                                            setSelectedSampleGroupParameter(params.row);
                                            setParameterName(params.row.parameterName);
                                            setParameterDescription(params.row.parameterDescription);
                                        }}
                                        />
                                    </Box>
                                </Grid>
                                {selectedSampleGroupParameter?
                                <Grid item xs={5} sx={{p:2}}>
                                    <Box sx={{ height: 350, width: '100%' }}>
                                        <Button 
                                        variant="outlined" 
                                        align="right"
                                        onClick={()=>{
                                            handleSaveParameter();
                                        }}>
                                            Close Parameter
                                        </Button>
                                        <Grid container>
                                            <Grid item xs={4} sx={{p:2}}>
                                                <Typography variant="subtitle1" align="center">
                                                    Name
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} sx={{p:2}}>
                                                <TextField
                                                id="parameter-name"
                                                label="Name"
                                                value={parameterName}
                                                onChange={(event) => {
                                                    setParameterName(event.target.value);
                                                }}
                                                fullWidth
                                                multiline
                                                />
                                            </Grid>
                                            <Grid item xs={4} sx={{p:2}}>
                                                <Typography variant="subtitle1" align="center">
                                                    Description
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} sx={{p:2}}>
                                                <TextField
                                                id="parameter-description"
                                                label="Description"
                                                value={parameterDescription}
                                                onChange={(event) => {
                                                    setParameterDescription(event.target.value);
                                                }}
                                                fullWidth
                                                multiline
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                :null}
                            </React.Fragment>
                            }
                            {(mode === "update")&& currentTab==="2" &&
                            <React.Fragment>
                                <Grid item xs={3} sx={{p:2}}>
                                    <Typography variant="h6" align="center">
                                        Add User to Access
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} sx={{p:2}}>
                                <Autocomplete
                                freeSolo
                                id="AddUserAccess"
                                disableClearable
                                options={selectedNoPermissions}
                                onChange={(event, newUID) => {
                                    setSelectedAddUserAccess(newUID);
                                }}
                                getOptionLabel={(option) => userTable[option].name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search Users"
                                        InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        }}
                                    />
                                )}/>
                                </Grid>
                                <Grid item xs={12} sx={{p:2}}>
                                    <TransferList
                                    left={selectedViewPermissions}
                                    setLeft={setSelectedViewPermissions}
                                    right={selectedEditPermissions}
                                    setRight={setSelectedEditPermissions}
                                    leftLabel="View Only"
                                    rightLabel="View & Edit"
                                    definitions={userTable}
                                    removal = {selectedNoPermissions}
                                    setRemoval = {setSelectedNoPermissions}
                                    />
                                </Grid>
                            </React.Fragment>
                            }
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {(mode === "update") ?
                                "Update Sample Group":
                                "Add Sample Group"
                                }
                            </Button> 
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
)
}

export default SampleGroupsTabCard;