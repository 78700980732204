import { useState, useEffect, useContext } from 'react';
import React from 'react';
import { Box, Typography, Dialog, Alert, DialogTitle, DialogContent, Grid, Button, TextField, Select, MenuItem} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingContext } from '../../../App';
import APIServerPath from '../../Constants/APIServerPath';
import TransferList from '../../TransferList';
import axios from 'axios';

function ProjectsTabCard (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const mode = props.mode;
    const projectID = props.projectData.projectID;
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = useState(null);
    //form data
    const [projectName, setProjectName] = React.useState(props.projectData.projectName);
    const [projectActive, setProjectActive] = React.useState(props.projectData.active);
    const [projectDescription, setProjectDescription] = React.useState(props.projectData.projectDescription);
    const [selectedProtocols, setSelectedProtocols] = React.useState([]);
    const [unselectedProtocols, setUnselectedProtocols] = React.useState([]);
    const [selectedSampleGroups, setSelectedSampleGroups] = React.useState([]);
    const [unselectedSampleGroups, setUnselectedSampleGroups] = React.useState([]);
    const [protocolDefinitions, setProtocolDefinitions] = React.useState({});
    const [sampleGroupDefinitions, setSampleGroupDefinitions] = React.useState({});

    //Update user data
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.currentTarget);
        formData.append("lab_ID", localStorage.labID);
        formData.append("projectName", projectName);
        formData.append("projectDescription", projectDescription);
        formData.append("active", projectActive);
        if (mode === "update"){
            formData.append("projectID", projectID);
            formData.append("selectedProtocols", selectedProtocols);
            formData.append("selectedSampleGroups", selectedSampleGroups);
            const response = client.post(
                'update_project', 
                formData,
                {headers}
                ).then(function (response) {
                    if (response.data.msg) {//show error alert
                        setErrorMsg(response.data.msg);
                        setLoading(false);
                    } else{//save token and name to cookies and redirect to dashboard
                        setErrorMsg(null);
                        setLoading(false);
                        props.close();
                    }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        } else {
            const response = client.post(
                'add_project', 
                formData,
                {headers}
                ).then(function (response) {
                    if (response.data.msg) {//show error alert
                        setErrorMsg(response.data.msg);
                        setLoading(false);
                    } else{//save token and name to cookies and redirect to dashboard
                        setErrorMsg(null);
                        setLoading(false);
                        props.close();
                    }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        }
    };

    //retrieve user permissions associated with lab
    useEffect(()=> {
        if (mode === "update") {
            setLoading(true);
            let formData = new FormData();
            formData.append("lab_ID", localStorage.labID);
            formData.append("project_ID", projectID);
            const response = client.post(
                'retrieve_project_data', 
                formData,
                {headers}
                ).then(function (response) {
                if (response.data.msg) {//Error messages
                    setErrorMsg(response.data.msg);
                    setLoading(false);
                } else{//
                    setProjectName(response.data.projectName);
                    setProjectDescription(response.data.projectDescription);
                    setProjectActive(response.data.active);
                    setSelectedProtocols(response.data.assignedProtocols);
                    setSelectedSampleGroups(response.data.assignedSampleGroups);
                    setUnselectedProtocols(response.data.unassignedProtocols);
                    setUnselectedSampleGroups(response.data.unassignedSampleGroups);
                    setProtocolDefinitions(response.data.protocolDefinitions);
                    setSampleGroupDefinitions(response.data.sampleGroupDefinitions);
                    setErrorMsg(null);
                    setLoading(false);
                }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        }
    }, [projectID]);

    return (
        <React.Fragment>
            <Dialog
            open={projectID} 
            onClose={()=>{props.close();}}
            fullWidth
            maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">
                    {(mode === "update") ?
                    "Update Project":
                    "Add Project"
                    }
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 500, width:'100%'}} component="form" noValidate onSubmit={handleSubmit} justifyContent="center" alignment="center">
                    <Grid 
                    container 
                    justifyContent="center"
                    alignItems="center"
                    sx={{ p: 2}}>
                        <Grid item xs={12} sx={{p:1}}>
                            {errorMsg ? <Alert severity="error">{errorMsg}</Alert>:null}
                        </Grid>
                        <Grid item xs={4} sx={{p:2}}>
                            <Typography variant="h6" align="center">
                                Project Name
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{p:2}}>
                            <TextField
                            id="outlined-controlled"
                            label="Name"
                            value={projectName}
                            onChange={(event) => {
                                setProjectName(event.target.value);
                            }}
                            fullWidth
                            />
                        </Grid>
                        <Grid item xs={4} sx={{p:2}}>
                            <Typography variant="h6" align="center">
                                Project Description
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{p:2}}>
                            <TextField
                            id="outlined-controlled"
                            label="Description"
                            value={projectDescription}
                            onChange={(event) => {
                                setProjectDescription(event.target.value);
                            }}
                            fullWidth
                            multiline
                            />
                        </Grid>
                        <Grid item xs={4} sx={{p:2}}>
                            <Typography variant="h6" align="center">
                                Project Status
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{p:2}}>
                            <Select
                            labelId="select-project-active"
                            id="select-project-active"
                            value={projectActive}
                            label="Project Status"
                            onChange={(event) => {
                                setProjectActive(event.target.value);
                            }}
                            fullWidth
                            >
                                <MenuItem value={0}>Inactive</MenuItem>
                                <MenuItem value={1}>Active</MenuItem>
                            </Select>
                        </Grid>
                        {(mode === "update")&&
                        <React.Fragment>
                            <Grid item xs={12} sx={{p:2}}>
                                <Typography variant="h6" align="center">
                                    Protocol Assignments
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{p:2}}>
                                <TransferList
                                left={selectedProtocols}
                                setLeft={setSelectedProtocols}
                                right={unselectedProtocols}
                                setRight={setUnselectedProtocols}
                                leftLabel="Selected"
                                rightLabel="Non-Selected"
                                definitions={protocolDefinitions}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{p:2}}>
                                <Typography variant="h6" align="center">
                                    Sample Group Assignments
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{p:2}}>
                                <TransferList
                                left={selectedSampleGroups}
                                setLeft={setSelectedSampleGroups}
                                right={unselectedSampleGroups}
                                setRight={setUnselectedSampleGroups}
                                leftLabel="Selected"
                                rightLabel="Non-Selected"
                                definitions={sampleGroupDefinitions}
                                />
                            </Grid>
                        </React.Fragment>
                        }
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {(mode === "update") ?
                            "Update Project":
                            "Add Project"
                            }
                        </Button>
                    </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
)
}

export default ProjectsTabCard;