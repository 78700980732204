import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import NavigationMenu from '../components/layout/NavigationMenu';
import { useState, useEffect, useContext } from 'react';
import { Tabs, Tab , Box, Typography, Divider, Alert} from '@mui/material';
import UserTab from '../components/LabSettings/UsersTab/UserTab';
import ProjectsTab from '../components/LabSettings/ProjectsTab/ProjectsTab';
import LabelTemplateTab from '../components/LabSettings/LabelTemplateTab/LabelTemplateTab';
import ProtocolsTab from '../components/LabSettings/ProtocolsTab/ProtocolsTab';
import SampleGroupsTab from '../components/LabSettings/SampleGroups/SampleGroupsTab';
import SampleAliquotGroupsTab from '../components/LabSettings/SampleAliquotGroups/SampleAliquotGroupsTab';
import PatientSettingsTab from '../components/LabSettings/PatientSettings/PatientSettingsTab';
import Copyright from '../components/layout/Copyright';

function LabSettings () {
    const [currentTab, setCurrentTab]  = useState("0");
    const [errorMsg, setErrorMsg] = useState(null);
    const [tabRenderData, setTabRenderData] = useState([]);
    const tabNames = {
        "0" : "Users",
        "1" : "Projects",
        "2" : "Protocols",
        "3" : "Patient Settings",
        "4" : "Sample Groups",
        "5" : "Sample Aliquot Groups",
        "6" : "Label Templates"
    };

    const handleTabChange = (event, newTab) => {
        setCurrentTab(newTab);
    };

    //Generate tab renders
    useEffect(()=> {
        let tabRender = [];
        Object.keys(tabNames).forEach(function(key, index) {
            tabRender.push(<Tab value={key} label={tabNames[key]}/>);
        });
        setTabRenderData(tabRender);
    }, []);

    return (
        <div>
            <NavigationMenu/>
            <Container component="main" >
                <CssBaseline />
                {errorMsg ? 
                <Box sx={{m:1}}>
                    <Alert severity="error" >{errorMsg} </Alert>
                </Box> 
                :null}
                <Box sx={{mt:2}}>
                    <Typography variant="h5" align="left">
                        Lab Settings - {localStorage.labName}
                    </Typography>
                </Box> 
                <Divider sx={{m:0}}/>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs 
                    value={currentTab} 
                    onChange={handleTabChange}
                    variant="scrollable"
                    >
                        {tabRenderData}
                    </Tabs>
                </Box>

                <Box sx={{m:2}}>
                    {currentTab === "0" && <UserTab errorMsg={errorMsg} setErrorMsg={setErrorMsg}/>}
                    {currentTab === "1" && <ProjectsTab errorMsg={errorMsg} setErrorMsg={setErrorMsg}/>}
                    {currentTab === "2" && <ProtocolsTab errorMsg={errorMsg} setErrorMsg={setErrorMsg}/>}
                    {currentTab === "3" && <PatientSettingsTab errorMsg={errorMsg} setErrorMsg={setErrorMsg}/>}
                    {currentTab === "4" && <SampleGroupsTab errorMsg={errorMsg} setErrorMsg={setErrorMsg}/>}
                    {currentTab === "5" && <SampleAliquotGroupsTab errorMsg={errorMsg} setErrorMsg={setErrorMsg}/>}
                    {currentTab === "6" &&<LabelTemplateTab errorMsg={errorMsg} setErrorMsg={setErrorMsg}/>}
                </Box>
            </Container>
            <Copyright/>
        </div>
    )
}

export default LabSettings;