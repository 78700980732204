import React, {useState, useEffect } from "react";
import { 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    DialogActions, 
    FormControl, 
    TextField, 
    Button, 
    Select, 
    MenuItem, 
    Grid, 
    Paper, 
    Stepper, 
    Step, 
    StepLabel, 
    Slider, 
    Typography, 
    FormControlLabel, 
    Checkbox, 
    InputLabel 
} from "@mui/material";
import TemplateDropDown from "./TemplateDropDown";
import axios from "axios";
import APIServerPath from "../Constants/APIServerPath";


const samplePrintTemplate = {
    "pageHeight" : 279.4, //required
    "pageWidth" : 215.9, //required
    "noColumns" : 5, //required
    "noRows" : 17, //required
    "labelHeight" : 12.7, //required
    "labelWidth" : 32.5, //required
    "columnGap" : 0, //default 0, any value will cause shift in margins as column gaps will be overriden 
    "rowGap" : 0, //default 0, any value will cause shift in margins as row gaps will be overriden 
    "leftMargin" : 19.6, //required
    "rightMargin" : 19.6, //defaults to same as left margin
    "topMargin" : 6.1, //required
    "bottomMargin" : 6.1, //defaults to same as top margin
    "labelLeftPadding" : 0.05, // UNITS ARE % of LABEL WIDTH defaults to 0.05 (5%)
    "labelRightPadding" : 0.05,// UNITS ARE % of LABEL WIDTH defaults to 0.05 (5%)
    "labelTopPadding" : 0.05, // UNITS ARE % of LABEL HEIGHT defaults to 0.05 (5%)
    "labelBottomPadding" : 0.05, // UNITS ARE % of LABEL HEIGHT defaults to 0.05 (5%)
    "cornerRadius" : 0, //defaults to 0
    "qrSize" : 12.7 * .9, //Defaults to label height - top and bottom padding (90% of label height with default padding)
    "qrFix" : "tr", //defaults to "tl" indicating top-left. Accepted values are tl, tr, bl, br
    "units" : "mm", //required
    "border" : true, //required
};

const GenerateQRCodes = (props) => {
    const {onClose, itemIDs} = props;
    const headers = { 
        'Authorization': `Bearer ${localStorage.access_token}`
    };
    
    const client = axios.create({
      baseURL: APIServerPath
    });

    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedTemplateID, setSelectedTemplateID] = useState(null);
    const [templateData, setTemplateData] = useState([]);
    const [rawTemplateData, setRawTemplateData] = useState([]);
    
    const [fontName, setFontName] = useState("");
    const [fontSize, setFontSize] = useState(1);
    const [grid, setGrid] = useState([]);
    const [userTemplates, setUserTemplates] = useState([]);
    const [labelData, setLabelData] = useState("");
    const [data, setData] = useState("");
    const [showGrid, setShowGrid] = useState(false);
    const [coordinateTuples, setCoordinateTuples] = useState([]);
    const labID = localStorage.getItem('labID');
    const accessToken = localStorage.getItem('access_token');

    const steps = [
        'Select Print Template', 
        'Font Details', 
        'Add Print Coordinates',
    ];



    const fetchTemplates = async () => {
        try {
            
            const accessToken = localStorage.getItem('access_token');
            const lab_ID = localStorage.getItem('labID');

            console.log("Access Token:", accessToken);

            if (!accessToken || !lab_ID) {
                console.error("Access token or Lab ID is missing in localStorage");
                return;
            }
            
            const formData = new FormData();
            //formData.append(headers);
            formData.append('lab_ID', lab_ID);

            // Log formData content
            for (let pair of formData.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }

            console.log("Headers:", headers);

            const response = await axios.post(`${APIServerPath}retrieve_label_templates`, formData, { headers });
            
            console.log("Response:", response.data);
            //setUserTemplates(response.data.userTemplates);

            if (response.data && response.data.rowData) {
                const templates = response.data.rowData.map(template => ({
                    id: template.id,
                    name: template.templateName
                }));
                setRawTemplateData(response.data.rawTemplateData);
                setTemplateData(templates);
            } else {
                console.error("No templates found in the response");
            }
        } catch (error) {
            console.error("Error fetching templates:", error);
            if (error.response) {
                console.error("Response data:", error.response.templateData);
            }
        }
    };
    
    useEffect(() => {
        console.log("Component mounted");
        setOpen(true);
    }, []);

    useEffect(() => {
        console.log(`Dialog open state #3: $(open)`);
    }, [open]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setSelectedTemplate(null);
        setFontName("");
        setFontSize(1);
        setCoordinateTuples([]);
        setGrid([]);
        setShowGrid(false);
    };

    const handleOpen = () => {
        console.log("Opening dialog...");
        console.log("handleOpen called");
        setOpen(true);
    };

    useEffect(() => {
            console.log("Dialog open state #1:", open);
        }, [open]);

    const handleClose = () => {
        setOpen(false);
        //handleReset();
        onClose();
    };

    useEffect(() => {
        if (selectedTemplate) {
            // const { noRows, noColumns } = selectedTemplate;
            // const newGrid = Array.from({ length: noRows }, () => 
            //     Array.from({ length: noColumns }, () => false));
            // setGrid(newGrid);
            console.log("Selected Template Changed:", selectedTemplate);
            initializeGrid(selectedTemplate.noRows, selectedTemplate.noColumns);
        }
    }, [selectedTemplate]);

    useEffect(() => {
        //if on step 0 (templateDropDown), retrieve and save template data
        if (activeStep === 0) {
            fetchTemplates();
        }
        if (activeStep === 1) {
            let noRows = rawTemplateData[selectedTemplate].noRows;
            let noColumns = rawTemplateData[selectedTemplate].noColumns;
            initializeGrid(noRows, noColumns);

        }
        // console.log("Active Step:", activeStep);
        // console.log("Grid State:", grid);
    }, [activeStep, grid]);

    const handleFontNameChange = (event) => {
        setFontName(event.target.value);
    };

    const handleFontSizeChange = (event, newValue) => {
        setFontSize(newValue);
    };

    const initializeGrid = (rows, columns) => {
        //console.log("Initializing grid with rows and columns:", rows, columns);
        const newGrid = Array.from({ length: rows }, () =>
            Array.from({ length: columns }, () => false)
        );
        setGrid(newGrid);
        //console.log("Initialized Grid:", newGrid)
    };

    // useEffect(() => {
    //     if (selectedTemplate) {
    //         initializeGrid(selectedTemplate.noRows, selectedTemplate.noColumns);
    //     }
    // }, [selectedTemplate]);

    const handleCellClick = (rowIndex, colIndex) => {
        setGrid((prevGrid) => {
            const newGrid = prevGrid.map((row, rIndex) => 
                row.map((cell, cIndex) => 
                    (rIndex === rowIndex && cIndex === colIndex ? !cell : cell))
            );
            return newGrid;
        });
        const cellCoordinates = { x: colIndex, y: rowIndex };

        if (grid[rowIndex][colIndex]) {
            setCoordinateTuples((prevTuples) =>
                prevTuples.filter(
                    (tuple) => 
                        tuple.x !== cellCoordinates.x || tuple.y !== cellCoordinates.y
                )
            );
        } else {
            setCoordinateTuples((prevTuples) => [...prevTuples, cellCoordinates]);
        }
    };

    const renderGrid = () => {
        console.log("Rendering grid with state:", grid);
        const noRows = rawTemplateData[selectedTemplate].noRows;
        const noColumns = rawTemplateData[selectedTemplate].noColumns;
        const gridHeight = 400;
        const gridWidth = 400;
        const cellHeight = gridHeight/noRows;
        const cellWidth = gridWidth/noRows;
        return (
            <Grid container spacing={1}>
                {grid.map((row, rowIndex) => (
                    <Grid container item key={rowIndex} spacing={1}>
                        {row.map((col, colIndex) => (
                            <Grid item key={colIndex}>
                                <Paper
                                    onClick={() => handleCellClick(rowIndex, colIndex)}
                                    style={{
                                        height: cellHeight,
                                        width: cellWidth,
                                        //height: 20,
                                        //width: 20,
                                        backgroundColor: col ? "blue" : "white",
                                        border: "1px solid black",
                                    }}
                                />
                            </Grid>
                        ))} 
                    </Grid>
                ))}
            </Grid>
        );
    };



    const handleSubmit = async () => {
        try {
            console.log("selectedTemplate:", selectedTemplate)
            console.log("Selected Template:", selectedTemplate.noRows);
            console.log("Raw template data:", rawTemplateData);
            console.log("itemIDs:", itemIDs);
            console.log("Font Name:", fontName);
            console.log("Font Size:", fontSize);
            console.log("Data:", data);
            console.log("Coordinate Tuples:", coordinateTuples);
            console.log('Token:', localStorage.access_token);
            console.log('Headers:', headers);


            const lab_ID = localStorage.getItem('labID');
            const dataBody = {
                itemIDs: itemIDs,
                labID: lab_ID
            };
            const label_str = await client.post(`${APIServerPath}generate_stock_data_field`, dataBody, { headers });
            console.log("label_string:", label_str);
            //const label_str = itemIDs.map(item => `c_${item.labID}_${item.itemID}`);

            let printTemplateWithoutIDAndName = {};

            if (rawTemplateData[selectedTemplate]) {
                printTemplateWithoutIDAndName = { ...rawTemplateData[selectedTemplate] };
                // Remove the templateID and templateName properties since generatePDF function does not take those
                //delete printTemplateWithoutIDAndName.templateID;
                delete printTemplateWithoutIDAndName.templateName;
            } else {
                console.error(`Template not found for selectedTemplate: ${selectedTemplate}`);
                // Handle or return as needed
            }
            
            const requestBody = {
                printTemplateID: rawTemplateData[selectedTemplate].templateID,
                fontName: fontName,
                fontSize: fontSize,
                label_str: label_str,
                coordinateTuples: coordinateTuples
            };
            console.log("This is request body:", requestBody);
            if (!rawTemplateData[selectedTemplate].templateID || !fontName || !fontSize || !label_str || !coordinateTuples) {
                console.error("GENERATEQRCODES Missing required data parameters");
                return;
            }

            const response = await axios.post(`${APIServerPath}generate_labels`, requestBody, { headers });
            console.log("Full Response:", response);
            console.log("Response:", response.data);
            //let pdfUrl;
            if (response.data && response.data.pdfPath) {
                // const pdfUrl = `${APIServerPath}${response.data.pdfPath}`;
                //const decodedPath = decodeURIComponent(response.data.pdfpath);
                // const pdfUrl = `${APIServerPath}retrieve_file?token=${decodedPath}`;
                //const pdfUrl = `${APIServerPath}retrieve_file?token=${response.data.pdfPath}`;
                const pdfUrl = `${APIServerPath}retrieve_file/${response.data.pdfPath}`;
                console.log("PDF URL:", pdfUrl);

                setTimeout(() => {
                    const newWindow = window.open(pdfUrl, "_blank", "noopener,noreferrer");
                    //const newWindow = window.open(pdfUrl, "_blank");
                    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                        console.error("Failed to open PDF in a new window");
                        window.open(pdfUrl);
                        console.log("Form Submitted");
                    }
                }, 500);
                
                // } else {
                //     console.error("Failed to retrieve the file");
                // }
            } else {
                console.error("Failed to generate PDF labels");
            }
        } catch (error) {
            console.error("Error generating PDF labels:", error);
        }
    };

    
  return (
    <div>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            {console.log("Dialog open state #2:", open)}
            <DialogContent>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === 0 && (
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="template-select-label">
                            
                        </InputLabel>
                        <TemplateDropDown 
                            selectedTemplate={selectedTemplate}
                            setSelectedTemplate={setSelectedTemplate} 
                            selectedTemplateID={selectedTemplateID}
                            setSelectedTemplateID={setSelectedTemplateID} 
                            templateData = {[templateData, setTemplateData]}
                            rawTemplateData = {[rawTemplateData, setRawTemplateData]}
                        />
                    </FormControl>
                )}
                {activeStep === 1 && (
                    <div>
                        <Select
                            value={fontName}
                            onChange={handleFontNameChange}
                            fullWidth
                            label="Font Name"
                            displayEmpty
                        >
                        <MenuItem value="" disabled>Select Font</MenuItem>
                            {[
                                'Courier',
                                'Courier-Bold',
                                'Courier-BoldOblique',
                                'Courier-Oblique',
                                'Helvetica',
                                'Helvetica-Bold',
                                'Helvetica-BoldOblique',
                                'Helvetica-Oblique',
                                'Symbol',
                                'Times-Bold',
                                'Times-BoldItalic',
                                'Times-Italic',
                                'Times-Roman',
                                'ZapfDingbats'
                            ].map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography 
                            id="font-size-slider" 
                            gutterBottom 
                            align="center"
                            variant="body1"
                        >
                            Font Size:
                        </Typography>
                        <Slider
                            value={fontSize}
                            onChange={handleFontSizeChange}
                            min={1}
                            max={(16)}
                            step={1}
                            valueLabelDisplay="auto"
                        />
                        <Typography variant="body2" align="center">
                            {fontSize}
                        </Typography>
                    </div>
                )}
                {activeStep === 2 && (
                    <div>
                        {renderGrid()}
                    </div>
                )}
                </div>
                </DialogContent>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                        {activeStep !== 0 && (
                            <Button onClick={handleBack}>
                                Back
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                    {activeStep < steps.length - 1 && (
                            <Button onClick={handleNext}>
                                Next
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        {activeStep === steps.length - 1 && (
                            <Button onClick={handleSubmit}>
                                Generate
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        <Button onClick={handleClose}>
                            Close
                        </Button>
                    </Grid>
                    <Grid item>
                        {activeStep > 0 && (
                            <Button onClick={handleReset}>
                                Reset
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
};

export default GenerateQRCodes;
