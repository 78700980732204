import * as React from "react";
import { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Paper, Box, Grid, Typography, Alert, LinearProgress } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";
import Copyright from "../components/layout/Copyright";
import { LoadingContext, IsLoadingContext } from "../App";
import APIServerPath from "../components/Constants/APIServerPath";


// TODO remove, this demo shouldn't need to reset the theme.

export default function SignIn() {
  const [errorMsg, setErrorMsg] = useState(null);
  const navigate = useNavigate();
  const loading = React.useContext(IsLoadingContext);
  const setLoading = React.useContext(LoadingContext);

  const client = axios.create({
    baseURL:APIServerPath
  });

  const handleSignUpRedirect = () =>{
    navigate('/create-account');
  }

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const submissionData = {
      email: data.get("email"),
      password: data.get("password"),
    };

    const response = client
      .post("login_request", submissionData)
      .then(function (response) {
        if (response.data.msg) {
          setLoading(false);
          setErrorMsg(response.data.msg);
        } else {
          setLoading(false);
          localStorage.access_token = response.data.access_token;
          localStorage.name = response.data.name;
          setErrorMsg(null);
          navigate('/');
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error, "error"); //implement redirecting to error pages
      });
  };


  if (localStorage.access_token){ //already signed in 
    return (<Navigate to='/' />);
  }

  return (
    <div>
      {loading ? 
        <Box>
          <LinearProgress color="secondary" />
        </Box> : null
      }
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box sx={{my: 1, mx: 1, display: "flex", flexDirection: "column", alignItems: "center", }}> 
              {errorMsg ? <Alert severity="error">{errorMsg}</Alert>:null}
          </Box>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link variant="body2" onClick={handleSignUpRedirect} component="button">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Copyright sx={{ mt: 5 }} />
    </div>
  );
}
