import NavigationMenu from '../components/layout/NavigationMenu';
import { useState, useEffect, useContext, useMemo, useCallback} from 'react';
import {Box, Alert, Snackbar, Button, Typography } from '@mui/material';
import ConsumablesLogFilter from '../components/LabActivity/ConsumablesLogFilter';
import ConsumablesLogTable from '../components/LabActivity/ConsumablesLogTable';

const LabActivity = () => {
    const [filters, setFilters] = useState({
        startDate: null,
        endDate: null,
        actionType: '',
        entityType: ''
    });

    return (
        <Box sx={{ p: 4 }}>
            <NavigationMenu/>
            <Typography variant="h4" mb={2}>Activity Log</Typography>
            <ConsumablesLogFilter filters={filters} setFilters={setFilters} fetchLogs={() => {}} />
            <ConsumablesLogTable filters={filters}/>
        </Box>
    );
};

export default LabActivity;