import { useState, useEffect, useContext } from 'react';
import React from 'react';
import { Box, Typography, Dialog, Alert, DialogTitle, DialogContent, Grid, Button, TextField} from '@mui/material';
import { LoadingContext } from '../../App';
import APIServerPath from '../Constants/APIServerPath';
import axios from 'axios';

function JoinLabCard (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = useState(null);

    //form data
    const [associationCode, setAssociationCode] = React.useState("");
    //Update user data
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.currentTarget);
        formData.append("associationCode", associationCode);
        const response = client.post(
            'join_lab', 
            formData,
            {headers}
            ).then(function (response) {
                if (response.data.msg) {//show error alert
                    setErrorMsg(response.data.msg);
                    setLoading(false);
                } else{//save token and name to cookies and redirect to dashboard
                    setErrorMsg(null);
                    setLoading(false);
                    props.close();
                }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });
        
    };

    return (
        <React.Fragment>
            <Dialog
            open={props.open} 
            onClose={props.close}
            fullWidth
            required
            maxWidth="sm"
            >
                <DialogContent>
                    <Box sx={{ height: 450, width:'100%'}} component="form" noValidate onSubmit={handleSubmit} justifyContent="center" alignment="center">
                    <Grid 
                    container 
                    justifyContent="center"
                    alignItems="center"
                    sx={{ p: 1}}>
                        {errorMsg ? 
                        <Grid item xs={12} sx={{p:1}}>
                            <Alert severity="error">{errorMsg}</Alert>
                        </Grid>
                        :null
                        }
                        <Grid item xs={12} sx={{p:2}}>
                            <Typography variant="h3" align="center">
                                Join Lab
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{p:2}}>
                            <Typography variant="h7" align="center">
                                Invite Code
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{p:2}}>
                            <TextField
                            id="invite-code"
                            label="Invite Code"
                            value={associationCode}
                            onChange={(event) => {
                                setAssociationCode(event.target.value);
                            }}
                            fullWidth
                            required
                            />
                        </Grid>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Join Lab
                        </Button>
                    </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
)
}

export default JoinLabCard;