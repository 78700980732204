import { Grid, Container, Typography } from "@mui/material";
import SquareBox from "./SquareBox";
import React from "react";
import { useState, useEffect } from "react";

const boxLocationLetterAssignment = ['', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
const rowLabelWidth = 0.7;


function NxNBox (props) {//props include locationData
    const [locationData, setLocationData] = props.locationData;
    const [renderData, setRenderData] = useState([]);
    const [dragState, setDragState] = useState({start_coord:null});

    useEffect(()=> {
        const boxWidth = 11.3 / locationData.limitX;//to account for row label
        let boxRender = [];
        for (var i = 0; i < (locationData.limitY + 1); i++) {//row (i) 0 and column (j) 0 are for labels
            for (var j = 0; j < (locationData.limitX + 1); j++) {
                if (i == 0){//column labels
                    if (j==0){
                        boxRender.push(<Grid item xs={rowLabelWidth}></Grid>);//no label in 0,0
                    } else {
                        boxRender.push(<Grid item xs={boxWidth}><Typography varidrivedrdrdrant="subtitle1" align="center">{boxLocationLetterAssignment[j]}</Typography></Grid>);
                    }
                } else if (j == 0){//row labels
                    if (i==0){
                        boxRender.push(<Grid item xs={rowLabelWidth}></Grid>);//no label in 0,0
                    } else {
                        boxRender.push(<Grid item xs={rowLabelWidth}><Typography variant="subtitle1" align="center">{i.toString()}</Typography></Grid>);
                    }
                } else if (locationData.items[[j, i].toString()]) {//check if items is in slot, can implement different icons for different item types
                    boxRender.push(
                            <SquareBox 
                            boxWidth={boxWidth} 
                            locCoords={`${[j, i].toString()}`}
                            filled={true} 
                            errorState={props.errorState}
                            locationDataState={[locationData,setLocationData]}
                            dragState={dragState}
                            mode={props.mode}
                            setLocation={props.setLocation} 
                            />
                    
                    );
                } else {
                    boxRender.push(
                        <SquareBox 
                        boxWidth={boxWidth} 
                        locCoords={`${[j, i].toString()}`}
                        filled={false} 
                        errorState={props.errorState}
                        locationDataState={[locationData,setLocationData]}
                        dragState={dragState}
                        mode={props.mode}
                        setLocation={props.setLocation} 
                        />
                    );
                }
            }
        }
        setRenderData(boxRender);
    },[locationData]);
    return (
        <div>
           <Container component="main" maxWidth="xl">
                <Grid container 
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ border: "1px solid black", maxWidth:800}}
                >
                    {renderData}
                </Grid>
           </Container>
        </div>
    )
}

export default NxNBox;