import React from "react";
import KitchenIcon from '@mui/icons-material/Kitchen';
import Draggable from 'react-draggable';
import { useCallback, useState } from "react";


function DraggableIcon (props) {
    const [iconWidth, setIconWidth] = useState(0);
    const [iconHeight, setIconHeight] = useState(0);
    const [startX, setStartX] = useState(null);
    const [startY, setStartY] = useState(null);

    const elementRef = useCallback(node => {//update container height and width on resize or render
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            setIconWidth(node.clientWidth);
            setIconHeight(node.clientHeight);
        });
        resizeObserver.observe(node);
    }, []);

    const handleStartDrag = (event) => {//store starting coordinates of movement of icon
        event.stopPropagation();
        event.preventDefault();
        setStartX(event.clientX);
        setStartY(event.clientY);
    }
    
    const handleDrop = (event) => {//handle update to icon current position and bound area of icon
        event.stopPropagation();
        event.preventDefault();

        //handle clicks to navigate to new location (if final coordinates are <2px away from starting)
        if (Math.sqrt( (event.clientX - startX)*(event.clientX - startX) + (event.clientY - startY)*(event.clientY - startY )) < 2) {
            props.setLocation(props.locationID);
        }

        //calculate % distance moved relative to image size
        let updatedX = props.relativeLeft + (100 * (event.clientX - startX) / props.containerWidth);
        let updatedY = props.relativeTop + (100 * (event.clientY - startY) / props.containerHeight);

        if (updatedX < 0) {
            updatedX = 0;
        } else if (updatedX > (100 - 100 * iconWidth / props.containerWidth)) {
            updatedX = 100 - 100 * iconWidth / props.containerWidth;
        }

        if (updatedY < 0) {
            updatedY = 0;
        } else if (updatedY > (100 - 100 * iconHeight / props.containerHeight)) {
            updatedY = 100 - 100 * iconHeight / props.containerHeight;
        }

        if (props.updates.length < 1) {//if updates queue is currently empty, set as new list with update
            props.setUpdates([
                {
                    locationID: props.locationID,
                    updatedX: updatedX,
                    updatedY: updatedY,
                }
            ]);
        } else {//if update queue is not empty, make a copy, then update state with clone + new update
            let updatesClone = props.updates.slice();
            updatesClone.push({
                locationID: props.locationID,
                updatedX: updatedX,
                updatedY: updatedY,
            });
            props.setUpdates(updatesClone);
        }
    }

    return (
            <Draggable
            onStart={handleStartDrag}
            onStop={handleDrop}
            defaultPosition={{x: 0, y: 0}}
            position={{x: 0, y: 0}}
            bounds={{
                top:-1 * (props.relativeTop / 100) * props.containerHeight,
                left:-1 * (props.relativeLeft / 100) * props.containerWidth,
                right:(1 - props.relativeLeft / 100) * props.containerWidth - iconWidth,
                bottom:(1 - props.relativeTop / 100) * props.containerHeight - iconHeight,
            }}
            >
                <KitchenIcon 
                fontSize="large"
                ref={elementRef}
                sx={{
                    position:"absolute",
                    left:`${props.relativeLeft}%`,
                    top:`${props.relativeTop}%`,
                    color:"DodgerBlue",
                    transition: "all 0.2s ease-in-out",
                    cursor: 'pointer'
                    }}
                />
            </Draggable>
    )
}

export default DraggableIcon;