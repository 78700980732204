import { useState, useEffect, useContext } from 'react';
import React from 'react';
import { Box, Typography, Dialog, Alert, DialogTitle, DialogContent, Grid, Button, TextField, Select, MenuItem} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingContext } from '../../../App';
import APIServerPath from '../../Constants/APIServerPath';
import TransferList from '../../TransferList';
import axios from 'axios';

function UserTabCard (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const userData = props.userData;
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = useState(null);
    const [selectedPermissions, setSelectedPermissions] = React.useState([]);
    const [unselectedPermissions, setUnselectedPermissions] = React.useState([]);
    const [permissionDefinitions, setPermissionDefinitions] = React.useState({});
    const [userRole, setUserRole] = React.useState(null);
    const [userActive, setUserActive] = React.useState(null);

    //Update user data
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.currentTarget);
        formData.append("lab_ID", localStorage.labID);
        formData.append("target_uID", userData.uID);
        formData.append("role", userRole);
        formData.append("active", userActive);
        //recreate permission table
        let userPermissionTable = {};
        for (let i = 0; i < unselectedPermissions.length;i++) {
            userPermissionTable[unselectedPermissions[i]] = 0;
        };
        for (let i = 0; i < selectedPermissions.length;i++) {
            userPermissionTable[selectedPermissions[i]] = 1;
        };
        formData.append("permissions", JSON.stringify(userPermissionTable));
        const response = client.post(
            'update_lab_association', 
            formData,
            {headers}
            ).then(function (response) {
                if (response.data.msg) {//show error alert
                    setErrorMsg(response.data.msg);
                    setLoading(false);
                } else{//save token and name to cookies and redirect to dashboard
                    props.setUserData(null);
                    setErrorMsg(null);
                    setLoading(false);
                }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });
    };

    //retrieve user permissions associated with lab
    useEffect(()=> {
        setLoading(true);
        let formData = new FormData();
        formData.append("lab_ID", localStorage.labID);
        formData.append("requested_uID", userData.uID);
        const response = client.post(
            'retrieve_user_permissions', 
            formData,
            {headers}
            ).then(function (response) {
            if (response.data.msg) {//there should be no error messages from this query
                setErrorMsg(response.data.msg);
                setLoading(false);
            } else{//save new locationID and reset all form values
                let requestedPermissions = response.data.permissions
                let userPermissionDefinitions = response.data.definitions
                let truePermissions = [];
                let falsePermissions = [];
                //split permissions object into 2 arrays
                Object.keys(requestedPermissions).forEach(function(key, index) {
                    if (requestedPermissions[key] === 1) {
                        truePermissions.push(key);
                    } else {
                        falsePermissions.push(key);
                    }
                });
                setSelectedPermissions(truePermissions);
                setUnselectedPermissions(falsePermissions);
                setPermissionDefinitions(userPermissionDefinitions);
                setUserRole(userData.role);
                setUserActive(userData.active);
                setErrorMsg(null);
                setLoading(false);
            }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });
    }, [userData]);

    return (
        <React.Fragment>
            <Dialog
            open={props.userData} 
            onClose={()=>{props.setUserData(null);}}
            fullWidth
            maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">Update User Information</DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 500, width:'100%'}} component="form" noValidate onSubmit={handleSubmit} justifyContent="center" alignment="center">
                    <Grid 
                    container 
                    justifyContent="center"
                    alignItems="center"
                    sx={{ p: 2}}>
                        <Grid item xs={12} sx={{p:1}}>
                            {errorMsg ? <Alert severity="error">{errorMsg}</Alert>:null}
                        </Grid>
                        <Grid item xs={6} sx={{p:2}}>
                            <Typography variant="h6" align="center">
                                Name
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{p:2}}>
                            <Typography variant="h7" align="center">
                                {userData.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{p:2}}>
                            <Typography variant="h6" align="center">
                                Email
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{p:2}}>
                            <Typography variant="h7" align="center">
                                {userData.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{p:2}}>
                            <Typography variant="h6" align="center">
                                User Role
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{p:2}}>
                            <TextField
                            id="outlined-controlled"
                            label="User Role"
                            value={userRole}
                            onChange={(event) => {
                                setUserRole(event.target.value);
                            }}
                            fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sx={{p:2}}>
                            <Typography variant="h6" align="center">
                                User Status
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{p:2}}>
                            <Select
                            labelId="select-user-active"
                            id="select-user-active"
                            value={userActive}
                            label="User Status"
                            onChange={(event) => {
                                setUserActive(event.target.value);
                            }}
                            fullWidth
                            >
                                <MenuItem value={0}>Inactive</MenuItem>
                                <MenuItem value={1}>Active</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sx={{p:2}}>
                            <Typography variant="h6" align="center">
                                Permissions
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{p:2}}>
                            <TransferList
                            left={selectedPermissions}
                            setLeft={setSelectedPermissions}
                            right={unselectedPermissions}
                            setRight={setUnselectedPermissions}
                            leftLabel="Selected"
                            rightLabel="Non-Selected"
                            definitions={permissionDefinitions}
                            />
                        </Grid>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Update User Information
                        </Button>
                    </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
)
}

export default UserTabCard;