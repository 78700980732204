import { Grid, Container, Typography } from "@mui/material";
import RackBox from "./RackBox";
import React from "react";
import { useState, useEffect } from "react";



function Rack (props) {//props include locationData
    const [locationData, setLocationData] = props.locationData;
    const [renderData, setRenderData] = useState([]);
    const boxWidth = 12 / locationData.limitX;//to account for row label
    const [dragState, setDragState] = useState({start_coord:null});

    useEffect(()=> {
        var boxRender = [];
        for (var i = 1; i < (locationData.limitY + 1); i++) {//row (i) 0 and column (j) 0 are for labels
            for (var j = 1; j < (locationData.limitX + 1); j++) {
                if (locationData.children[[j, i].toString()]) {//check if items is in slot, can implement different icons for different item types
                    boxRender.push(
                    <RackBox 
                    boxWidth={boxWidth} 
                    filled={true} 
                    errorState={props.errorState}
                    locCoords={`${[j, i].toString()}`}
                    locationDataState={[locationData,setLocationData]}
                    dragState={dragState}
                    mode={props.mode}
                    name={locationData.children[[j, i].toString()].name} 
                    setLocation={props.setLocation} 
                    locationID={locationData.children[[j, i].toString()].locationID}
                    capacity={locationData.children[[j, i].toString()].capacity}
                    used={locationData.children[[j, i].toString()].filled}/>
                    );
                } else {
                    boxRender.push(
                    <RackBox 
                    boxWidth={boxWidth} 
                    filled={false} 
                    errorState={props.errorState}
                    locCoords={`${[j, i].toString()}`}
                    locationDataState={[locationData,setLocationData]}
                    setLocation={props.setLocation} 
                    dragState={dragState}
                    mode={props.mode}
                    />
                    );
                }
            }
        }
        setRenderData(boxRender);
    }, [locationData]);
    return (
        <div>
           <Container component="main" maxWidth="xl">
                <Grid container 
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ border: "1px solid black", maxWidth:1000}}
                >
                    {renderData}
                </Grid>
           </Container>
        </div>
    )
}

export default Rack;