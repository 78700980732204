import React, { useState, useMemo, useContext, useEffect, useCallback } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Tabs,
  Tab,
  IconButton,
  FormControlLabel,
  Checkbox,
  Input
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { LoadingContext, IsLoadingContext } from "../../App";

function StockTable({ columnDefs, rowData, onGridReady }) {
    const [filterText, setFilterText] = useState('');
    const [locations, setLocations] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [locationCounts, setLocationCounts] = useState({});

    // useEffect(() => {
    //     // Extract unique locations from rowData and set locations state
    //     const uniqueLocations = Array.from(new Set(rowData.flatMap(item => item.locationName)));
    //     setLocations(uniqueLocations);
    //     setSelectedLocations(uniqueLocations);  // By default, all locations are selected
    // }, [rowData]);
    
    useEffect(() => {
        const locationMap = {};
        const countsMap = {};

        rowData.forEach(location => {
            location.items.forEach(item => {
                if (!locationMap[item.locationName]) {
                    locationMap[item.locationName] = true;
                    countsMap[item.locationName] = 0;
                }
                countsMap[item.locationName] += 1;
            });
        });

        setLocations(Object.keys(locationMap));
        setSelectedLocations(Object.keys(locationMap));  // By default, all locations are selected
        setLocationCounts(countsMap);
    }, [rowData]);

    const handleLocationChange = (location, isChecked) => {
        setSelectedLocations(prev => isChecked
            ? [...prev, location]
            : prev.filter(l => l !== location)
        );
    };

    const paginationPageSizeSelector = useMemo(() => {
        return [25, 50, 100];
    }, []);

    const paginationNumberFormatter = useCallback((params) => {
        return '[' + params.value.toLocaleString() + ']';
    }, []);

    const getFilteredRowData = useMemo(() => {
        const flattenedData = rowData.flatMap(location => location.items);
        return flattenedData.filter(item =>
            selectedLocations.includes(item.locationName) &&
            item.locationName.toLowerCase().includes(filterText.toLowerCase())
        );
    }, [rowData, selectedLocations, filterText]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', height: '500px', width: '100%', marginTop: 4, gap: 2 }}>
            <Box sx={{
                width: 200,
                overflowY: 'auto',
                borderRight: 1,
                borderColor: 'divider',
                p: 2,
                backgroundColor: 'grey.200',  // Setting the background color to grey
            }}>
                <Box sx = {{marginBottom: 4 }}>
                    <Typography variant="h6">Filters</Typography>
                    <Box sx = {{marginBottom: 2, marginTop: 2 }}>
                        <Input
                            type="text"
                            placeholder="Search..."
                            variant="outlined"
                            fullWidth
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            margin="normal"
                        />
                    </Box>
                </Box>
                <Box>
                    <Typography variant="subtitle1">Location Name</Typography>
                    <Box sx = {{marginTop: 2 }}>
                        <Grid container spacing={2}>
                            {locations.map((location, index) => (
                                <Grid item xs={6} key={index}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedLocations.includes(location)}
                                                onChange={(e) => handleLocationChange(location, e.target.checked)}
                                                name={location}
                                            />
                                        }
                                        
                                        label={
                                            <Typography sx={{ fontSize: '0.775rem' }}>
                                                {location} ({locationCounts[location]})
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                </Box>

            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <AgGridReact
                    className="ag-theme-alpine"
                    style={{ height: '100%', width: '100%' }}
                    columnDefs={columnDefs}
                    rowData={getFilteredRowData}
                    defaultColDef={{ sortable: true, filter: true, resizable: true }}
                    onGridReady={onGridReady}
                    rowSelection="multiple"
                    pagination={true}
                    paginationPageSize={25}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    paginationNumberFormatter={paginationNumberFormatter}
                />
            </Box>
        </Box>
    );
}


export default StockTable;
