import React from 'react';
import {Navigate} from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import APIServerPath from './Constants/APIServerPath';

function PrivateRoute({ children }) {
  const [tokenValid, setTokenValid] = useState(true);
  const client = axios.create({
    baseURL:APIServerPath
  });
  const auth = localStorage.access_token;
  if (auth) {//Checks if token has expired
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}` };
    const auth_response = client.post(
    'token_check', 
    {},
    {headers}
    ).catch(
        function (error) {
          if (error.response){
            if (error.response.data.msg.indexOf("Token ") > -1) {
              localStorage.clear();
              setTokenValid(false);
            }
          }
        }
    );
  }
  
  return (auth && tokenValid )? <>{children}</> : <Navigate to="/sign-in" />;
}

export default PrivateRoute;