import { useState, useEffect } from 'react';
import {Card, CardContent, Box, Grid, TextField, Autocomplete, Button, Typography, Alert, Backdrop, ClickAwayListener} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import axios from "axios";
import AddInstitutionCard from './AddInstitutionCard';
import APIServerPath from '../Constants/APIServerPath';

function CreateLabCard (props) {//props include onClick, open
    //Need to fetch list of country and country codes
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}` };
    const [errorMsg, setErrorMsg] = useState(null);
    const [institutions, setInstitutions] = useState([]);
    const [selectedInstitutions, setSelectedInstitutions] = useState(null);
    const [timezones, setTimezones] = useState([]);
    const [selectedTimezone, setSelectedTimezone] = useState(null);
    const [addInstitutionCardOpen, setAddInstitutionCardOpen] = useState(false);
    const filter = createFilterOptions();

    const client = axios.create({
      baseURL:APIServerPath
    });

    const handleClickAway = () => {
        if (!addInstitutionCardOpen) {
            props.onClick();
        }
    }

    const handleAddInstitutionCardClose = () => {
        setAddInstitutionCardOpen(false);
    }

    const handleAddInstitutionCardOpen = () => {
        setAddInstitutionCardOpen(true);
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const submissionData= {
            lab_name: data.get('labName'),
            institution_ID: selectedInstitutions.id,
            timezone: selectedTimezone.label,
        };


        const response = client.post(
          'create_lab', 
          submissionData,
          {headers}
          ).then(function (response) {
            if (response.data.msg) {//show error alert
                setErrorMsg(response.data.msg);
            } else{//save token and name to cookies and redirect to dashboard
              localStorage.labID = response.data.labID;
              localStorage.labName = response.data.labName;
              props.onClick();
            }
          }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
          });
      };

    useEffect(()=> {
        const institution_response = client.post(
        'retrieve_institutions', 
        {},
        {headers}
        ).then(
            function (institution_response) {
                setInstitutions(institution_response.data.insitutions);
            }
        ).catch(
            function (error) {
                console.log(error, "error");//implement redirecting to error pages
            }
        );

        const timezone_response = client.post(
        'retrieve_countries', 
        {},
        {headers}
        ).then(
            function (timezone_response) {
                setTimezones(timezone_response.data.country_code_timezones);
            }
        ).catch(
            function (error) {
                console.log(error, "error");//implement redirecting to error pages
            }
        );
    }, [addInstitutionCardOpen]);

    return (
        <div>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.open}
            >
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Card sx={{ minWidth: 300,  maxWidth: 450, textAlign: 'center'  }}>
                        <CardContent>
                            <Box component="form" noValidate onSubmit={handleSubmit} justifyContent="center" alignment="center">
                                <Grid 
                                container 
                                justifyContent="center"
                                alignItems="center"
                                sx={{ p: 2}}>
                                    <Grid item xs={12} sx={{p:1}}>
                                        {errorMsg ? <Alert severity="error">{errorMsg}</Alert>:null}
                                        <Typography variant="h3" align="center">
                                            Create Lab
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{p:2}}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="labName"
                                            label="Lab Name"
                                            name="labName"
                                            autoComplete="lab-name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{p:2}}>
                                        <Autocomplete
                                        required
                                        value={selectedInstitutions}
                                        onChange={(event, newInstitution) => {
                                            if (newInstitution.label.indexOf("Add ") > -1) {
                                                handleAddInstitutionCardOpen();
                                            } else {
                                                setSelectedInstitutions(newInstitution);
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);

                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = options.some((option) => inputValue === option.label);
                                            if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                label: `Add "${inputValue}"`,
                                            });
                                            }

                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        id="institution"
                                        options={institutions}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                            return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.label) {
                                            return option.label;
                                            }
                                            // Regular option
                                            return option.title;
                                        }}
                                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField {...params} label="Institution" />
                                        )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{p:2}}>
                                        <Autocomplete
                                        value={selectedTimezone}
                                        required
                                        fullWidth
                                        disablePortal
                                        id="timezone"
                                        options={timezones}
                                        onChange={(event, newTimezone) => {
                                            setSelectedTimezone(newTimezone);
                                        }}
                                        sx={{}}
                                        renderInput={(params) => <TextField {...params} label="Timezone" />}
                                        />
                                    </Grid>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Create Lab
                                    </Button>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </ClickAwayListener>
                {addInstitutionCardOpen ? <AddInstitutionCard onClick={handleAddInstitutionCardClose} open={addInstitutionCardOpen} setInstitution={setSelectedInstitutions} setTimezone={setSelectedTimezone} timezones={timezones}/>:null}
            </Backdrop>
        </div>
    )
}

export default CreateLabCard;
//