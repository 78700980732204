import { Grid, Box } from "@mui/material";
import { useContext } from "react";
import tubeOverviewIcon from '../../../Icons/tube-overview.png';
import DraggableLocation from "../DraggableLocation";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { IsBoxDraggingContext } from "../../../pages/Locations";


function SquareBox (props) {
    const isDragging = useContext(IsBoxDraggingContext);
    return (
        <Grid 
        item 
        xs={props.boxWidth}
        sx={{
            border: "1px solid black",
            position: "relative",
            width: "100%",
            "&::before": {
                display: "block",
                content: "''",
                paddingBottom: "100%"
            }
        }}>
            <Box
            style={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                p:3
            }}
            >
                    {props.filled ?
                        props.mode === "selector"?
                            <img src={tubeOverviewIcon} alt={props.itemName} style={{ width: "100%", height: "100%" }}/>
                        :
                            <DraggableLocation
                            locCoords={props.locCoords}
                            locationDataState={props.locationDataState}
                            dragState={props.dragState}
                            errorState={props.errorState}
                            setLocation={props.setLocation} 
                            mode={props.mode}
                            >
                                <img src={tubeOverviewIcon} alt={props.itemName} style={{ width: "100%", height: "100%" }}/>
                            </DraggableLocation>
                        :
                        <DraggableLocation
                        locCoords={props.locCoords}
                        locationDataState={props.locationDataState}
                        dragState={props.dragState}
                        errorState={props.errorState}
                        setLocation={props.setLocation}  
                        mode={props.mode}
                        >
                            {isDragging || props.mode == "selector"?
                                <AddTwoToneIcon sx={{ color:"green" }} fontSize="large"/>
                            :null}
                        </DraggableLocation>
                    }
            </Box>
        </Grid>
    )
}

export default SquareBox;