import React, { useEffect, useState, useMemo, useCallback } from 'react'; 
import { Dialog, DialogTitle, DialogContent, Tabs, Tab, Box, Grid, TextField, Button, CircularProgress, Typography } from '@mui/material'; 
import APIServerPath from '../Constants/APIServerPath'; 
import axios from 'axios'; 
import CreateStockCard from './CreateStockCard'; 
import { AgGridReact } from 'ag-grid-react'; 
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";
import StockTable from './StockTable';

function ItemSelectionCard(props) {
    const { open, onClose, itemData, setAlertMsg, setAlertSeverity, setAlertOpen, canModify } = props;
    const [currentTab, setCurrentTab] = useState(0);
    const [stockData, setStockData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editableItemData, setEditableItemData] = useState({ ...itemData });
    const [consumableUsageData, setConsumableUsageData] = useState([]);
    const [editableStockData, setEditableStockData] = useState({ ...itemData });
    const [showCreateStockCard, setShowCreateStockCard] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showStockDetails, setShowStockDetails] = useState(false);
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}` };

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        if (open) {
            fetchInventoryStock();
            fetchConsumableUsage();
        }
    }, [open, itemData.itemID]);

    const fetchInventoryStock = async () => {
        setLoading(true);
        try {
            const client = axios.create({ baseURL: APIServerPath });
            const requestData = { labID: `${localStorage.labID}`, itemID: itemData.itemID };
            const response = await client.post('/retrieve_inventory', requestData, { headers });
            const locationsData = response.data.locations;
            const parsedLocations = Object.keys(locationsData).map(key => {
                return {
                    locationID: key,
                    locationName: locationsData[key].locationName,
                    items: locationsData[key].items
                };
            });
            setStockData(parsedLocations);
            console.log('Lab ID is: ');
            console.log(`${localStorage.labID}`);
            console.log('Access token is: ');
            console.log(`${localStorage.access_token}`);

        } catch (error) {
            setAlertMsg("Failed to retrieve Inventory Stock data. ");
            setAlertSeverity("error");
            setAlertOpen(true);
            console.log(`Failed with error: ${error}`);
        } finally {
            setLoading(false);
        }
    };

    const fetchConsumableUsage = async () => {
        setLoading(true);
        try {
            const client = axios.create({ baseURL: APIServerPath });
            const requestData = { labID: localStorage.getItem('labID'), itemID: itemData.itemID };
            const response = await client.post('/retrieve_consumable_usage', requestData, { headers });
            setConsumableUsageData(response.data.items);
        } catch (error) {
            setAlertMsg("Failed to retrieve consumable usage data.");
            setAlertSeverity("error");
            setAlertOpen(true);
        } finally {
            setLoading(false);
        }
      };
    
        
    const saveItemChanges = async () => {
        setLoading(true);
        try {
            const dataToSubmit = {
                itemID: parseInt(editableItemData.itemID, 10),
                type: parseInt(editableItemData.type, 10),
                temperature: parseInt(editableItemData.temperature, 10),
                manufacturer: String(editableItemData.manufacturer),
                catNo: String(editableItemData.catNo),
                itemName: String(editableItemData.itemName),
                quantityUnits: String(editableItemData.quantityUnits),
                minQty: parseInt(editableItemData.minQty, 10),
                maxQty: parseInt(editableItemData.maxQty, 10)
            };

            for (const key in dataToSubmit) {
                if (typeof dataToSubmit[key] === 'number' && isNaN(dataToSubmit[key])) {
                    throw new Error(`Invalid input for ${key}, expected a number.`);
                }
            }

            const client = axios.create({
                baseURL: APIServerPath
            });
            const requestBody = {
                item: dataToSubmit,
                labID: localStorage.getItem('labID')
            };
            await client.post('/update_consumable_item', requestBody, { headers });
            setAlertMsg('Item Successfully Updated!');
            setAlertSeverity('success');
        } catch (error) {
            setAlertMsg(error.message || 'Failed to update item.');
            setAlertSeverity('error');
        } finally {
            setLoading(false);
            setAlertOpen(true);
        }
    };

    const handleAddStockbutton = () => {
        setShowCreateStockCard(true);
    }

    const onRowSelected = (event) => {
        setSelectedRowData(event.data);
        setShowStockDetails(true);
    };

    const stockColumnDefs = useMemo(() => [
        { headerName: "StockID", field: "stockID", width: 120},
        { headerName: "lot", field: "lot", width: 90},
        { headerName: "expDate", field: "expDate", width: 175 },
        { headerName: "quantity", field: "quantity", width: 90 },
        { headerName: "quantityUnits", field: "quantityUnits", width: 120 },
        { headerName: "concentration", field: "concentration", width: 150},
        { headerName: "concentrationUnits", field: "concentrationUnits", width: 180},
        { headerName: "noThaws", field: "noThaws", width: 120 },
        { headerName: "parentStockID", field: "parentStockID", width: 150 },
    ], []);
    
    const consumableUsageColumns = useMemo(() => [
        { headerName: "User", field: "name", width: 140},
        { headerName: "stockID", field: "stockID", width: 90},
        { headerName: "Date", field: "recordDatetime", width: 180},
        { headerName: "Quantity Change", field: "quantity", width: 120},
        { headerName: "qtyUnits", field: "qtyUnits", width: 120},
      ], []);

    const paginationPageSizeSelector = useMemo(() => {
        return [25, 50, 100];
    }, []);
    const paginationNumberFormatter = useCallback((params) => {
        return '[' + params.value.toLocaleString() + ']';
    }, []);


    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>Stock Details</DialogTitle>
            <DialogContent style={{height: '700px', width: '100%'}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={currentTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label="Item Details" />
                        <Tab label="Stock Table" />
                        <Tab label="Usage" />
                    </Tabs>
                </Box>
                {currentTab === 0 && (
                        <Grid container spacing={2} sx={{ p: 3, justifyContent: 'center' }}>
                            {canModify ? (
                                <React.Fragment>
                                    <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <TextField label="Item ID" variant="outlined" fullWidth value={editableItemData.itemID} InputProps={{readOnly: true,}}/>
                                        <TextField label="Item Name" variant="outlined" fullWidth value={editableItemData.itemName} onChange={e => setEditableItemData({...editableItemData, itemName: e.target.value})} />
                                        <TextField label="Type" variant="outlined" fullWidth value={editableItemData.type} onChange={e => setEditableItemData({...editableItemData, type: e.target.value})} />
                                        <TextField label="Manufacturer" variant="outlined" fullWidth value={editableItemData.manufacturer} onChange={e => setEditableItemData({...editableItemData, manufacturer: e.target.value})} />
                                        <TextField label="Catalog Number" variant="outlined" fullWidth value={editableItemData.catNo} onChange={e => setEditableItemData({...editableItemData, catNo: e.target.value})} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <TextField label="Temperature (°C)" variant="outlined" fullWidth value={editableItemData.temperature} onChange={e => setEditableItemData({...editableItemData, temperature: e.target.value})} />
                                        <TextField label="Quantity Units" variant="outlined" fullWidth value={editableItemData.quantityUnits} onChange={e => setEditableItemData({...editableItemData, quantityUnits: e.target.value})} />
                                        <TextField label="Minimum Quantity" variant="outlined" fullWidth value={editableItemData.minQty} onChange={e => setEditableItemData({...editableItemData, minQty: e.target.value})} />
                                        <TextField label="Maximum Quantity" variant="outlined" fullWidth value={editableItemData.maxQty} onChange={e => setEditableItemData({...editableItemData, maxQty: e.target.value})} />
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button color="primary" variant="contained" onClick={saveItemChanges}>
                                            Save Changes
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                            ) : (
                        <React.Fragment>
                            <Grid item xs={6}>
                                <p><b>Item ID:</b> {itemData?.itemID}</p>
                                <p><b>Item Name:</b> {itemData?.itemName}</p>
                                <p><b>Type:</b> {itemData?.type}</p>
                                <p><b>Manufacturer:</b> {itemData?.manufacturer}</p>
                                <p><b>Catalog Number:</b> {itemData?.catNo}</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p><b>Temperature (°C):</b> {itemData?.temperature}</p>
                                <p><b>Quantity Units:</b> {itemData?.quantityUnits}</p>
                                <p><b>Minimum Quantity:</b> {itemData?.minQty}</p>
                                <p><b>Maximum Quantity:</b> {itemData?.maxQty}</p>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            )}
                {currentTab === 1 && (
                    <Grid>
                        {loading ? (
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <StockTable
                                columnDefs={stockColumnDefs}
                                rowData={stockData}
                                onGridReady={props.onGridReady}
                            />
                        )}
                        {canModify && (
                            <Button onClick={() => setShowCreateStockCard(true)}>Add Stock</Button>
                        )}
                        {showCreateStockCard && (
                            <CreateStockCard
                                open={showCreateStockCard}
                                close={() => setShowCreateStockCard(false)}
                                setAlertMsg={setAlertMsg}
                                setAlertSeverity={setAlertSeverity}
                                setAlertOpen={setAlertOpen}
                                itemID={itemData.itemID}
                            />
                        )}
                    </Grid>
                )}
                {currentTab === 2 && (
                    <div className="ag-theme-quartz" style={{ height: '600px', width: '100%' }}>
                        {loading ? (
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <CircularProgress />
                            </Box>
                        ) : consumableUsageData.length === 0 ? (
                            <Typography variant="body2" color="textSecondary" align="center">
                                No usage data available.
                            </Typography>
                        ) : (
                            <AgGridReact
                                columnDefs={consumableUsageColumns}
                                rowData={consumableUsageData}
                                onGridReady={props.onGridReady}
                                pagination={true}
                                paginationPageSize={25}
                                paginationPageSizeSelector={paginationPageSizeSelector}
                                paginationNumberFormatter={paginationNumberFormatter}
                            />
                        )}
                  </div>
                )}
            </DialogContent>
        </Dialog>
    );
}

export default ItemSelectionCard;
