import { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingContext } from '../../../App';
import APIServerPath from '../../Constants/APIServerPath';
import UserTabCard from './UserTabCard';
import InviteUserCard from './InviteUserCard';
import axios from 'axios';

function UserTab (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const userPerms = JSON.parse(localStorage.userPermissions);
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = [props.errorMsg, props.setErrorMsg];
    const [rows, setRows] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [newUserCardOpen, setNewUserCardOpen] = useState(null);
    const columns = [
        {
          field: 'name',
          headerName: 'Name',
          flex: 1,
          editable: false,
        },
        {
          field: 'email',
          headerName: 'Email',
          flex: 1,
          editable: false,
        },
        {
          field: 'role',
          headerName: 'Role',
          flex: 1,
          editable: false,
        },
        {
          field: 'activeText',
          headerName: 'Status',
          flex: 1,
          editable: false,
        },
        {
          field: 'dateAdded',
          headerName: 'Date Added',
          flex: 1,
          editable: false,
        },
    ];
    //retrieve user data associated with lab
    useEffect(()=> {
        setLoading(true);
        let formData = new FormData();
        formData.append("lab_ID", localStorage.labID);
        const response = client.post(
            'retrieve_associated_users', 
            formData,
            {headers}
            ).then(function (response) {
            if (response.data.msg) {//there should be no error messages from this query
                setErrorMsg(response.data.msg);
                setLoading(false);
            } else{//save new locationID and reset all form values
                setLoading(false);
                setRows(response.data);
            }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });
    }, [selectedUser]);

    //handling new user card
    const handlInviteUserCardOpen = (event) => {
        event.preventDefault();
        if (userPerms.users) {
            setNewUserCardOpen(true);
        }
    };
    const handlInviteUserCardClose = (event) => {
        event.preventDefault();
        setNewUserCardOpen(false);
    };

    return (
        <div>
            {selectedUser && <UserTabCard userData={selectedUser} setUserData={setSelectedUser} />}
            {newUserCardOpen && <InviteUserCard open={newUserCardOpen} handleClose={handlInviteUserCardClose} />}
            {userPerms.users ?
            <Grid
            container
            justifyContent="right"
            alignItems="right"
            spacing={12}>
                <Grid item xs={9} />
                <Grid item xs={3}>
                    <Button variant="outlined" onClick={handlInviteUserCardOpen}>Invite New Users</Button>
                </Grid>
            </Grid>
            : null
            }
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    getRowId={(row) => row.id}
                    onCellDoubleClick={(params, event, details)=> {
                        //check permissions to modify user information
                        //set selectedUser
                        if (userPerms.users) {
                            setSelectedUser(params.row);
                        }
                    }}
                />
            </Box>
        </div>
    )
}

export default UserTab;