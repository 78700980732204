import NavigationMenu from '../components/layout/NavigationMenu';
import LocationExplorer from '../components/Locations/LocationExplorer';
import NxNBox from "../components/Locations/NxNBox/NxNBox"
import Rack from '../components/Locations/Racks/Rack';
import GeneralLocations from '../components/Locations/GeneralLocations/GeneralLocations';
import { useState, useEffect, useContext, createContext } from 'react';
import { Box, Grid, Typography, Divider, Container, CssBaseline, Button, Alert } from '@mui/material';
import VerticalFreezer from '../components/Locations/VerticalFreezer/VerticalFreezer';
import CreateLocationCard from '../components/Locations/CreateLocationCard';
import CreateItemCard from '../components/Inventory/CreateItemCard';
import { LoadingContext} from "../App";
import APIServerPath from '../components/Constants/APIServerPath';
import Copyright from '../components/layout/Copyright';
import axios from 'axios';



export const BoxDragContext = createContext();
export const IsBoxDraggingContext = createContext();

function Locations () {
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isDragging, setIsDragging] = useState(null);
    const locationExplorerWidth = 250;//in # of pixels
    const emptyLocationData = {//default empty location data
        name:'',
        items:{},//format of coordinates in x, y or col, row
        children:{},
        samples:{},
        locationType:null,
        locationID:null,
        limitX:null,
        limitY:null,
        locationX:null,
        locationY:null
    };
    const [currentLocation, setCurrentLocation] = useState(0);
    const [currentLocationData, setCurrentLocationData] = useState(emptyLocationData);
    const [createLocationCardOpen, setCreateLocationCardOpen] = useState(false);
    const [createItemCardOpen, setItemCardOpen] = useState(false);
    const [locationTree, setLocationTree] = useState([{name:localStorage.labName, locationID:"0", children:[]}]);
    const [locationImageToken, setLocationImageToken] = useState(null);

    const client = axios.create({
        baseURL:APIServerPath
    });
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}` };

    const handleCreateLocationCardOpen = () => {
        setCreateLocationCardOpen(true);
    }

    const handleCreateLocationCardClose = () => {
        setCreateLocationCardOpen(false);
    }

    const handleCreateItemCardOpen = () => {
        setItemCardOpen(true);
    }
    
    const handleCreateItemCardClose = () => {
        setItemCardOpen(false);
    }

    useEffect(()=> {//handle update location
        //get new location data to trigger re-render, update to use API
        setLoading(true);
        // if (currentLocation === 0) {
        //     setCurrentLocationData(
        //         {
        //             name:'Box 1',
        //             items:{
        //                 '1,7':{lot:'123',  expDate:'10-12-2023', locationReadable:'1A', quantity:'6', 
        //                 quantityUnits:'mL', concentration:'10',  concentrationUnits:'mg/mL', noThaws:'0', temperature:-10,
        //                 itemName:'anti-CD3', manufacturer:'BD', catNo:'435320'},
        //                 '1,2':{lot:'213',  expDate:'10-12-2023', locationReadable:'2A', quantity:'6', 
        //                 quantityUnits:'mL', concentration:'10',  concentrationUnits:'mg/mL', noThaws:'0', temperature:-10,
        //                 itemName:'anti-CD8', manufacturer:'BD', catNo:'435320'},
        //                 '4,3':{lot:'456',  expDate:'10-12-2023', locationReadable:'3D', quantity:'6', 
        //                 quantityUnits:'mL', concentration:'10',  concentrationUnits:'mg/mL', noThaws:'0', temperature:-10,
        //                 itemName:'anti-CD4', manufacturer:'BD', catNo:'435320'},
        //             },//format of coordinates in x, y or col, row
        //             children:{},
        //             samples:{},
        //             locationType:5,
        //             locationID:0,
        //             limitX:10,
        //             limitY:10,
        //             locationX:1,
        //             locationY:1
        //         }
        //     );
        //     setLoading(false);
        // } else {
        const response = client.post(
            'retrieve_location_data', 
            {
                lab_ID:localStorage.labID,
                location_ID:currentLocation
            },
            {headers}
            ).then(function (response) {
            if (response.data.msg) {//show error alert
                setErrorMsg(response.data.msg);
                setLoading(false);
            } else{//save new locationID and reset all form values
                setErrorMsg(null);
                setCurrentLocationData(response.data.locationData);
                let tempLocationTree = response.data.locationTree;
                tempLocationTree.name = localStorage.labName;
                setLocationTree([tempLocationTree]);
                if (response.data.locationImageToken.length > 0) {//set location image token
                    setLocationImageToken(response.data.locationImageToken);
                } else {
                    setLocationImageToken(null);
                }
                setLoading(false);
            }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });

       //}
    }, [currentLocation]);

    //<FileUpload/>
    return (
        <div>
            {errorMsg ? 
            <Box sx={{m:1}}>
                <Alert severity="error" >{errorMsg} </Alert>
            </Box> 
            :null}
            <CreateLocationCard 
            open={createLocationCardOpen} 
            close={handleCreateLocationCardClose} 
            parentLocationName={currentLocationData.name}
            parentLocationID={currentLocationData.locationID}
            setCurrentLocation={setCurrentLocation}
            />
            <CreateItemCard
            open = {createItemCardOpen}
            close = {handleCreateItemCardClose}
            />
            <NavigationMenu/>
            <Container component="main" maxWidth={false}>
                <CssBaseline /> 
                <LocationExplorer width={locationExplorerWidth} currentLocation={currentLocation.toString()} setLocation={setCurrentLocation} locationTree={locationTree}/>
                <Box sx={{ml:`${locationExplorerWidth}px`, mt:2, flexGrow: 1}}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="h5" align="left">
                                Current Location: {currentLocationData.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="outlined" onClick={handleCreateLocationCardOpen}>Add Sub-Location</Button>
                        </Grid>
                    </Grid>
                    <Divider sx={{m:1}}/>
                    <Grid container 
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    >
                        <BoxDragContext.Provider value={setIsDragging}>
                            <IsBoxDraggingContext.Provider value={isDragging}>
                                {currentLocationData.locationType === 5 && 
                                <Box sx={{ mt:1, mb:3, width:"100%", maxWidth:600, minWidth:350}}>
                                    <NxNBox 
                                    locationData={[currentLocationData, setCurrentLocationData]}
                                    setLocation={setCurrentLocation}
                                    errorState={[errorMsg, setErrorMsg]}
                                    preview={false}
                                    />
                                </Box>
                                }
                                {currentLocationData.locationType === 2 && 
                                <Box sx={{ mt:1, mb:3, width:"100%", maxWidth:900, minWidth:350}}>
                                    <VerticalFreezer 
                                    locationData={[currentLocationData, setCurrentLocationData]}
                                    setLocation={setCurrentLocation}
                                    errorState={[errorMsg, setErrorMsg]}
                                    preview={false}
                                    />
                                </Box>
                                }
                                {currentLocationData.locationType === 4 && 
                                <Box sx={{ mt:1, mb:3, width:"100%", maxWidth:1000, minWidth:600}}>
                                    <Rack 
                                    locationData={[currentLocationData, setCurrentLocationData]} 
                                    setLocation={setCurrentLocation}
                                    errorState={[errorMsg, setErrorMsg]}
                                    preview={false}
                                    />
                                </Box>
                                }
                                {(currentLocationData.locationType === 1) && locationImageToken && 
                                <Box sx={{ mt:1, mb:3, width:"100%", maxWidth:1000, minWidth:600}}>
                                    <GeneralLocations 
                                    locationData={[currentLocationData, setCurrentLocationData]} 
                                    setLocation={setCurrentLocation} 
                                    locationImageToken={locationImageToken}
                                    errorState={[errorMsg, setErrorMsg]}
                                    preview={false}
                                    />
                                </Box>
                                }
                            </IsBoxDraggingContext.Provider>
                        </BoxDragContext.Provider>
                    </Grid>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="h5" align="left">
                                Items (count: {Object.keys(currentLocationData.items).length.toString()})
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="outlined" onClick={handleCreateItemCardOpen}>Add Item</Button>
                        </Grid>
                    </Grid>
                    <Divider sx={{m:1}}/>
                </Box>
            </Container>
            <Copyright/>
        </div>
    )
}

export default Locations;