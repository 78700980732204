import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, Stepper, Step, StepLabel, Button, Typography, FormControl, Select,
        MenuItem, InputLabel, TextField, List, ListItem, ListItemText, Divider, Paper, Alert} from "@mui/material";
import axios from "axios";
import APIServerPath from "../Constants/APIServerPath"; 

const steps = ["Stock Information", "Select Location", "Confirm Details"];

function CreateStockCard(props) {
    const [errorMsg, setErrorMsg] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [stockData, setStockData] = useState({
        lot: "",
        expDate: "",
        quantity: "",
        concentration: "",
        concentrationUnits: "",
        noThaws: "",
        parentStockID: 0, 
    });
    const [locationID, setLocationID] = useState(0);
    const [locationX, setLocationX] = useState(0);
    const [locationY, setLocationY] = useState(0);

    useEffect(() => {
        const fetchLocations = async () => { // TEMPORARY SELECTION OF LOCATION, TO BE UPDATED
            const labID = localStorage.getItem("labID");
            const token = localStorage.getItem("access_token");
            try {
                const response = await axios.post(
                    `${APIServerPath}/location_names`,
                    { labID },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                setLocations(response.data.locations);
            } catch (error) {
                console.log(error,"error") // Redirect to error page
            }
        };

        fetchLocations();
    }, []);

    const handleInputChange = (e) => {
        setStockData({ ...stockData, [e.target.name]: e.target.value });
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setStockData({
            lot: "",
            expDate: "",
            quantity: "",
            concentration: "",
            concentrationUnits: "",
            noThaws: "",
            parentStockID: 0,
        });
        setSelectedLocation("");
        setLocationID(0);
        setLocationX(0);
        setLocationY(0);
    };

    const handleLocationChange = (event) => {
        const location = locations.find((loc) => loc.locationID === event.target.value);
        setSelectedLocation(event.target.value);
        setLocationID(location.locationID);
        setLocationX(location.locationX);
        setLocationY(location.locationY);
    };

    // Sends request to add new stock entry
    const handleSubmit = async () => {
        const labID = localStorage.getItem("labID");
        const token = localStorage.getItem("access_token");
        const inventoryData = { ...stockData, locationID, locationX, locationY };
        try {
            await axios.post(
                `${APIServerPath}/add_stock_item`,
                { inventory: [inventoryData], labID: labID, itemID: props.itemID },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log("Stock added successfully");
            handleReset();
            props.close();
            props.onStockAdded();
        } catch (error) {
            console.error("Error adding new stock item:", error);
        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0: // 
                return (
                    <>
                        <TextField label="Lot" name="lot" value={stockData.lot} onChange={handleInputChange} fullWidth margin="normal" />
                        <TextField label="Expiration Date" type="date" name="expDate" value={stockData.expDate} onChange={handleInputChange} fullWidth margin="normal" InputLabelProps={{ shrink: true }} />
                        <TextField label="Quantity" name="quantity" value={stockData.quantity} onChange={handleInputChange} fullWidth margin="normal" />
                        <TextField label="Concentration" name="concentration" value={stockData.concentration} onChange={handleInputChange} fullWidth margin="normal" />
                        <TextField label="Concentration Units" name="concentrationUnits" value={stockData.concentrationUnits} onChange={handleInputChange} fullWidth margin="normal" />
                        <TextField label="Number of Times Thawed" name="noThaws" value={stockData.noThaws} onChange={handleInputChange} fullWidth margin="normal" />
                    </>
                );
            case 1:
                return (
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="location-select-label">Location</InputLabel>
                        <Select labelId="location-select-label" id="location-select" value={selectedLocation} label="Location" onChange={handleLocationChange}>
                            {locations.map((location) => (
                                <MenuItem key={location.locationID} value={location.locationID}>
                                    {location.locationName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case 2:
                const selectedLocationDetails = locations.find(location => location.locationID === selectedLocation) || {};
                return (
                    <>
                        <Typography variant="h6" gutterBottom>
                            Confirm Stock Details
                        </Typography>
                        <Paper style={{maxWidth: 600, margin: 'auto', overflow: 'hidden', padding: 16}}>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemText primary="Lot:" secondary={stockData.lot} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Expiration Date:" secondary={stockData.expDate} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Quantity:" secondary={stockData.quantity} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Concentration:" secondary={stockData.concentration} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Concentration Units:" secondary={stockData.concentrationUnits} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Number of Times Thawed:" secondary={stockData.noThaws} />
                                </ListItem>
                            </List>
                            <Divider />
                            <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                                Selected Location:
                            </Typography>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemText primary="Name:" secondary={selectedLocationDetails.locationName || 'No location selected'} />
                                </ListItem>
                                {selectedLocationDetails.locationID && (
                                    <>
                                        <ListItem>
                                            <ListItemText primary="Location ID:" secondary={selectedLocationDetails.locationID} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="Location X:" secondary={selectedLocationDetails.locationX} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary="Location Y:" secondary={selectedLocationDetails.locationY} />
                                        </ListItem>
                                        
                                        
                                    </>
                                )}
                            </List>
                        </Paper>
                    </>
                );
            default:
                return "Internal Error";
        }
    };

    return (
        <Dialog open={props.open} onClose={props.close} maxWidth="md" fullWidth>
            <DialogTitle>Enter Stock Details</DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>{getStepContent(activeStep)}</div>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
                    {activeStep > 0 && (
                        <Button onClick={handleBack} style={{ marginRight: 10 }}>
                            Back
                        </Button>
                    )}
                    {activeStep < steps.length - 1 ? (
                        <Button variant="contained" onClick={handleNext}>
                            Next
                        </Button>
                    ) : (
                        <Button variant="contained" onClick={handleSubmit}>
                            Finish
                        </Button>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default CreateStockCard;
