import React, {useState, useEffect } from "react";
import axios from "axios";
import APIServerPath from "../Constants/APIServerPath";

const TemplateDropDown = (props) => {
    const headers = { 
        'Authorization': `Bearer ${localStorage.access_token}`, 
        'Content-Type': 'multipart/form-data' 
    };

    const client = axios.create({
      baseURL: APIServerPath
    });
    
    const [templates, setTemplates] = props.templateData;
    const [selectedTemplate, setSelectedTemplate] = [props.selectedTemplate, props.setSelectedTemplate];//index in the local array
    const [selectedTemplateID, setSelectedTemplateID] = [props.selectedTemplateID, props.setSelectedTemplateID];
    const [rawTemplateData, setRawTemplateData] = props.rawTemplateData;
    const [userTemplates, setUserTemplates] = useState([]);
    const labID = localStorage.getItem('labID');
    const accessToken = localStorage.getItem('access_token');

    // useEffect(() => {
    //     fetchTemplates();
    // }, []);

    // const fetchTemplates = async () => {
    //     try {
    //         const response = await client.post(
    //             `${APIServerPath}retrieve_label_templates`,
    //             {},
    //             { headers }
    //         );

    //         if (response.data && response.data.templates) {
    //             setTemplates(response.data.templates);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching templates:", error);
    //     }
    // };

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const templateData = await fetchTemplates();
    //             setUserTemplates(templateData);
    //         } catch (error) {
    //             console.error("Error in fetchData", error);
    //         }
    //     };

    //     if (labID && accessToken) {
    //         fetchData();
    //     }
    // }, [labID, accessToken]);

    const handleChange = (event) => {
        setSelectedTemplate(event.target.value);
        setSelectedTemplateID(rawTemplateData[event.target.value].templateID);
    };

    return (
        <div>
            <label htmlFor="template-select">
                Select Template
            </label>
            <select 
                id="template-select" 
                value={selectedTemplate} 
                onChange={handleChange}
            >
                <option value="">
                    --Select a Template--
                </option>
                {templates.map((template) => (
                    <option key={template.id} value={template.id}>
                        {template.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default TemplateDropDown;