import NavigationMenu from '../components/layout/NavigationMenu';
import ItemSelectionCard from '../components/Inventory/ItemSelectionCard';
import CreateItemCard from '../components/Inventory/CreateItemCard';
import DiscardItemCard from '../components/Inventory/DiscardItemCard';
//import GenerateQRCode from '../components/Inventory/GenerateQRCode';
import GenerateQRLabels from '../components/Inventory/GenerateQRLabels'
import FetchTemplatesTest from '../components/Inventory/FetchTemplateTest'
import ItemFilter from '../components/Inventory/ItemFilter'; 
import GenerateQRCodes from '../components/Inventory/GenerateQRCodes';
import { useState, useEffect, useContext, useMemo, useCallback} from 'react';
import {Box, Alert, Snackbar, Button } from '@mui/material';

import axios from 'axios';
import { LoadingContext, IsLoadingContext } from "../App";
import APIServerPath from '../components/Constants/APIServerPath';
import { AgGridReact } from 'ag-grid-react'; 
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";
import QRCode from 'qrcode.react';

function Inventory() {
    const [alertMsg, setAlertMsg] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [canModify, setCanModify] = useState(false); 
    const [itemData, setItemData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [filteredItemData, setFilteredItemData] = useState([]);
    const loading = useContext(IsLoadingContext);
    const setLoading = useContext(LoadingContext);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showItemSelectionCard, setItemSelectionCard] = useState(false);
    const [showCreateItemCard, setShowCreateItemCard] = useState(false);
    const [showDiscardItemCard, setShowDiscardItemCard] = useState(false);

    const headers = { 'Authorization': `Bearer ${localStorage.access_token}` };
    const [showQRCodeForm, setShowQRCodeForm] = useState(false);

    const client = axios.create({
        baseURL:APIServerPath
    });

    useEffect(() => {
        setLoading(true);
        if (JSON.parse(localStorage.userPermissions)['consumableModification']){
            setCanModify(true);
        }
        fetchInventoryItems();
    }, []);

    const fetchInventoryItems = async () => {
        try {
            const response = await client.post('retrieve_consumables', { labID: `${localStorage.labID}` }, { headers });
            setItemData(response.data.items);
            setFilteredItemData(response.data.items);
        } catch (error) {
            setAlertMsg("Failed to fetch inventory items.");
            setAlertSeverity("error");
            setAlertOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleRowSelection = (event) => {
        const selectedItem = event.data;
        const isSelected = event.node.isSelected();

        if (isSelected) {
            setSelectedItems([...selectedItems, selectedItem]);
        } else {
            setSelectedItems(selectedItems.filter(item => item.itemID !== selectedItem.itemID));
        }
    };


    const handleGenerateQRCodeClick = () => {
        console.log("Generate QR Code button clicked")
        setShowQRCodeForm(true);
    };

    const handleDiscardItems = async () => {
        if (selectedItems.length === 0) {
            setAlertMsg("Please select at least one item to discard.");
            setAlertSeverity("error");
            setAlertOpen(true);
            return;
        }

        setShowDiscardItemCard(true);
        // try {
        //     await client.post('discard_items',
        //         { itemIDs: selectedItems.map(item => item.itemID), labID: `${localStorage.labID}`},
        //         { headers }
        //     );
        //     setAlertMsg("Items have been successfully discarded!");
        //     setAlertSeverity("success");
        //     setAlertOpen(true);
        //     setSelectedItems([]); 
        //     updateItemGrid();
        // } catch (error) {
        //     setAlertMsg("Error occured when attempting to discard items.");
        //     setAlertSeverity("error");
        //     setAlertOpen(true);
        // }
    };

    const onSelectionChanged = ({ api }) => {
        const selectedNodes = api.getSelectedNodes();
        const selectedItems = selectedNodes.map(node => node.data);
        setSelectedItems(selectedItems);
    };

    const updateItemGrid = () => {
        fetchInventoryItems();
    };

    const onRowDoubleClicked = async (event) => {
        setSelectedItem(event.data);
        setItemSelectionCard(true);
    };

    const resetAlert = () => {
        setAlertMsg('');
        setAlertSeverity('');
        setAlertOpen(false);
    };

    const columnDefs = [
        {headerCheckboxSelection: true, checkboxSelection: true, resizable: false, width: 50},
        { headerName: "ItemID", field: "itemID"}, 
        { headerName: "Item Name", field: "itemName"},
        { headerName: "Type", field: "type"},
        { headerName: "Storage Temp (°C)", field: "temperature"},
        { headerName: "Manufacturer", field: "manufacturer"},
        { headerName: "CatNo", field: "catNo"},
        { headerName: "Quantity Units", field: "quantityUnits"},
        { headerName: "Min Qty", field: "minQty"},
        { headerName: "Max Qty", field: "maxQty"},
    ];

    const defaultColDef = useMemo(() => { 
        return {
            filter: 'agTextColumnFilter',
            minWidth: 100
        };
    }, []);

    const paginationPageSizeSelector = useMemo(() => {
        return [25, 50, 100];
    }, []);
    const paginationNumberFormatter = useCallback((params) => {
        return '[' + params.value.toLocaleString() + ']';
    }, []);

    const onGridReady = params => {
        params.api.sizeColumnsToFit();
    };

    const buttonStyle = {
        margin: '5px',
        padding: '10px 15px', 
        border: '1px solid #007bff',
        borderRadius: '5px', 
        backgroundColor: '#007bff', 
        color: 'white', 
        cursor: 'pointer', 
        outline: 'none', 
        fontSize: '14px',
    };

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 0',
        margin: '0px 30px'
    };

    const containerStyle = {
        display: 'flex',
        height: '100vh'
        
    };

    return (
        <Box>
            <NavigationMenu />
            <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', overflow: 'hidden' }}>
                <Box sx={{ width: '20%', padding: '20px', backgroundColor: '#f5f5f5', overflowY: 'auto' }}>
                    <ItemFilter itemData={itemData} onFilterChange={setFilteredItemData} />
                </Box>
                <Box sx={{ width: '80%', padding: '20px', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={buttonContainerStyle}>
                        <Button sx={buttonStyle} onClick={() => setShowCreateItemCard(true)}>Add Item</Button>
                        <Button sx={buttonStyle} onClick={handleGenerateQRCodeClick}>Generate QR Codes</Button>
                        <Button sx={buttonStyle} onClick={handleDiscardItems}>Discard Item</Button>
                    </Box>
                    <Box sx={{ flex: 1, overflow: 'hidden' }}>
                        <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {loading ? <div>Loading...</div> :
                            <Box className="ag-theme-quartz" sx={{ height: '100%', width: '100%', maxWidth: '1400px' }}>
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    rowData={filteredItemData}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    paginationPageSize={25}
                                    paginationPageSizeSelector={paginationPageSizeSelector}
                                    paginationNumberFormatter={paginationNumberFormatter}
                                    onRowDoubleClicked={onRowDoubleClicked}
                                    onGridReady={onGridReady}
                                    rowSelection="multiple" 
                                    onSelectionChanged={onSelectionChanged}
                                />
                            </Box>}
                        </Box>
                    </Box>
                </Box>
            </Box>
            {showItemSelectionCard && (
                <ItemSelectionCard
                    open={showItemSelectionCard}
                    itemData={selectedItem}
                    onGridReady={onGridReady}
                    setAlertMsg={setAlertMsg}
                    setAlertSeverity={setAlertSeverity}
                    setAlertOpen={setAlertOpen}
                    canModify={canModify}
                    onClose={() => {
                        setItemSelectionCard(false);
                        setSelectedItem(null);
                    }}
                />
            )}
            {showCreateItemCard && (
                <CreateItemCard
                    open={showCreateItemCard}
                    onClose={() => setShowCreateItemCard(false)}
                    onItemAdded={updateItemGrid}
                    setAlertMsg={setAlertMsg}
                    setAlertSeverity={setAlertSeverity}
                    setAlertOpen={setAlertOpen}
                />
            )}
            {showDiscardItemCard && (
                <DiscardItemCard
                    open={showDiscardItemCard}
                    onClose={() => setShowDiscardItemCard(false)}
                    setAlertMsg={setAlertMsg}
                    setAlertSeverity={setAlertSeverity}
                    setAlertOpen={setAlertOpen}
                    selectedItems={selectedItems}
                />
            )}
            {showQRCodeForm && <GenerateQRCodes onClose={() => setShowQRCodeForm(false)} itemIDs = {selectedItems}/>}
            <Snackbar open={alertOpen} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={resetAlert}> 
                <Alert onClose={resetAlert} severity={alertSeverity} sx={{ width: '100%' }}>{alertMsg}</Alert>
            </Snackbar>
        </Box>
    );
}

export default Inventory;
