import {Container, CssBaseline, Box} from '@mui/material';
import NavigationMenu from '../components/layout/NavigationMenu';
import Copyright from '../components/layout/Copyright';

function Dashboard () {
    return (
        <div>
            <NavigationMenu/>
            <Container component="main" maxWidth="xs">
                <CssBaseline /> 
            </Container>
            <Copyright/>
        </div>
    )
}

export default Dashboard;