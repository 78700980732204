import { Box, Grid, Button, Card, CardContent, Typography, CardActions } from '@mui/material';

function LabCardButton (props) {
    return (
        <Grid item xs={6} md={4}>
            <Card sx={{ minHeight: 200, textAlign: 'center' }}>
                <CardContent>
                    <Box sx={{ pb: 5 , pt:3}}>
                        <Typography variant="h3">
                        {props.labName}
                        </Typography>
                    </Box>
                    <Typography variant="subtitle1" align="right">
                        {props.institutionName}
                    </Typography>
                    <Typography variant="subtitle2" align="right">
                        {props.country}
                    </Typography>
                </CardContent>
                <CardActions sx={{alignItems: "flex-end"}}>
                    <Button size="large" color="primary" onClick={props.onClick}>Select Lab</Button>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default LabCardButton