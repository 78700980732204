import LocationExplorer from "./LocationExplorer";
import NxNBox from "./NxNBox/NxNBox";
import Rack from "./Racks/Rack";
import GeneralLocations from "./GeneralLocations/GeneralLocations";
import { useState, useEffect, useContext, createContext } from 'react';
import { Box, Grid, Typography, Divider, Container, CssBaseline, Button, Alert, List, ListItem, ListItemText, ListItemButton, IconButton } from '@mui/material';
import VerticalFreezer from "./VerticalFreezer/VerticalFreezer";
import { LoadingContext } from "../../App";
import APIServerPath from "../Constants/APIServerPath";
import axios from 'axios';
import DraggableLocation from "./DraggableLocation";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';

//input props are:
//select = [item or sample objects]
//and setSelected = react set state function for the select prop

export const LocationSelectionContext = createContext();
export const SetLocationSelectionContext = createContext();
export const LocationSelectionDragContext = createContext();

function LocationSelector (props) {
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = useState(null);
    const [selectionArray, setSelectionArray] = useState(props.select);
    const [assignedListRender, setAssignedListRender] = useState([]);
    const [unassignedListRender, setUnassignedListRender] = useState([]);
    const [dragState, setDragState] = useState({start_coord:null});
    const locationExplorerWidth = 250;//in # of pixels
    const emptyLocationData = {//default empty location data
        name:'',
        items:{},//format of coordinates in x, y or col, row
        children:{},
        samples:{},
        locationType:null,
        locationID:null,
        limitX:null,
        limitY:null,
        locationX:null,
        locationY:null
    };
    const [currentLocation, setCurrentLocation] = useState(0);
    const [currentLocationData, setCurrentLocationData] = useState(emptyLocationData);
    const [locationTree, setLocationTree] = useState([{name:localStorage.labName, locationID:"0", children:[]}]);
    const [locationImageToken, setLocationImageToken] = useState(null);

    const client = axios.create({
        baseURL:APIServerPath
    });
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}` };

    const unassignItem = (e, index) => {
        e.preventDefault();
        let tempSelectionArray = selectionArray.slice();
        tempSelectionArray[index].locationID = null;
        let dbData = structuredClone(currentLocationData);
        let assigned_coord = [tempSelectionArray[index].locationX, tempSelectionArray[index].locationY].toString();
        if (tempSelectionArray[index].entryType === 1 && assigned_coord in dbData.items) {
            delete dbData.items[assigned_coord];
        } else if (tempSelectionArray[index].entryType === 2 && assigned_coord in dbData.samples) {
            delete dbData.samples[assigned_coord];
        }
        setSelectionArray(tempSelectionArray);
        setCurrentLocationData(dbData);
    };

    useEffect(()=> {//handle update location
        //get new location data to trigger re-render, update to use API
        setLoading(true);
        const response = client.post(
            'retrieve_location_data', 
            {
                lab_ID:localStorage.labID,
                location_ID:currentLocation
            },
            {headers}
            ).then(function (response) {
            if (response.data.msg) {//show error alert
                setErrorMsg(response.data.msg);
                setLoading(false);
            } else{//save new locationID and reset all form values
                setErrorMsg(null);
                let dbData = structuredClone(response.data.locationData);//add assigned items
                let tempSelectionArray = selectionArray.slice();
                for (let i = 0; i < tempSelectionArray.length; i++){
                    if (tempSelectionArray[i].locationID && tempSelectionArray[i].entryType === 1 && tempSelectionArray[i].locationX) {
                        dbData.items[[tempSelectionArray[i].locationX, tempSelectionArray[i].locationY].toString()] = tempSelectionArray[i];
                    } else if (tempSelectionArray[i].locationID && tempSelectionArray[i].entryType === 2 && tempSelectionArray[i].locationX) {
                        dbData.samples[[tempSelectionArray[i].locationX, tempSelectionArray[i].locationY].toString()] = tempSelectionArray[i];
                    }
                }
                setCurrentLocationData(dbData);
                let tempLocationTree = response.data.locationTree;
                tempLocationTree.name = localStorage.labName;
                setLocationTree([tempLocationTree]);
                if (response.data.locationImageToken.length > 0) {//set location image token
                    setLocationImageToken(response.data.locationImageToken);
                } else {
                    setLocationImageToken(null);
                }
                setLoading(false);
            }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });

       //}
    }, [currentLocation]);
    
    useEffect(()=> {//update list renderings
        let tempSelectionArray = selectionArray.slice();
        let tempAssignedRender = [];
        let tempUnassignedRender = [];
        for (let i = 0; i < tempSelectionArray.length; i++){
            dragState.start_coord = '' + i;
            if (tempSelectionArray[i].locationID && tempSelectionArray[i].entryType === 1) {
                tempAssignedRender.push(
                    <DraggableLocation
                    locCoords={dragState.start_coord}
                    locationDataState={[currentLocationData, setCurrentLocationData]}
                    dragState={dragState}
                    errorState={[errorMsg, setErrorMsg]}
                    setLocation={setCurrentLocation}
                    mode="selector"
                    >
                        <ListItem
                            secondaryAction={
                                <div class="font-icon-wrapper" onClick={(event)=>{unassignItem(event, i)}}>
                                    <IconButton edge="end" aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            }>
                                <ListItemButton
                                sx={{
                                    "&:hover": {
                                    borderRadius: "16px"
                                    }
                                }}>
                                    <ListItemText
                                        primary={tempSelectionArray[i].name}
                                        secondary={`Lot ${tempSelectionArray[i].lot}`}
                                    />
                                </ListItemButton>
                        </ListItem>
                    </DraggableLocation>
                );
            } else if (tempSelectionArray[i].locationID && tempSelectionArray[i].entryType === 2) {
                tempAssignedRender.push(
                    <DraggableLocation
                    locCoords={dragState.start_coord}
                    locationDataState={[currentLocationData, setCurrentLocationData]}
                    dragState={dragState}
                    errorState={[errorMsg, setErrorMsg]}
                    setLocation={setCurrentLocation}
                    mode="selector"
                    >
                        <ListItem
                            secondaryAction={
                                <div class="font-icon-wrapper" onClick={(event)=>{unassignItem(event, i)}}>
                                    <IconButton edge="end" aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            }>
                                <ListItemButton
                                sx={{
                                    "&:hover": {
                                    borderRadius: "16px"
                                    }
                                }}>
                                    <ListItemText
                                        primary={tempSelectionArray[i].name}
                                        secondary={`Aliquot of ${tempSelectionArray[i].parentSampleIdentifier}`}
                                    />
                                </ListItemButton>
                        </ListItem>
                    </DraggableLocation>
                );
            } else if (tempSelectionArray[i].entryType === 1) {
                tempUnassignedRender.push(
                    <DraggableLocation
                    locCoords={dragState.start_coord}
                    locationDataState={[currentLocationData, setCurrentLocationData]}
                    dragState={dragState}
                    errorState={[errorMsg, setErrorMsg]}
                    setLocation={setCurrentLocation}
                    mode="selector"
                    >
                        <ListItem>
                            <ListItemButton
                            sx={{
                                "&:hover": {
                                borderRadius: "16px"
                                }
                            }}>
                                <ListItemText
                                primary={tempSelectionArray[i].name}
                                secondary={`Lot ${tempSelectionArray[i].lot}`}
                                />
                            </ListItemButton>
                        </ListItem>
                    </DraggableLocation>
                );
            } else if (tempSelectionArray[i].entryType === 2) {
                tempUnassignedRender.push(
                    <DraggableLocation
                    locCoords={dragState.start_coord}
                    locationDataState={[currentLocationData, setCurrentLocationData]}
                    dragState={dragState}
                    errorState={[errorMsg, setErrorMsg]}
                    setLocation={setCurrentLocation}
                    mode="selector"
                    >
                        <ListItem>
                            <ListItemButton
                            sx={{
                                "&:hover": {
                                borderRadius: "16px"
                                }
                            }}>
                                <ListItemText
                                primary={tempSelectionArray[i].name}
                                secondary={`Aliquot of ${tempSelectionArray[i].parentSampleIdentifier}`}
                                />
                                </ListItemButton>
                        </ListItem>
                    </DraggableLocation>
                );
            }
        }
        setAssignedListRender(tempAssignedRender);
        setUnassignedListRender(tempUnassignedRender);
    }, [selectionArray]);

    //<FileUpload/>
    return (
        <div>
            {errorMsg ? 
            <Box sx={{m:1}}>
                <Alert severity="error" >{errorMsg} </Alert>
            </Box> 
            :null}
            <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center">
                    <Grid item xs={2}>
                        <List 
                        dense={false}
                        styles={{
                            tr: {
                                background: "#f1f1f1",
                                '&:hover': {
                                background: "#f00",
                                }
                        }
                        }}>
                            {unassignedListRender}
                        </List>
                    </Grid>
                    <Grid item xs={3}>
                        <LocationExplorer width={locationExplorerWidth} currentLocation={currentLocation.toString()} setLocation={setCurrentLocation} locationTree={locationTree} mode="selector"/>
                    </Grid>
                    
                    <Grid item xs={5}>
                        <Box sx={{mt:2, flexGrow: 1}}>
                            <SetLocationSelectionContext.Provider value={setSelectionArray}>
                                <LocationSelectionContext.Provider value={selectionArray}>
                                    <LocationSelectionDragContext.Provider value={dragState}>
                                        <Grid container 
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        width="100%"
                                        >
                                        {currentLocationData.locationType === 5 && 
                                        <Box sx={{ mt:1, mb:3, width:"100%", maxWidth:600, minWidth:350}}>
                                            <NxNBox 
                                            locationData={[currentLocationData, setCurrentLocationData]}
                                            setLocation={setCurrentLocation}
                                            errorState={[errorMsg, setErrorMsg]}
                                            mode="selector"
                                            />
                                        </Box>
                                        }
                                        {currentLocationData.locationType === 2 && 
                                        <Box sx={{ mt:1, mb:3, width:"100%", maxWidth:900, minWidth:350}}>
                                            <VerticalFreezer 
                                            locationData={[currentLocationData, setCurrentLocationData]} 
                                            setLocation={setCurrentLocation}
                                            errorState={[errorMsg, setErrorMsg]}
                                            mode="selector"
                                            />
                                        </Box>
                                        }
                                        {currentLocationData.locationType === 4 && 
                                        <Box sx={{ mt:1, mb:3, width:"100%", maxWidth:1000, minWidth:600}}>
                                            <Rack 
                                            locationData={[currentLocationData, setCurrentLocationData]} 
                                            setLocation={setCurrentLocation}
                                            errorState={[errorMsg, setErrorMsg]}
                                            mode="selector"
                                            />
                                        </Box>
                                        }
                                        {(currentLocationData.locationID != 0) &&
                                        <DraggableLocation
                                        locCoords="0"
                                        locationDataState={[currentLocationData, setCurrentLocationData]}
                                        dragState={dragState}
                                        errorState={[errorMsg, setErrorMsg]}
                                        setLocation={setCurrentLocation}
                                        mode="selector"
                                        >
                                            <Box
                                            style={{
                                                border: "4px solid green",
                                                borderRadius: 12,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height:200
                                            }}
                                            sx={{m:1, cursor: 'pointer'}}
                                            >
                                                <AddTwoToneIcon sx={{ color:"green" }} fontSize="large"/>
                                            </Box>
                                        </DraggableLocation>
                                        }
                                    </Grid>
                                </LocationSelectionDragContext.Provider>
                            </LocationSelectionContext.Provider>
                        </SetLocationSelectionContext.Provider>
                    </Box>
                    
                </Grid>
                <Grid item xs={2}>
                    <List dense={false}>
                        {assignedListRender}
                    </List>
                </Grid>
            </Grid>
           
        </div>
    )
}

export default LocationSelector;