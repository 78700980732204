import React from 'react';

const AccountEditForm = ({ formData, handleInputChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit} style={styles.formContainer} autoComplete="off">
      <label style={styles.label}>Name:</label>
      <input
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        style={styles.input}
        />

      <label style={styles.label}>Email:</label>
      <input
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        style={styles.input}
        />

      <label style={styles.label}>Current Password:</label>
      <input
        type='password'
        name="currentPassword"
        autoComplete="new-password" 
        value={formData.currentPassword}
        onChange={handleInputChange}
        required
        style={styles.input}
        />  

      <label style={styles.label}>New Password:</label>
      <input
        type='password'
        name="newPassword"
        autoComplete="new-password"
        value={formData.newPassword}
        onChange={handleInputChange}
        style={styles.input}
        />
      
      <label style={styles.label}>New Password Verification:</label>
      <input
        type='password'
        name="newPasswordVerification"
        autoComplete="new-password"
        value={formData.newPasswordVerification}
        onChange={handleInputChange}
        style={styles.input}
      />
      <button type="submit" style={styles.button}>Save Changes</button>
    </form>
  );
};

const styles = {
  formContainer: {
    backgroundColor: '#f9f9f9',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    maxWidth: '500px',
    margin: '0 auto',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '20px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box', 
  },
  button: {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  buttonHover: { 
    backgroundColor: '#0056b3',
  }
};


export default AccountEditForm;