import {Routes, Route} from 'react-router-dom';
import { createContext, useState } from 'react';
import SignUps from './pages/SignUp';
import Dashboard from './pages/Dashboard';
import SignIn from './pages/SignIn';
import LogOut from './pages/LogOut';
import LabSelection from './pages/LabSelection';
import AccountEdit from './pages/AccountEdit';
import Inventory from './pages/Inventory';
import PrivateRoute from './components/PrivateRoute';
import LabRoute from './components/LabRoute';
import FileUpload from './components/FileUpload';
import Locations from './pages/Locations';
import LabSettings from './pages/LabSettings';
import LocationSelector from './components/Locations/LocationSelector';
import LabActivity from './pages/LabActivity';

export const LoadingContext = createContext();
export const IsLoadingContext = createContext();

function App() {
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <LoadingContext.Provider value={setLoading}>
        <IsLoadingContext.Provider value={loading}>
          <Routes>
            <Route path='/create-account' element={<SignUps />}/>
            <Route path='/sign-in' element={<SignIn />}/>
            <Route path='/logout' element={<PrivateRoute><LogOut /></PrivateRoute>}/>
            <Route path='/lab-selection' element={<PrivateRoute><LabSelection /></PrivateRoute>}/>
            <Route path='/' element={<PrivateRoute><LabRoute><Dashboard /></LabRoute></PrivateRoute>}/>
            <Route path='/lab-settings' element={<PrivateRoute><LabRoute><LabSettings /></LabRoute></PrivateRoute>}/>
            <Route path='/account-edit' element={<PrivateRoute><LabRoute><AccountEdit /></LabRoute></PrivateRoute>}/>
            <Route path='/file-upload' element={<PrivateRoute><LabRoute><FileUpload /></LabRoute></PrivateRoute>}/>
            <Route path='/locations' element={<PrivateRoute><LabRoute><Locations /></LabRoute></PrivateRoute>}/>
            <Route path='/inventory' element={<PrivateRoute><LabRoute><Inventory/></LabRoute></PrivateRoute>}/>
            <Route path='/lab-activity' element={<PrivateRoute><LabRoute><LabActivity/></LabRoute></PrivateRoute>}/>
            <Route path='/test' element={<PrivateRoute><LabRoute><LocationSelector select={[{name:'huhhhh', entryType:1, lot:2312312}, {name:'huhhhh1', entryType:1, lot:12311235}]}/></LabRoute></PrivateRoute>}/>
          </Routes>
        </IsLoadingContext.Provider>
      </LoadingContext.Provider>
    </div>
  );
}

export default App;
