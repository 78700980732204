//Filters different type of numeric inputs manually, regexps with .replace was being glitchy
export default function numericInputFilter(input, allowScientific = false, allowDecimal = false, places = 3) {
    const allowed = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);
    if (allowScientific && !allowDecimal) {
        allowed.add('e');
        allowed.add('+');
        allowed.add('-');
        input = input.split('').map(x => allowed.has(x) ? x : '').join('');
    } else if (allowDecimal && !allowScientific) {
        allowed.add('.');
        input = input.split('').map(x => allowed.has(x) ? x : '').join('');
        if ((input.split(".").length - 1) > 1) {
            let temp = input.split('');
            temp[input.lastIndexOf('.')] = '';
            console.log(temp);
            input = temp.join('');
        }
        if (input[input.length - 1] != '.') {
            input = String(Math.round(input * (10 ** places)) / (10 ** places));
        }
    } else {
        input = input.split('').map(x => allowed.has(x) ? x : '').join('');
    }
    return input;
}