import React, { useState, useEffect } from 'react';
import axios from 'axios';
import APIServerPath from "../components/Constants/APIServerPath";
import NavigationMenu from '../components/layout/NavigationMenu';
import AccountEditForm from '../components/AccountEditForm'; 
import Dashboard from './Dashboard';


const client = axios.create({
  baseURL:APIServerPath
});

const AccountEdit = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    currentPassword: '', // Requries the user's current password to make changes
    newPassword: '',
    newPasswordVerification: '',
  });
  const [loading, setLoading] = useState(true);

  // Function to fetch user information
  const fetchUserData = async () => {
    try {
      const response = await client.get('retrieve_user_information', {
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        name: response.data.name,
        email: response.data.email,
        currentPassword: '',
        newPassword: '',
        newPasswordVerification: '',
      }));  
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  };

  // useEffect to fetch user data on component mount
  useEffect(() => {
    fetchUserData();
  }, []); // Empty dependency array ensures the effect runs only once on mount


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await client.post('update_user_information', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      window.alert(response.data.msg);
      console.log(response.data.msg);
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };  


  if (loading) {
    return <Dashboard /> // Kept dashboard as a placeholder
  }

  return (
    <div style={styles.container}>
      <NavigationMenu />
      <AccountEditForm formData={formData} handleInputChange={handleInputChange} handleSubmit={handleFormSubmit}/>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '60px',
  },
};


export default AccountEdit;
