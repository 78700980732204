import { useState, useEffect, useContext } from 'react';
import React from 'react';
import { Box, Typography, Dialog, Alert, DialogTitle, DialogContent, Grid, Button, TextField, Select, MenuItem, Tabs, Tab, FormControlLabel, Checkbox, Autocomplete} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingContext } from '../../../App';
import APIServerPath from '../../Constants/APIServerPath';
import axios from 'axios';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


function SampleAliquotGroupsTabCard (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const mode = props.mode;//modes are add or update
    const sampleAliquotGroupID = props.sampleAliquotGroupData.sampleAliquotGroupID;
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = useState(null);
    //form data
    const [sampleAliquotGroupName, setSampleAliquotGroupName] = useState(props.sampleAliquotGroupData.sampleAliquotGroupName);
    const [sampleAliquotGroupDescription, setSampleAliquotGroupDescription] = useState(props.sampleAliquotGroupData.sampleAliquotGroupDescription);
    const [sampleGroupID, setSampleGroupID] = useState(props.sampleAliquotGroupData.sampleGroupID)
    //Update mode data
    const [allSampleGroupID, setAllSampleGroupID] = useState([]);
    const [sampleGroupTable, setSampleGroupTable] = useState({"":{name:""}});
    const [updateSampleGroup, setUpdateSampleGroup] = useState(0);

    //Sample group parameters
    const [selectedSampleAliquotGroupParameter, setSelectedSampleAliquotGroupParameter] = useState(null);
    const [sampleAliquotGroupParameterRows, setSampleAliquotGroupParameterRows] = useState([]);
    const [parameterName, setParameterName] = useState(null);
    const [parameterDescription, setParameterDescription] = useState(null);


    const handleParameterRemoval = (toBeRemovedID) => {
        let tempParameterRows = sampleAliquotGroupParameterRows.slice();
        for (let i=0; i < tempParameterRows.length; i++) {
            if (tempParameterRows[i].sampleAliquotGroupPropertiesID === toBeRemovedID){
                let removalIndex = i;
                tempParameterRows.splice(removalIndex, 1);
                break;
            }
        }
        setSampleAliquotGroupParameterRows(tempParameterRows);
    }

    const handleAddParameter = () => {
        //find free ids
        let tempParameterRows = sampleAliquotGroupParameterRows.slice();;
        let highestID = 0;
        for (let i=0; i<tempParameterRows.length; i++){
            if (+tempParameterRows[i].id > highestID) {
                highestID = +tempParameterRows[i].id;
            }
        }
        highestID++;
        let newParameterRow = {
            id:highestID,
            sampleAliquotGroupPropertiesID:highestID, 
            parameterName:"", 
            parameterDescription:""
        };
        tempParameterRows.push(newParameterRow);
        setSampleAliquotGroupParameterRows(tempParameterRows);
        setSelectedSampleAliquotGroupParameter(newParameterRow);
    }

    const handleSaveParameter = () => {
        //find correct item in array of actions
        let tempParameter = selectedSampleAliquotGroupParameter;
        let tempParameterRows = sampleAliquotGroupParameterRows.slice();
        tempParameter.parameterName = parameterName;
        tempParameter.parameterDescription = parameterDescription;
        for (let i=0;i<tempParameterRows.length;i++) {
            if (tempParameter.id === tempParameterRows[i].id &&  tempParameter.sampleAliquotGroupPropertiesID === tempParameterRows[i].sampleAliquotGroupPropertiesID) {
                tempParameterRows[i] = tempParameter;
            break;
            }
        }
        setSampleAliquotGroupParameterRows(tempParameterRows);
        setSelectedSampleAliquotGroupParameter(null);
        setParameterName(null);
        setParameterDescription(null);
    }
    const sampleAliquotGroupParameterColumns = [
        {
            field: 'parameterName',
            headerName: 'Name',
            flex: 1,
            editable: false,
        },
        {
            field: 'parameterDescription',
            headerName: 'Description',
            flex: 2,
            editable: false,
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div>
                        <DeleteForeverIcon color="primary" onClick={event =>  handleParameterRemoval(params.row.sampleAliquotGroupPropertiesID)} />
                    </div>
                );
            }
        }
    ];


    //generate tab rendering
    const [currentTab, setCurrentTab] = useState("0");
    const [tabRenderData, setTabRenderData] = useState([]);
    const tabNames = {
        "0" : "General",
        "1" : "Parameters"
    };

    const handleTabChange = (event, newTab) => {
        setCurrentTab(newTab);
    };

    useEffect(()=> {//tab rendering
        let renderData = [];
        Object.keys(tabNames).forEach(function(key, index) {
            renderData.push(<Tab value={key} label={tabNames[key]}/>);
        });
        setTabRenderData(renderData);
        renderData = [];
    }, [currentTab]);


    //handle add or update request
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.currentTarget);
        formData.append("lab_ID", localStorage.labID);
        formData.append("sampleGroupID", sampleGroupID);
        formData.append("sampleAliquotGroupName", sampleAliquotGroupName);
        formData.append("sampleAliquotGroupDescription", sampleAliquotGroupDescription);
        if (mode === "update"){
            formData.append("sampleAliquotGroupID", sampleAliquotGroupID);
            formData.append("sampleAliquotGroupParameters", JSON.stringify(sampleAliquotGroupParameterRows));
            const response = client.post(
                'update_sample_aliquot_group', 
                formData,
                {headers}
                ).then(function (response) {
                    if (response.data.msg) {//show error alert
                        setErrorMsg(response.data.msg);
                        setLoading(false);
                    } else{//save token and name to cookies and redirect to dashboard
                        setErrorMsg(null);
                        setLoading(false);
                        props.close();
                    }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        } else {
            const response = client.post(
                'add_sample_aliquot_group', 
                formData,
                {headers}
                ).then(function (response) {
                    if (response.data.msg) {//show error alert
                        setErrorMsg(response.data.msg);
                        setLoading(false);
                    } else{//save token and name to cookies and redirect to dashboard
                        setErrorMsg(null);
                        setLoading(false);
                        props.close();
                    }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        }
    };

    //retrieve sample group information
    useEffect(()=> {
        if (mode === "update") {
            let tempSampleGroupTable = {};
            tempSampleGroupTable[props.sampleAliquotGroupData.sampleGroupID] = props.sampleAliquotGroupData.sampleGroupName;
            setAllSampleGroupID([props.sampleAliquotGroupData.sampleGroupID]);
            setSampleGroupTable(tempSampleGroupTable);
            setLoading(true);
            let formData = new FormData();
            formData.append("lab_ID", localStorage.labID);
            formData.append("sampleAliquotGroupID", sampleAliquotGroupID);
            formData.append("sampleAliquotGroupData", 1);
            formData.append("sampleGroupTable", 1);
            formData.append("sampleAliquotGroupAccess", 1);
            formData.append("sampleAliquotGroupParameters", 1);
            const response = client.post(
                'retrieve_sample_aliquot_group_data', 
                formData,
                {headers}
                ).then(function (response) {
                if (response.data.msg) {//Error messages
                    setErrorMsg(response.data.msg);
                    setLoading(false);
                } else{//
                    let tempAllSampleGroupIDs = []
                    Object.keys(response.data.sampleGroupTable).forEach(function(key) {
                        tempAllSampleGroupIDs.push(key);
                    })
                    setAllSampleGroupID(tempAllSampleGroupIDs);
                    setSampleGroupTable(response.data.sampleGroupTable);
                    setSampleGroupID(response.data.sampleGroupID);
                    setSampleAliquotGroupParameterRows(response.data.sampleAliquotGroupParameters);
                    setErrorMsg(null);
                    setLoading(false);
                }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        } else {
            setLoading(true);
            let formData = new FormData();
            formData.append("lab_ID", localStorage.labID);
            formData.append("sampleAliquotGroupData", 0);
            formData.append("sampleGroupTable", 1);
            formData.append("sampleAliquotGroupAccess", 0);
            formData.append("sampleAliquotGroupParameters", 0);
            const response = client.post(
                'retrieve_sample_aliquot_group_data', 
                formData,
                {headers}
                ).then(function (response) {
                if (response.data.msg) {//Error messages
                    setErrorMsg(response.data.msg);
                    setLoading(false);
                } else{//
                    let tempAllSampleGroupIDs = []
                    Object.keys(response.data.sampleGroupTable).forEach(function(key) {
                        tempAllSampleGroupIDs.push(key);
                    })
                    setAllSampleGroupID(tempAllSampleGroupIDs);
                    setSampleGroupTable(response.data.sampleGroupTable);
                    setErrorMsg(null);
                    setLoading(false);
                }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        }
    }, [sampleAliquotGroupID]);

    return (
        <React.Fragment>
            <Dialog
            open={sampleAliquotGroupID} 
            onClose={()=>{props.close();}}
            fullWidth
            maxWidth="lg"
            >
                <DialogTitle id="scroll-dialog-title">
                    {(mode === "update") ?
                    "Update Sample Aliquot Group":
                    "Add Sample Aliquot Group"
                    }
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 500, width:'100%'}} component="form" noValidate onSubmit={handleSubmit} justifyContent="center" alignment="center">
                        <Grid 
                        container 
                        justifyContent="center"
                        alignItems="center"
                        sx={{ p: 1}}>
                            {errorMsg ? 
                            <Grid item xs={12} sx={{p:1}}>
                                <Alert severity="error">{errorMsg}</Alert>
                            </Grid>
                            :null}
                            {(mode === "update") ?
                            <Grid item xs={12} sx={{}}>
                                <Tabs 
                                value={currentTab} 
                                onChange={handleTabChange}
                                centered
                                >
                                    {tabRenderData}
                                </Tabs>
                            </Grid>
                            :null}
                            
                            {currentTab==="0" ?
                            <React.Fragment>
                                <Grid item xs={4} sx={{p:2}}>
                                    <Typography variant="h6" align="center">
                                        Parent Sample Group
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sx={{p:2}}>
                                    {updateSampleGroup?
                                    <Autocomplete
                                    freeSolo
                                    id="sample-group"
                                    disableClearable
                                    options={allSampleGroupID}
                                    onChange={(event, newID) => {
                                        setSampleGroupID(newID);
                                    }}
                                    getOptionLabel={(option) => sampleGroupTable[option].name}
                                    renderInput={(params) => (
                                        <TextField
                                            size="small"
                                            {...params}
                                            label="Search Sample Groups"
                                            InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                            }}
                                        />
                                    )}/>
                                    :
                                    <Typography variant="h6" align="center">
                                        {props.sampleAliquotGroupData.sampleGroupName}
                                    </Typography>
                                    }
                                </Grid>
                                <Grid item xs={12} sx={{p:2}}>
                                    <FormControlLabel 
                                    control={
                                        <Checkbox
                                        checked={updateSampleGroup}
                                        onChange={(event)=>{
                                            setUpdateSampleGroup(event.target.checked);
                                            if (!event.target.checked) {
                                                setSampleGroupID(props.sampleAliquotGroupData.sampleGroupID);
                                            }
                                        }}
                                        />
                                    } 
                                    label="Set new parent sample group" 
                                    />
                                </Grid>
                                <Grid item xs={4} sx={{p:2}}>
                                    <Typography variant="h6" align="center">
                                        Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sx={{p:2}}>
                                    <TextField
                                    id="sample-group-name"
                                    label="Name"
                                    value={sampleAliquotGroupName}
                                    size="small"
                                    onChange={(event) => {
                                        setSampleAliquotGroupName(event.target.value);
                                    }}
                                    fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} sx={{p:2}}>
                                    <Typography variant="h6" align="center">
                                        Description
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sx={{p:2}}>
                                    <TextField
                                    id="sample-group-description"
                                    label="Description"
                                    value={sampleAliquotGroupDescription}
                                    onChange={(event) => {
                                        setSampleAliquotGroupDescription(event.target.value);
                                    }}
                                    fullWidth
                                    multiline
                                    />
                                </Grid>
                            </React.Fragment>
                            :null}
                            {(mode === "update")&& currentTab==="1" &&
                            <React.Fragment>
                                <Grid 
                                item
                                xs={selectedSampleAliquotGroupParameter? 
                                7
                                :12
                                } sx={{p:2}}>
                                    <Box sx={{ height: 350, width: '100%' }}>
                                        <Button 
                                        variant="outlined" 
                                        onClick={()=>{
                                            handleAddParameter();
                                        }}>
                                            Add New Parameter
                                            </Button>
                                        <DataGrid
                                        rows={sampleAliquotGroupParameterRows}
                                        columns={sampleAliquotGroupParameterColumns}
                                        disableRowSelectionOnClick
                                        onCellDoubleClick={(params, event, details)=> {
                                            //check permissions to modify project information
                                            //set selectedProtocol
                                            setSelectedSampleAliquotGroupParameter(params.row);
                                            setParameterName(params.row.parameterName);
                                            setParameterDescription(params.row.parameterDescription);
                                        }}
                                        />
                                    </Box>
                                </Grid>
                                {selectedSampleAliquotGroupParameter?
                                <Grid item xs={5} sx={{p:2}}>
                                    <Box sx={{ height: 350, width: '100%' }}>
                                        <Button 
                                        variant="outlined" 
                                        align="right"
                                        onClick={()=>{
                                            handleSaveParameter();
                                        }}>
                                            Close Parameter
                                        </Button>
                                        <Grid container>
                                            <Grid item xs={4} sx={{p:2}}>
                                                <Typography variant="subtitle1" align="center">
                                                    Name
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} sx={{p:2}}>
                                                <TextField
                                                id="parameter-name"
                                                label="Name"
                                                value={parameterName}
                                                onChange={(event) => {
                                                    setParameterName(event.target.value);
                                                }}
                                                fullWidth
                                                multiline
                                                />
                                            </Grid>
                                            <Grid item xs={4} sx={{p:2}}>
                                                <Typography variant="subtitle1" align="center">
                                                    Description
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} sx={{p:2}}>
                                                <TextField
                                                id="parameter-description"
                                                label="Description"
                                                value={parameterDescription}
                                                onChange={(event) => {
                                                    setParameterDescription(event.target.value);
                                                }}
                                                fullWidth
                                                multiline
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                :null}
                            </React.Fragment>
                            }
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {(mode === "update") ?
                                "Update Sample Aliquot Group":
                                "Add Sample Aliquot Group"
                                }
                            </Button> 
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
)
}

export default SampleAliquotGroupsTabCard;