import { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingContext } from '../../../App';
import APIServerPath from '../../Constants/APIServerPath';
import axios from 'axios';
import SampleGroupsTabCard from './SampleGroupsTabCard';

function SampleGroupsTab (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const userPerms = JSON.parse(localStorage.userPermissions);
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = [props.errorMsg, props.setErrorMsg];
    const [rows, setRows] = useState([]);
    const [selectedSampleGroup, setSelectedSampleGroup] = useState(null);
    const [newSampleGroupCardOpen, setNewSampleGroupCardOpen] = useState(null);
    const columns = [
        {
            field: 'sampleGroupName',
            headerName: 'Name',
            flex: 1,
            editable: false,
        },
        {
            field: 'sampleGroupDescription',
            headerName: 'Description',
            flex: 2,
            editable: false,
        }
    ];
    //retrieve sampleGroups associated with lab and which the user has access to
    useEffect(()=> {
        setLoading(true);
        let formData = new FormData();
        formData.append("lab_ID", localStorage.labID);
        const response = client.post(
            'retrieve_lab_sample_groups', 
            formData,
            {headers}
            ).then(function (response) {
            if (response.data.msg) {//there should be no error messages from this query
                setErrorMsg(response.data.msg);
                setLoading(false);
            } else{
                setRows(response.data.sampleGroupData);
                setLoading(false);
            }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });
    }, [selectedSampleGroup]);

    //handling add mode card
    const handlAddSampleGroupCardOpen = (event) => {
        event.preventDefault();
        if (userPerms.sampleInventory) {
            setSelectedSampleGroup({
                sampleGroupID:true,
                sampleGroupName:"",
                sampleGroupDescription:""
            });
            setNewSampleGroupCardOpen(true);
        }
    };

    //closes all cards
    const handleCardClose = () => {
        setSelectedSampleGroup(null);
        setNewSampleGroupCardOpen(false);
    }

    return (
        <div>
            {selectedSampleGroup && !newSampleGroupCardOpen && <SampleGroupsTabCard  sampleGroupData={selectedSampleGroup} close={handleCardClose} mode="update"/>}
            {newSampleGroupCardOpen && <SampleGroupsTabCard  sampleGroupData={selectedSampleGroup} close={handleCardClose} mode="add"/>}
            <Grid
            container
            justifyContent="right"
            alignItems="right"
            spacing={12}>
                <Grid item xs={8.5}> 
                    <Typography variant="h6" align="left">
                        Sample Groups
                    </Typography>
                </Grid>

                <Grid item xs={3.5}>
                {userPerms.sampleInventory ?
                    <Button variant="outlined" onClick={handlAddSampleGroupCardOpen}>Add New Sample Group</Button>
                : null
                }
                </Grid>
            </Grid>
            <Box sx={{ height: 350, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    onCellDoubleClick={(params, event, details)=> {
                        //check permissions to modify project information
                        //set selectedSampleGroup
                        if (params.row.permissions === 2 || userPerms.sampleInventory) {
                            setSelectedSampleGroup(params.row);
                        }
                    }}
                />
            </Box>
        </div>
    )
}

export default SampleGroupsTab;