import {Navigate, useNavigate} from 'react-router-dom';
import axios from 'axios';
import APIServerPath from '../components/Constants/APIServerPath';

function LogOut() {
    const navigate = useNavigate();
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}` };

    const client = axios.create({
      baseURL:APIServerPath
    });

    const logout_response = client.post(
    'logout', 
    {},
    {headers}
    );

    localStorage.clear();
    return <Navigate to='/sign-in' />
}

export default LogOut;
