import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { PropaneSharp } from '@mui/icons-material';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
//Props Include: react states for left and right IDs, left and right labels, and object with definitions of IDs

export default function TransferList(props) {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = [props.left, props.setLeft];
  const [right, setRight] = [props.right, props.setRight];

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const handleRemoval = () => {
    let tempRemoval = props.removal;
    let tempLeft = left;
    let tempRight = right;
    for (let i =0; i < checked.length; i++){
      tempRemoval.push(checked[i]);//Add to removal array
      if (tempLeft.includes(checked[i])) {
        tempLeft.splice(tempLeft.indexOf(checked[i]), 1);
        setLeft(tempLeft);
      } else if (tempRight.includes(checked[i])) {
        tempRight.splice(tempRight.indexOf(checked[i]), 1)
        setRight(tempRight);
      }
    }
    setChecked([]);
    props.setRemoval(tempRemoval);
  };

  React.useEffect(()=>{
  }, [left, right]);

  const customList = (items) => (
    <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItemButton
              key={value}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={props.definitions[value]["name"]} />
            </ListItemButton>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        <Typography variant="h7" align="left">
            {props.leftLabel}
        </Typography>
        {customList(left)}
        </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
          {props.removal?
          <Button
          sx={{ my: 0.5 }}
          variant="outlined"
          size="small"
          onClick={handleRemoval}
          disabled={rightChecked.length === 0 && leftChecked.length === 0}
          aria-label="removal"
        >
          Remove
        </Button>
          :null}
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h7" align="left">
            {props.rightLabel}
        </Typography>
        {customList(right)}
        </Grid>
    </Grid>
  );
}