import { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingContext } from '../../../App';
import APIServerPath from '../../Constants/APIServerPath';
import axios from 'axios';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PatientSettingsTabCard from './PatientSettingsTabCard';

function PatientSettingsTab (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const userPerms = JSON.parse(localStorage.userPermissions);
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = [props.errorMsg, props.setErrorMsg];
    const [rows, setRows] = useState([]);
    const [selectedPatientParameter, setSelectedPatientParameter] = useState(null);
    const [newParameterCardOpen, setNewParameterCardOpen] = useState(null);
    const [removals, setRemovals] = useState(null);

    const handleCardOpen = (event) => {
        //handling add mode card
        event.preventDefault();
        if (userPerms.sampleInventory) {
            setSelectedPatientParameter({
                labPatientPropertiesID:true,
                parameterName:"",
                parameterDescription:""
            });
            setNewParameterCardOpen(true);
        }
    }

    const handleCardClose = () => {
        //handling add mode card
        setSelectedPatientParameter(null);
        setNewParameterCardOpen(null);
    }

    const handleParameterRemoval = (labPatientPropertiesID) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("lab_ID", localStorage.labID);
        formData.append("labPatientPropertiesID", labPatientPropertiesID);
        const response = client.post(
            'remove_lab_patient_properties', 
            formData,
            {headers}
            ).then(function (response) {
            if (response.data.msg) {//there should be no error messages from this query
                setErrorMsg(response.data.msg);
                setLoading(false);
            } else{
                setRemovals(true);
                setLoading(false);
            }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });
    }

    const columns = [
        {
            field: 'parameterName',
            headerName: 'Parameter Name',
            flex: 1,
            editable: false,
        },
        {
            field: 'parameterDescription',
            headerName: 'Description',
            flex: 2,
            editable: false,
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div>
                        {userPerms.sampleInventoryRemovals&&
                        <DeleteForeverIcon color="primary" onClick={event =>  handleParameterRemoval(params.row.labPatientPropertiesID)} />
                        }
                    </div>
                );
            }
        }
    ];
    //retrieve patients associated with lab and which the user has access to
    useEffect(()=> {
        setLoading(true);
        let formData = new FormData();
        formData.append("lab_ID", localStorage.labID);
        const response = client.post(
            'retrieve_lab_patient_parameters', 
            formData,
            {headers}
            ).then(function (response) {
            if (response.data.msg) {//there should be no error messages from this query
                setErrorMsg(response.data.msg);
                setLoading(false);
            } else{
                setRows(response.data.patientParameters);
                setRemovals(null);
                setLoading(false);
            }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });
    }, [selectedPatientParameter, removals]);

    return (
        <div>
            {selectedPatientParameter && !newParameterCardOpen && <PatientSettingsTabCard parameterData={selectedPatientParameter} close={handleCardClose} mode="update"/>}
            {newParameterCardOpen && <PatientSettingsTabCard parameterData={selectedPatientParameter} close={handleCardClose} mode="add"/>}
            <Grid
            container
            justifyContent="right"
            alignItems="right"
            spacing={12}>
                <Grid item xs={8.5}> 
                    <Typography variant="h6" align="left">
                        Patient Settings/Parameters
                    </Typography>
                </Grid>

                <Grid item xs={3.5}>
                {userPerms.sampleInventory ?
                    <Button variant="outlined" onClick={handleCardOpen}>Add New Parameter</Button>
                : null
                }
                </Grid>
            </Grid>
            <Box sx={{ height: 350, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    onCellDoubleClick={(params, event, details)=> {
                        //check permissions to modify project information
                        //set selectedPatient
                        if (userPerms.sampleInventory) {
                            setSelectedPatientParameter(params.row);
                        }
                    }}
                />
            </Box>
        </div>
    )
}

export default PatientSettingsTab;