import { Container, CssBaseline } from "@mui/material";
import React from "react";
import { useState, useCallback, useEffect, useContext } from "react";
import DraggableIcon from "./DraggableIcon";
import APIServerPath from "../../Constants/APIServerPath";
import { LoadingContext } from "../../../App";
import axios from "axios";


function GeneralLocations (props) {
    const [iconRender, setIconRender] = useState([]);
    const [updates, setUpdates] = useState([]);//list of updates to process
    /*
    update objects: {
        locationID:#,
        updatedX:#,//may need to change to deltaX depending on testing
        updatedY:#,//may need to change to deltaY depending on testing
    }
    */
    const setLoading = useContext(LoadingContext);
    const client = axios.create({
        baseURL:APIServerPath
    });
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}` };
    const [locationData, setLocationData] = props.locationData;
    const [containerHeight, setContainerHeight] = useState(0);//stores current container height
    const [containerWidth, setContainerWidth] = useState(0);//stores current container width
    const [errorMsg, setErrorMsg] = props.errorState;


    useEffect(()=> {//processes updates to sublocation X and Y
        var updatePackage = [];
        if (updates.length > 0) {
            let dbData = structuredClone(locationData);
            let updateQueue = updates.slice();
            while (updateQueue.length > 0) {
                //update local data and create update queue to send to DB
                let queue = updateQueue.shift();//first update in queue
                for (let position of Object.keys(structuredClone(dbData).children)) {//find sublocation in location data object
                    if (dbData.children[position].locationID === queue.locationID) {
                        //update coordinates in location object
                        if (position !== `${queue.updatedX.toFixed(2)},${queue.updatedY.toFixed(2)}`){
                            updatePackage.push(
                                {
                                    entryID:dbData.children[position].locationID,
                                    entryType:0,
                                    updatedX:queue.updatedX.toFixed(2),
                                    updatedY:queue.updatedY.toFixed(2),
                                }
                            );//add to update package
                            dbData.children[`${queue.updatedX.toFixed(2)},${queue.updatedY.toFixed(2)}`] = structuredClone(dbData.children[position]);
                            delete dbData.children[position];
                        }
                        break;
                    }
                }
            }
            setLocationData(dbData);
            setUpdates([]);
            //backend api call
            setLoading(true);
            const response = client.post(
                'update_location_coord', 
                {
                    lab_ID:localStorage.labID,
                    updates:updatePackage
                },
                {headers}
                ).then(function (response) {
                if (response.data.msg) {//show error alert
                    setErrorMsg(response.data.msg);
                    setLoading(false);
                } else{//reset currentLocation State to trigger rerender and updated locationData
                    setErrorMsg(null);
                    props.setLocation(locationData.locationID);
                    setLoading(false);
                }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
    
        }
    }, [updates]);

    useEffect(()=> {//update rendering of icons
        let locationIDOrder = []; //always render locationID low to high to have a fixed rendering order to prevent mixing of icons during transition animation
        for (let position of Object.keys(locationData.children)) {
            locationIDOrder.push(locationData.children[position].locationID);
        }
        locationIDOrder.sort();
        let renders = [];
        //Generate rendering of icons of sublocations
        for (let index = 0; index < locationIDOrder.length; index++) {
            for (let position of Object.keys(locationData.children)) {
                if (locationData.children[position].locationID === locationIDOrder[index]) {
                    let iconPositions = position.split(",");//retrieve X, Y relative % coordinates from children/sub-location data in dbReturn
                    let iconRelativeLeft = parseFloat(iconPositions[0]);
                    let iconRelativeTop = parseFloat(iconPositions[1]);
                    renders.push(//Renders
                        <DraggableIcon 
                        containerHeight={containerHeight} 
                        containerWidth={containerWidth}
                        locationID={locationData.children[position].locationID}
                        locationName={locationData.children[position].name}
                        relativeTop={iconRelativeTop}
                        relativeLeft={iconRelativeLeft}
                        setLocation={props.setLocation}
                        updates = {updates}//add update to list to trigger update of icon coordinates and re-render
                        setUpdates = {setUpdates}//add update to list to trigger update of icon coordinates and re-render
                        />
                    );
                    break;
                }
            }
            setIconRender(renders);
        }
    }, [locationData,containerHeight, containerWidth])

    const elementRef = useCallback(node => {//update container height and width on resize or render
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            setContainerWidth(node.clientWidth);
            setContainerHeight(node.clientHeight);
        });
        resizeObserver.observe(node);
      }, []);

    return (
        <div>
            <CssBaseline /> 
            <Container ref={elementRef} component="main" maxWidth="xl" sx={{ border: "1px solid black", width:"100%", position:"relative"}} disableGutters={true}>
                <img style={{width:"100%", height:"100%"}} src={`${APIServerPath}retrieve_file/${props.locationImageToken}`}/>
                {iconRender}
            </Container>
        </div>
    )
}

export default GeneralLocations;