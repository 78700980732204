import React from "react";
import { useContext } from "react";
import axios from "axios";
import APIServerPath from "../Constants/APIServerPath";
import { LoadingContext } from "../../App";
import { BoxDragContext } from "../../pages/Locations";
import { LocationSelectionContext, SetLocationSelectionContext, LocationSelectionDragContext } from "./LocationSelector";



function DraggableLocation ({children, ...props}) {//props include locationData
    const dragState= props.dragState;
    const [locationData,setLocationData] = props.locationDataState;
    const setLoading = useContext(LoadingContext);
    const setIsDragging = useContext(BoxDragContext);
    const setSelectionArray = useContext(SetLocationSelectionContext);//only conditionally used depending on mode prop
    const selectionArray = useContext(LocationSelectionContext);
    const selectDragState = useContext(LocationSelectionDragContext);
    const client = axios.create({
        baseURL:APIServerPath
    });
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}` };
    const [errorMsg, setErrorMsg] = props.errorState;

    
    const handleDragStart = (e, start_coord) => {
        if (props.mode != "selector" && setIsDragging){
            setIsDragging(true);
        }
        dragState.start_coord = start_coord;
        e.dataTransfer.setData('text/plain', '');
    }

    const handleDragOver = (e) =>{
        e.preventDefault();
    }

    const handleDrop = (e, end_coord) => {//handle updates to location data
        var start_coord = dragState.start_coord;
        let dbData = structuredClone(locationData);
        
        if (props.mode === "selector" && !setIsDragging && selectDragState){
            var start_coord = selectDragState.start_coord;
            let tempSelectionArray = selectionArray.slice();
            //in selector mode, start_coord is interpreted as the index of the item in the selection array
            if ((!start_coord) || (start_coord.split(',').length > 1)){
                return;
            }
            let selectionIndex = +start_coord;
            //update object locationID and coordinates
            tempSelectionArray[selectionIndex].locationID = locationData.locationID;
            if (end_coord === "0") {//indicates no specific location selection
                tempSelectionArray[selectionIndex].locationX = null;
                tempSelectionArray[selectionIndex].locationY = null;
            } else {
                tempSelectionArray[selectionIndex].locationX = end_coord.split(',')[0];
                tempSelectionArray[selectionIndex].locationY = end_coord.split(',')[1];
            }
            //update locationData object to update rendering
            if (tempSelectionArray[selectionIndex].entryType === 1){//consumable items
                dbData.items[end_coord] = tempSelectionArray[selectionIndex];
            } else if (tempSelectionArray[selectionIndex].entryType === 2){//sample aliquot
                dbData.samples[end_coord] = tempSelectionArray[selectionIndex];
            }
            setSelectionArray(tempSelectionArray);
            setLocationData(dbData);
        } else if (setIsDragging) {
            //updated coords
            setIsDragging(false);
            var freezer_correction = false;
            var updatePackage = [];
            if ((locationData.locationType === 2) || (locationData.locationType === 2)) {
                var freezer_correction = true;
            }
            if (start_coord === null) {
                dragState.start_coord = null;
                return;
            }
            if (start_coord === end_coord) {//no movement
                dragState.start_coord = null;
                return;
            } else {//move item to new coordinate. if coordinate is occupied, swap coordinates
                //get starting position item data
                var end_data = null;
                var start_data = null;
                var end_type = null;
                var start_type = null;
                //find start and end data and respective types. Determine if ending position is occupied
                //overwriting start/end type/data is okay since each coordinate should have only 1 occupant
                for (let position of Object.keys(structuredClone(dbData.children))) {//check sublocation type (start_type = 0)
                    if (position === end_coord) {
                        var end_data = dbData.children[end_coord]
                        var end_type = 0;
                    }
                    if (position === start_coord) {
                        var start_data = dbData.children[start_coord]
                        var start_type = 0;
                    }
                }
                for (let position of Object.keys(structuredClone(dbData.items))) {//check items type (start_type = 1)
                    if (position === end_coord) {
                        var end_data = dbData.items[end_coord]
                        var end_type = 1;
                    }
                    if (position === start_coord) {
                        var start_data = dbData.items[start_coord]
                        var start_type = 1;
                    }
                }
                for (let position of Object.keys(structuredClone(dbData.samples))) {//check samples type (start_type = 2)
                    if (position === end_coord) {
                        var end_data = dbData.samples[end_coord]
                        var end_type = 2;
                    }
                    if (position === start_coord) {
                        var start_data = dbData.samples[start_coord]
                        var start_type = 2;
                    }
                }
                if (start_type === null) {//no item/sample/sublocation in starting coordinate
                    return;
                }
                if (end_type === null) {
                    //move data
                    switch (start_type) {
                        case 0://sublocation move
                            updatePackage.push(
                                {
                                    entryID:dbData.children[start_coord].locationID,
                                    entryType:0,
                                    updatedX:end_coord.split(',')[0],
                                    updatedY:end_coord.split(',')[1],
                                }
                            );
                            dbData.children[end_coord] = start_data;
                            delete dbData.children[start_coord];
                            break;
                        case 1://item move
                            updatePackage.push(
                                {
                                    entryID:dbData.items[start_coord].itemID,
                                    entryType:1,
                                    updatedX:end_coord.split(',')[0],
                                    updatedY:end_coord.split(',')[1],
                                }
                            );
                            dbData.items[end_coord] = start_data;
                            delete dbData.items[start_coord];
                            break;
                        case 2://sample move
                            updatePackage.push(
                                {
                                    entryID:dbData.samples[start_coord].sampleID,
                                    entryType:2,
                                    updatedX:end_coord.split(',')[0],
                                    updatedY:end_coord.split(',')[1],
                                }
                            );
                            dbData.samples[end_coord] = start_data;
                            delete dbData.samples[start_coord];
                            break;
                    }
                    if (freezer_correction) {
                        //check for freezer location correction: if item is not moved between rows, all item coordinates in that row must have 1 subtracted from X
                        var start_y = start_coord.split(',')[1];
                        var i = 0;
                        let gap = 0;
                        while (true) {
                            i = i + 1;//start at x=1 and find gaps
                            let updated = false;
                            if (gap > 1) {//max gaps possible should be 1, gap === 2 indicates end of row
                                break;
                            }
                            for (let position of Object.keys(structuredClone(dbData.children))) {//check sublocation type (start_type = 0)
                                if (position === [i, start_y].toString()) {
                                    updated = true;
                                    if (gap > 0) {
                                        updatePackage.push(
                                            {
                                                entryID:dbData.children[[i, start_y].toString()].locationID,
                                                entryType:0,
                                                updatedX:i - 1,
                                                updatedY:start_y,
                                            }
                                        );
                                        dbData.children[[i - 1, start_y].toString()] = dbData.children[[i, start_y].toString()];
                                        delete dbData.children[[i, start_y].toString()];
                                        break;
                                    }
                                }
                            }
                            if (updated) {continue;}
                            for (let position of Object.keys(structuredClone(dbData.items))) {//check items type (start_type = 1)
                                if (position === [i, start_y].toString()) {
                                    updated = true;
                                    if (gap > 0) {
                                        updatePackage.push(
                                            {
                                                entryID:dbData.items[[i, start_y].toString()].itemID,
                                                entryType:1,
                                                updatedX:i - 1,
                                                updatedY:start_y,
                                            }
                                        );
                                        dbData.items[[i - 1, start_y].toString()] = dbData.items[[i, start_y].toString()];
                                        delete dbData.items[[i, start_y].toString()];
                                        break;
                                    }
                                }
                            }
                            if (updated) {continue;}
                            for (let position of Object.keys(structuredClone(dbData.samples))) {//check samples type (start_type = 2)
                                if (position === [i, start_y].toString()) {
                                    updated = true;
                                    if (gap > 0) {
                                        updatePackage.push(
                                            {
                                                entryID:dbData.samples[[i, start_y].toString()].sampleID,
                                                entryType:1,
                                                updatedX:i - 1,
                                                updatedY:start_y,
                                            }
                                        );
                                        dbData.samples[[i - 1, start_y].toString()] = dbData.samples[[i, start_y].toString()];
                                        delete dbData.samples[[i, start_y].toString()];
                                        break;
                                    }
                                }
                            }
                            if (updated) {continue;}
                            gap = gap + 1;
                        }

                    }
                } else {//end_data exists
                    //swap data
                    switch (start_type) {
                        case 0://sublocation move
                            switch (end_type) {
                                case 0: //sublocation swap with sublocation
                                    updatePackage.push(
                                        {
                                            entryID:dbData.children[start_coord].locationID,
                                            entryType:0,
                                            updatedX:end_coord.split(',')[0],
                                            updatedY:end_coord.split(',')[1],
                                        }
                                    );
                                    updatePackage.push(
                                        {
                                            entryID:dbData.children[end_coord].locationID,
                                            entryType:0,
                                            updatedX:start_coord.split(',')[0],
                                            updatedY:start_coord.split(',')[1],
                                        }
                                    );
                                    dbData.children[end_coord] = start_data;
                                    dbData.children[start_coord] = end_data;
                                    break;
                                case 1: //sublocation swap with item
                                    updatePackage.push(
                                        {
                                            entryID:dbData.children[start_coord].locationID,
                                            entryType:0,
                                            updatedX:end_coord.split(',')[0],
                                            updatedY:end_coord.split(',')[1],
                                        }
                                    );
                                    updatePackage.push(
                                        {
                                            entryID:dbData.items[end_coord].itemID,
                                            entryType:1,
                                            updatedX:start_coord.split(',')[0],
                                            updatedY:start_coord.split(',')[1],
                                        }
                                    );
                                    dbData.children[end_coord] = start_data;
                                    dbData.items[start_coord] = end_data;
                                    delete dbData.items[end_coord];
                                    delete dbData.children[start_coord];
                                    break;
                                case 2: //sublocation swap with sample
                                    updatePackage.push(
                                        {
                                            entryID:dbData.children[start_coord].locationID,
                                            entryType:0,
                                            updatedX:end_coord.split(',')[0],
                                            updatedY:end_coord.split(',')[1],
                                        }
                                    );
                                    updatePackage.push(
                                        {
                                            entryID:dbData.samples[end_coord].sampleID,
                                            entryType:2,
                                            updatedX:start_coord.split(',')[0],
                                            updatedY:start_coord.split(',')[1],
                                        }
                                    );
                                    dbData.children[end_coord] = start_data;
                                    dbData.samples[start_coord] = end_data;
                                    delete dbData.samples[end_coord];
                                    delete dbData.children[start_coord];
                                    break;
                            }
                            break;
                        case 1://item move
                            switch (end_type) {
                                case 0: //item swap with sublocation
                                    updatePackage.push(
                                        {
                                            entryID:dbData.items[start_coord].itemID,
                                            entryType:1,
                                            updatedX:end_coord.split(',')[0],
                                            updatedY:end_coord.split(',')[1],
                                        }
                                    );
                                    updatePackage.push(
                                        {
                                            entryID:dbData.children[end_coord].locationID,
                                            entryType:0,
                                            updatedX:start_coord.split(',')[0],
                                            updatedY:start_coord.split(',')[1],
                                        }
                                    );
                                    dbData.items[end_coord] = start_data;
                                    dbData.children[start_coord] = end_data;
                                    delete dbData.children[end_coord];
                                    delete dbData.items[start_coord];
                                    break;
                                case 1: //item swap with item
                                    updatePackage.push(
                                        {
                                            entryID:dbData.items[start_coord].itemID,
                                            entryType:1,
                                            updatedX:end_coord.split(',')[0],
                                            updatedY:end_coord.split(',')[1],
                                        }
                                    );
                                    updatePackage.push(
                                        {
                                            entryID:dbData.items[end_coord].itemID,
                                            entryType:1,
                                            updatedX:start_coord.split(',')[0],
                                            updatedY:start_coord.split(',')[1],
                                        }
                                    );
                                    dbData.items[end_coord] = start_data;
                                    dbData.items[start_coord] = end_data;
                                    break;
                                case 2: //item swap with sample
                                    updatePackage.push(
                                        {
                                            entryID:dbData.items[start_coord].itemID,
                                            entryType:1,
                                            updatedX:end_coord.split(',')[0],
                                            updatedY:end_coord.split(',')[1],
                                        }
                                    );
                                    updatePackage.push(
                                        {
                                            entryID:dbData.samples[end_coord].sampleID,
                                            entryType:2,
                                            updatedX:start_coord.split(',')[0],
                                            updatedY:start_coord.split(',')[1],
                                        }
                                    );
                                    dbData.items[end_coord] = start_data;
                                    dbData.samples[start_coord] = end_data;
                                    delete dbData.samples[end_coord];
                                    delete dbData.items[start_coord];
                                    break;
                            }
                            break;
                        case 2://sample move
                        switch (end_type) {
                            case 0: //sample swap with sublocation
                                updatePackage.push(
                                    {
                                        entryID:dbData.samples[start_coord].sampleID,
                                        entryType:2,
                                        updatedX:end_coord.split(',')[0],
                                        updatedY:end_coord.split(',')[1],
                                    }
                                );
                                updatePackage.push(
                                    {
                                        entryID:dbData.children[end_coord].locationID,
                                        entryType:0,
                                        updatedX:start_coord.split(',')[0],
                                        updatedY:start_coord.split(',')[1],
                                    }
                                );
                                dbData.samples[end_coord] = start_data;
                                dbData.children[start_coord] = end_data;
                                delete dbData.children[end_coord];
                                delete dbData.samples[start_coord];
                                break;
                            case 1: //sample swap with item
                                updatePackage.push(
                                    {
                                        entryID:dbData.samples[start_coord].sampleID,
                                        entryType:2,
                                        updatedX:end_coord.split(',')[0],
                                        updatedY:end_coord.split(',')[1],
                                    }
                                );
                                updatePackage.push(
                                    {
                                        entryID:dbData.items[end_coord].itemID,
                                        entryType:1,
                                        updatedX:start_coord.split(',')[0],
                                        updatedY:start_coord.split(',')[1],
                                    }
                                );
                                dbData.samples[end_coord] = start_data;
                                dbData.items[start_coord] = end_data;
                                delete dbData.items[end_coord];
                                delete dbData.samples[start_coord];
                                break;
                            case 2: //sample swap with sample
                                updatePackage.push(
                                    {
                                        entryID:dbData.samples[start_coord].sampleID,
                                        entryType:2,
                                        updatedX:end_coord.split(',')[0],
                                        updatedY:end_coord.split(',')[1],
                                    }
                                );
                                updatePackage.push(
                                    {
                                        entryID:dbData.samples[end_coord].sampleID,
                                        entryType:2,
                                        updatedX:start_coord.split(',')[0],
                                        updatedY:start_coord.split(',')[1],
                                    }
                                );
                                dbData.samples[end_coord] = start_data;
                                dbData.samples[start_coord] = end_data;
                                break;
                        }
                        break;
                    }
                }
            }
            setLocationData(dbData);
            dragState.start_coord = null;
            //handle API update call
            setLoading(true);
            const response = client.post(
                'update_location_coord', 
                {
                    lab_ID:localStorage.labID,
                    updates:updatePackage
                },
                {headers}
                ).then(function (response) {
                if (response.data.msg) {//show error alert
                    setErrorMsg(response.data.msg);
                    setLoading(false);
                } else{//reset currentLocation State to trigger rerender and updated locationData
                    setErrorMsg(null);
                    props.setLocation(locationData.locationID);
                    setLoading(false);
                }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        } else {
            return;
        }
    }

    return (
        <div
        style={{width:"100%", height:"100%"}}
        key={props.locCoords}
        draggable="true"
        onDragStart={(e)=>handleDragStart(e, props.locCoords)}
        onDragOver={handleDragOver}
        onDrop={(e)=>{handleDrop(e, props.locCoords)}}
        >
            {children}
        </div>
    )
}

export default DraggableLocation;