import { Grid, Container, Typography } from "@mui/material";
import VerticalFreezerRack from "./VerticalFreezerRack";
import React from "react";
import { useState, useEffect } from "react";



function VerticalFreezer (props) {//props include props.locationData
    const [locationData, setLocationData] = props.locationData;
    const [renderData, setRenderData] = useState([]);
    const [dragState, setDragState] = useState({start_coord:null});
    
    useEffect(()=> {
        var boxRender = [];
        for (var i = 1; i < (locationData.limitY + 1); i++) {//row (i) 0 and column (j) 0 are for labels
            var rowRender = [];
            let boxes = 0;
            for (let coordinates in locationData.children){//find sublocations present on current shelf/row
                let position = coordinates.split(","); 
                if(parseInt(position[1])===i){
                    boxes++;
                }
            }
            if(boxes < 4){
                var boxWidth = 11 / 3;//default 3 slots per row
            } else {
                var boxWidth = 11 / boxes;
            }
            for (var j = 1; j < (boxes + 1); j++) {
                    rowRender.push(
                        <VerticalFreezerRack 
                        boxWidth={boxWidth} 
                        filled={true} 
                        errorState={props.errorState}
                        locCoords={`${[j, i].toString()}`}
                        locationDataState={[locationData,setLocationData]}
                        dragState={dragState}
                        mode={props.mode}
                        name={locationData.children[[j, i].toString()].name} 
                        setLocation={props.setLocation} 
                        locationID={locationData.children[[j, i].toString()].locationID}
                        capacity={locationData.children[[j, i].toString()].capacity}
                        used={locationData.children[[j, i].toString()].filled}/>);
            }
            //add empty slot for dropping items at end of row
            rowRender.push(
                <VerticalFreezerRack 
                boxWidth={1} 
                filled={false} 
                errorState={props.errorState}
                setLocation={props.setLocation}
                locCoords={`${[boxes + 1, i].toString()}`}
                locationDataState={[locationData,setLocationData]}
                dragState={dragState}
                mode={props.mode}/>
                );
            if (i === 1) {
                var topBorderThick = 1;
                var bottomBorderThick = 1;
            } else {
                var topBorderThick = 0;
                var bottomBorderThick = 1;
            }
    
            boxRender.push(
                <Grid container 
                direction="row"
                sx={{
                    height:100,
                    borderLeft: `1px solid black`,
                    borderRight: `1px solid black`,
                    borderTop: `${topBorderThick}px solid black`,
                    borderBottom: `${bottomBorderThick}px solid black`,
                }}
                >
                    {rowRender}
                </Grid>);
        }
        setRenderData(boxRender);
    }, [locationData]);
    return (
        <div>
           <Container component="main" maxWidth="xl">
           {renderData}
           </Container>
        </div>
    )
}

export default VerticalFreezer;