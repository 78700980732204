import { useState, useEffect, useContext } from 'react';
import React from 'react';
import { Box, Typography, Dialog, Alert, DialogTitle, DialogContent, Grid, Button, TextField, Select, MenuItem, Tabs, Tab, FormControlLabel, Checkbox, Autocomplete} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingContext } from '../../../App';
import APIServerPath from '../../Constants/APIServerPath';
import FileUpload from '../../FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import TransferList from '../../TransferList';
import axios from 'axios';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import numericInputFilter from '../../../helperFunctions/numericInputFilter';


function ProtocolsTabCard (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const mode = props.mode;//modes are add or update
    const protocolID = props.protocolData.protocolID;
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = useState(null);
    //form data
    const [protocolName, setProtocolName] = useState(props.protocolData.protocolName);
    const [protocolDescription, setProtocolDescription] = useState(props.protocolData.protocolDescription);
    const [modificationComments, setModificationComments] = useState(props.protocolData.modificationComments);
    const [revisionID, setRevisionID] = useState(props.protocolData.revisionID);
    const [files, setFiles] = useState([]);
    const [updateFile, setUpdateFile] = useState(0);
    //Update mode data
    const [revisionRows, setRevisionRows] = useState([]);
    const [selectedNoPermissions, setSelectedNoPermissions] = useState([]);
    const [selectedViewPermissions, setSelectedViewPermissions] = useState([]);
    const [selectedEditPermissions, setSelectedEditPermissions] = useState([]);
    const [userTable, setUserTable] = useState({});
    const [protocolActionRows, setProtocolActionRows] = useState([]);
    const [selectedAddUserAccess, setSelectedAddUserAccess] = useState("");
    const [consumableItems, setConsumableItems] = useState({});
    const [sampleGroups, setSampleGroups] = useState({});
    const [sampleAliquotGroups, setSampleAliquotGroups] = useState({});
    //action modification data
    const [selectedProtocolAction, setSelectedProtocolAction] = useState(null);
    const [actionTargetType, setActionTargetType] = useState(0); //0 = consumables, 1 = samples, 2=aliquots
    const [actionTargetID, setActionTargetID] = useState(""); //0 = consumables, 1 = samples, 2=aliquots
    const [actionOperation, setActionOperation] = useState(null); //0=depletion, 1=addition
    const [actionOperationType, setActionOperationType] = useState(null); //0=Fixed, 1=Variable, 2=N-Based
    const [actionUsageQty, setActionUsageQty] = useState(null); //0=Fixed, 1=Variable, 2=N-Based


    //generate tab rendering
    const [currentTab, setCurrentTab] = useState("0");
    const [tabRenderData, setTabRenderData] = useState([]);
    const tabNames = {
        "0" : "General",
        "1" : "Actions",
        "2" : "Access",
        "3" : "Revision History",
    };

    const handleTabChange = (event, newTab) => {
        setCurrentTab(newTab);
    };

    useEffect(()=> {//tab rendering
        let renderData = [];
        Object.keys(tabNames).forEach(function(key, index) {
            renderData.push(<Tab value={key} label={tabNames[key]}/>);
        });
        setTabRenderData(renderData);
        renderData = [];
    }, [currentTab]);

    const revisionColumns = [
        {
            field: 'protocolName',
            headerName: 'Name',
            flex: 1,
            editable: false,
        },
        {
            field: 'protocolDescription',
            headerName: 'Description',
            flex: 2,
            editable: false,
        },
        {
            field: 'revisionID',
            headerName: 'Revision ID',
            flex: 0.5,
            editable: false,
        },
        {
            field: 'modificationName',
            headerName: 'Modified By',
            flex: 1,
            editable: false,
        },
        {
            field: 'activeDateRange',
            headerName: 'Active Between',
            flex: 1,
            editable: false,
        },
        {
            field: "download",
            headerName: "Download",
            sortable: false,
            width: 140,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.protocolFileToken?
                        <DownloadIcon color="primary" onClick={event =>  window.location.href=`${APIServerPath}/retrieve_file/${params.row.protocolFileToken}`} />
                        :null}
                    </div>
                );
                }
          }
    ];

    const ActionsColumns = [
        {
            field: "actionType",
            headerName: "Action Target",
            sortable: true,
            flex: 2,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.consumableItemID?
                        <React.Fragment>
                            <Typography variant="subtitle1">
                                {consumableItems[params.row.consumableItemID].name}
                            </Typography>
                            <Typography variant="subtitle2">
                                {consumableItems[params.row.consumableItemID].manufacturer}#{consumableItems[params.row.consumableItemID].catNo}
                            </Typography>
                        </React.Fragment>
                        :null}
                        {params.row.sampleGroupID?
                        <Typography variant="subtitle1">
                            {sampleGroups[params.row.sampleGroupID].name}
                        </Typography>
                        
                        :null}
                        {params.row.sampleAliquotGroupID?
                        <Typography variant="subtitle1">
                            {sampleAliquotGroups[params.row.sampleAliquotGroupID].name}
                        </Typography>
                        :null}
                    </div>
                );
            }
        },
        {
            field: "operation",
            headerName: "Action",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.operation === 0?
                        "Depletion"
                        :
                        "Addition"}
                    </div>
                );
            }
        },
        {
            field: "usageQty",
            headerName: "Quantity",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.operationType === 0 && params.row.consumableItemID?//using quotes to prevent true evaluation when compared against null
                        `${params.row.usageQty}${consumableItems[params.row.consumableItemID].quantityUnits}`
                        :null}
                        {params.row.operationType === 1 && params.row.consumableItemID?//using quotes to prevent true evaluation when compared against null
                        `Variable`
                        :null}
                        {params.row.operationType === 2 && params.row.consumableItemID?//using quotes to prevent true evaluation when compared against null
                        `n * ${params.row.usageQty}${consumableItems[params.row.consumableItemID].quantityUnits}`
                        :null}
                    </div>
                );
            }
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <div>
                        <DeleteForeverIcon color="primary" onClick={event =>  handleActionRemoval(params.row.protocolActionsID)} />
                    </div>
                );
            }
        }
    ];
    

    useEffect(()=> {//protocol action editing
        if (!selectedProtocolAction) {
            setActionTargetType(0);
            setActionTargetID("");
            setActionOperation(null);
            setActionOperationType(null);
            setActionUsageQty(null);
        } else {
            if (selectedProtocolAction.consumableItemID) {
                setActionTargetType(0);
                setActionTargetID(selectedProtocolAction.consumableItemID);
            } else if (selectedProtocolAction.sampleGroupID) {
                setActionTargetType(1);
                setActionTargetID(selectedProtocolAction.sampleGroupID);
            } else if (selectedProtocolAction.sampleAliquotGroupID) {
                setActionTargetType(2);
                setActionTargetID(selectedProtocolAction.sampleAliquotGroupID);
            } else {
                setActionTargetID("");
            }
            setActionOperation(selectedProtocolAction.operation);
            setActionOperationType(selectedProtocolAction.operationType);
            setActionUsageQty(selectedProtocolAction.usageQty);
        }
    }, [selectedProtocolAction]);

    useEffect(()=> {//Update array of user access arrays upon selection of new user
        if (selectedAddUserAccess) {
            let tempViewPerms = selectedViewPermissions;
            tempViewPerms.push(selectedAddUserAccess);
            setSelectedViewPermissions(tempViewPerms);
            let tempNoPerms = selectedNoPermissions;
            tempNoPerms.splice(tempNoPerms.indexOf(selectedAddUserAccess), 1);
            setSelectedAddUserAccess("");
        }
    }, [selectedAddUserAccess]);


    const handleActionRemoval = (toBeRemovedID) => {
        let tempActionRows = protocolActionRows.slice();
        for (let i=0; i < tempActionRows.length; i++) {
            if (tempActionRows[i].protocolActionsID === toBeRemovedID){
                let removalIndex = i;
                tempActionRows.splice(removalIndex, 1);
                break;
            }
        }
        setProtocolActionRows(tempActionRows);
    }

    const handleAddAction = () => {
        //find free ids
        let tempActionRows = protocolActionRows.slice();;
        let highestID = 0;
        for (let i=0; i<tempActionRows.length; i++){
            if (+tempActionRows[i].id > highestID) {
                highestID = +tempActionRows[i].id;
            }
        }
        highestID++;
        let newActionRow = {
            id:highestID,
            protocolActionsID:highestID, 
            sampleGroupID:null, 
            sampleAliquotGroupID:null, 
            consumableItemID:null, 
            operation:0, 
            operationType:null, 
            usageQty:0
        };
        tempActionRows.push(newActionRow);
        setProtocolActionRows(tempActionRows);
        setSelectedProtocolAction(newActionRow);
    }

    const handleSaveAction = () => {
        //find correct item in array of actions
        let tempAction = selectedProtocolAction;
        let tempActionRows = protocolActionRows.slice();
        if (actionTargetType === 0) {
            tempAction.consumableItemID = actionTargetID;
            tempAction.sampleGroupID = null;
            tempAction.sampleAliquotGroupID = null;
        } else if (actionTargetType === 1) {
            tempAction.consumableItemID = null;
            tempAction.sampleGroupID = actionTargetID;
            tempAction.sampleAliquotGroupID = null;
        } else if (actionTargetType === 2) {
            tempAction.consumableItemID = null;
            tempAction.sampleGroupID = null;
            tempAction.sampleAliquotGroupID = actionTargetID;
        } 
        tempAction.operation = actionOperation;
        tempAction.operationType = actionOperationType;
        tempAction.usageQty = actionUsageQty;
        for (let i=0;i<tempActionRows.length;i++) {
            if (tempAction.id === tempActionRows[i].id &&  tempAction.protocolActionsID === tempActionRows[i].protocolActionsID) {
                tempActionRows[i] = tempAction;
            break;
            }
        }
        setProtocolActionRows(tempActionRows);
        setSelectedProtocolAction(null);
        setActionTargetID("");
        setActionTargetType(0);
        setActionOperation(null);
        setActionOperationType(null);
        setActionUsageQty(null);
    }

    //Update user data
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.currentTarget);
        formData.append("lab_ID", localStorage.labID);
        formData.append("protocolName", protocolName);
        formData.append("protocolDescription", protocolDescription);
        formData.append("modificationComments", modificationComments);
        formData.append("revisionID", revisionID);
        formData.append("files", files[0]);
        if (mode === "update"){
            formData.append("protocolID", protocolID);
            formData.append("updateFile", +updateFile);
            formData.append("updatedActions", JSON.stringify(protocolActionRows));
            formData.append("updatedNoPerms", selectedNoPermissions);
            formData.append("updatedViewPerms", selectedViewPermissions);
            formData.append("updatedEditPerms", selectedEditPermissions);
            const response = client.post(
                'update_protocol', 
                formData,
                {headers}
                ).then(function (response) {
                    if (response.data.msg) {//show error alert
                        setErrorMsg(response.data.msg);
                        setLoading(false);
                    } else{//save token and name to cookies and redirect to dashboard
                        setErrorMsg(null);
                        setLoading(false);
                        props.close();
                    }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        } else {
            const response = client.post(
                'add_protocol', 
                formData,
                {headers}
                ).then(function (response) {
                    if (response.data.msg) {//show error alert
                        setErrorMsg(response.data.msg);
                        setLoading(false);
                    } else{//save token and name to cookies and redirect to dashboard
                        setErrorMsg(null);
                        setLoading(false);
                        props.close();
                    }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        }
    };

    //retrieve user permissions associated with lab
    useEffect(()=> {
        if (mode === "update") {
            setLoading(true);
            let formData = new FormData();
            formData.append("lab_ID", localStorage.labID);
            formData.append("protocolID", protocolID);
            formData.append("protocolData", 0);//do not retrieve duplicate data
            formData.append("revisionArchive", 1);
            formData.append("protocolActions", 1);
            formData.append("protocolAccess", 1);
            const response = client.post(
                'retrieve_protocol_data', 
                formData,
                {headers}
                ).then(function (response) {
                if (response.data.msg) {//Error messages
                    setErrorMsg(response.data.msg);
                    setLoading(false);
                } else{//
                    setRevisionRows(response.data.revisionArchive);
                    setProtocolActionRows(response.data.protocolActions);
                    //build data for transfer list of protocol access
                    let tempUserTable = {};
                    let tempViewPerms = [];
                    let tempEditPerms = [];
                    let tempNoPerms = [];
                    tempUserTable[""] = {name:""};//prevent no key error in autocomplete when there are no users left
                    for (let i = 0; i < response.data.protocolAccess.length; i++) {
                        tempUserTable[response.data.protocolAccess[i].uID] = {name:response.data.protocolAccess[i].name};
                        if (!response.data.protocolAccess[i].permissions || response.data.protocolAccess[i].permissions === 0){
                            tempNoPerms.push(response.data.protocolAccess[i].uID);
                        } else if (response.data.protocolAccess[i].permissions === 1) {
                            tempViewPerms.push(response.data.protocolAccess[i].uID);
                        } else if (response.data.protocolAccess[i].permissions === 2) {
                            tempEditPerms.push(response.data.protocolAccess[i].uID);
                        }
                    }
                    setSelectedNoPermissions(tempNoPerms);
                    setSelectedViewPermissions(tempViewPerms);
                    setSelectedEditPermissions(tempEditPerms);
                    setUserTable(tempUserTable);
                    console.log(response.data.consumableItems);
                    setConsumableItems(response.data.consumableItems);
                    setSampleGroups(response.data.sampleGroups);
                    setSampleAliquotGroups(response.data.sampleAliquotGroups);
                    setErrorMsg(null);
                    setLoading(false);
                    //if there is no file token, updateFile should be set to True
                    if (!props.protocolData.protocolFileToken) {
                        setUpdateFile(1);
                    }
                }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        }
    }, [protocolID]);

    return (
        <React.Fragment>
            <Dialog
            open={protocolID} 
            onClose={()=>{props.close();}}
            fullWidth
            maxWidth="lg"
            >
                <DialogTitle id="scroll-dialog-title">
                    {(mode === "update") ?
                    "Update Protocol":
                    "Add Protocol"
                    }
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 500, width:'100%'}} component="form" noValidate onSubmit={handleSubmit} justifyContent="center" alignment="center">
                        <Grid 
                        container 
                        justifyContent="center"
                        alignItems="center"
                        sx={{ p: 1}}>
                            {errorMsg ? 
                            <Grid item xs={12} sx={{p:1}}>
                                <Alert severity="error">{errorMsg}</Alert>
                            </Grid>
                            :null}
                            {(mode === "update") ?
                            <Grid item xs={12} sx={{}}>
                                <Tabs 
                                value={currentTab} 
                                onChange={handleTabChange}
                                centered
                                >
                                    {tabRenderData}
                                </Tabs>
                            </Grid>
                            :null}
                            
                            {currentTab==="0" ?
                            <React.Fragment>
                                <Grid item xs={2} sx={{p:2}}>
                                    <Typography variant="h6" align="center">
                                        Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{p:2}}>
                                    <TextField
                                    id="protocol-name"
                                    label="Name"
                                    value={protocolName}
                                    size="small"
                                    onChange={(event) => {
                                        setProtocolName(event.target.value);
                                    }}
                                    fullWidth
                                    />
                                </Grid>
                                <Grid item xs={2} sx={{p:2}}>
                                    <Typography variant="h6" align="center">
                                        Revision ID
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sx={{p:2}}>
                                    <TextField
                                    id="revision-id"
                                    label="Revision ID"
                                    value={revisionID}
                                    size="small"
                                    onChange={(event) => {
                                        setRevisionID(event.target.value);
                                    }}
                                    fullWidth
                                    />
                                </Grid>
                                <Grid item xs={2} sx={{p:2}}>
                                    <Typography variant="h6" align="center">
                                        Description
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} sx={{p:2}}>
                                    <TextField
                                    id="protocol-description"
                                    label="Description"
                                    value={protocolDescription}
                                    onChange={(event) => {
                                        setProtocolDescription(event.target.value);
                                    }}
                                    fullWidth
                                    multiline
                                    />
                                </Grid>
                                <Grid item xs={4} sx={{p:2}}>
                                    <Typography variant="h6" align="center">
                                        Revision Comments
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sx={{p:2}}>
                                    <TextField
                                    id="modification-comments"
                                    label="Revision Comments"
                                    value={modificationComments}
                                    size="small"
                                    onChange={(event) => {
                                        setModificationComments(event.target.value);
                                    }}
                                    fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} sx={{p:2}}>
                                    <Typography variant="h6" align="center">
                                        Protocol File
                                    </Typography>
                                    {mode === "add" ?
                                    <Typography variant="subtitle2" align="center">
                                        Uploading a protocol document is optional
                                    </Typography>
                                    :null
                                    }
                                </Grid>
                                {!props.protocolData.protocolFileToken || updateFile?
                                <Grid item xs={8} sx={{p:2}}>
                                    <FileUpload
                                    files={files}
                                    setFiles={setFiles}
                                    maxFileSize={10000000}//5MB
                                    acceptedFiles={['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']}//images only
                                    setAlerts={setErrorMsg}
                                    invalidMsg="Only pdf, docx, or doc files of sizes < 10 megabytes are allowed!"
                                    />
                                </Grid>
                                :
                                <Grid item xs={8} sx={{p:2}}>
                                    {props.protocolData.protocolFileToken?
                                    <DownloadIcon color="primary" onClick={event =>  window.location.href=`${APIServerPath}/retrieve_file/${props.protocolData.protocolFileToken}`} />
                                    :null
                                    } 
                                </Grid>
                                }
                                {props.protocolData.protocolFileToken ?
                                <Grid item xs={12} sx={{p:2}}>
                                    <FormControlLabel 
                                    control={
                                        <Checkbox
                                        checked={updateFile}
                                        onChange={(event)=>{
                                            setUpdateFile(event.target.checked);
                                        }}
                                        />}
                                    label="Upload new protocol file. This will replace any existing protocol file." 
                                    />
                                </Grid>
                                :null}
                            </React.Fragment>
                            :null}
                            {(mode === "update")&& currentTab==="1" &&
                            <React.Fragment>
                                <Grid 
                                item
                                xs={selectedProtocolAction? 
                                7
                                :12
                                } sx={{p:2}}>
                                    <Box sx={{ height: 350, width: '100%' }}>
                                        <Button 
                                        variant="outlined" 
                                        onClick={()=>{
                                            handleAddAction();
                                        }}>
                                            Create New Action
                                            </Button>
                                        <DataGrid
                                        rows={protocolActionRows}
                                        columns={ActionsColumns}
                                        disableRowSelectionOnClick
                                        onCellDoubleClick={(params, event, details)=> {
                                            //check permissions to modify project information
                                            //set selectedProtocol
                                            setSelectedProtocolAction(params.row);
                                        }}
                                        />
                                    </Box>
                                </Grid>
                                {selectedProtocolAction?
                                <Grid item xs={5} sx={{p:2}}>
                                    <Box sx={{ height: 350, width: '100%' }}>
                                        <Button 
                                        variant="outlined" 
                                        align="right"
                                        onClick={()=>{
                                            handleSaveAction();
                                        }}>
                                            Close Action
                                        </Button>
                                        <Grid container>
                                            <Grid item xs={4} sx={{p:2}}>
                                                <Typography variant="subtitle1" align="center">
                                                    Target Type
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} sx={{p:2}}>
                                                <Select
                                                labelId="select-target-type"
                                                id="select-target-type"
                                                value={actionTargetType}
                                                label="Target Type"
                                                onChange={(event) => {
                                                    setActionTargetType(event.target.value);
                                                    if (event.target.value === 1) {
                                                        setActionOperation(1);
                                                    }
                                                    if (event.target.value != 0) {
                                                        setActionOperationType(null);
                                                        setActionUsageQty(null);
                                                    }
                                                }}
                                                fullWidth
                                                size="small"
                                                >
                                                    <MenuItem value={0}>Consumable Item</MenuItem>
                                                    <MenuItem value={1}>Sample</MenuItem>
                                                    <MenuItem value={2}>Aliquot</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid item xs={4} sx={{p:2}}>
                                                <Typography variant="subtitle1" align="center">
                                                    Target
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} sx={{p:2}}>
                                                {(actionTargetType === 0)?
                                                <Autocomplete
                                                freeSolo
                                                id="protocolActionTarget"
                                                disableClearable
                                                options={Object.keys(consumableItems)}
                                                onChange={(event, targetID) => {
                                                    setActionTargetID(targetID);
                                                }}
                                                getOptionLabel={(option)=>consumableItems[option].name}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <div>
                                                            <Typography variant="subtitle1" align="left">
                                                                {consumableItems[option].name}
                                                            </Typography>
                                                            {consumableItems[option].manufacturer &&
                                                            <div>
                                                                <Typography variant="subtitle2" align="left">
                                                                    {consumableItems[option].manufacturer} # {consumableItems[option].catNo}
                                                                </Typography>
                                                            </div>
                                                            }
                                                        </div>
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Action Target"
                                                        size="small"
                                                        InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                        }}
                                                    />
                                                )}/>
                                                :null} 
                                                {(actionTargetType === 1)?
                                                <Autocomplete
                                                freeSolo
                                                id="protocolActionTarget"
                                                disableClearable
                                                options={Object.keys(sampleGroups)}
                                                onChange={(event, targetID) => {
                                                    setActionTargetID(targetID);
                                                }}
                                                getOptionLabel={(option)=>sampleGroups[option].name}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Action Target"
                                                        size="small"
                                                        InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                        }}
                                                    />
                                                )}/>
                                                :null}
                                                {(actionTargetType === 2)?
                                                <Autocomplete
                                                freeSolo
                                                id="protocolActionTarget"
                                                disableClearable
                                                options={Object.keys(sampleAliquotGroups)}
                                                onChange={(event, targetID) => {
                                                    setActionTargetID(targetID);
                                                }}
                                                getOptionLabel={(option)=>sampleAliquotGroups[option].name}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Action Target"
                                                        size="small"
                                                        InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                        }}
                                                    />
                                                )}/>
                                                :null}
                                            </Grid>
                                            <Grid item xs={4} sx={{p:2}}>
                                                <Typography variant="subtitle1" align="center">
                                                    Operation
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} sx={{p:2}}>
                                                <Select
                                                labelId="select-operation"
                                                id="select-operation"
                                                value={actionOperation}
                                                label="Operation"
                                                onChange={(event) => {
                                                    setActionOperation(event.target.value);
                                                }}
                                                fullWidth
                                                size="small"
                                                >
                                                    {(actionTargetType != 1)&&
                                                    <MenuItem value={0}>Depletion</MenuItem>
                                                    }
                                                    <MenuItem value={1}>Addition</MenuItem>
                                                </Select>
                                            </Grid>
                                            {(actionTargetType === 0)?
                                            <React.Fragment>
                                                <Grid item xs={4} sx={{p:2}}>
                                                    <Typography variant="subtitle1" align="center">
                                                        Operation Type
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8} sx={{p:2}}>
                                                    <Select
                                                    labelId="select-operation-type"
                                                    id="select-operation-type"
                                                    value={actionOperationType}
                                                    label="Operation Type"
                                                    onChange={(event) => {
                                                        setActionOperationType(event.target.value);
                                                    }}
                                                    fullWidth
                                                    size="small"
                                                    >
                                                        <MenuItem value={0}>Fixed</MenuItem>
                                                        <MenuItem value={1}>Variable</MenuItem>
                                                        <MenuItem value={2}>n * Quantity</MenuItem>
                                                    </Select>
                                                </Grid>
                                                {(actionTargetType != 1 && actionOperationType!=1)?
                                                <React.Fragment>
                                                    <Grid item xs={4} sx={{p:2}}>
                                                        <Typography variant="subtitle1" align="center">
                                                            Quantity
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{p:2}}>
                                                        <TextField
                                                        id="action-quantity"
                                                        label="Quantity"
                                                        value={actionUsageQty}
                                                        size="small"
                                                        onChange={(event) => {
                                                            let filteredValue = numericInputFilter(event.target.value, false, true);
                                                            setActionUsageQty(filteredValue);
                                                        }}
                                                        fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} sx={{p:2}}>
                                                        {actionTargetID?
                                                        <Typography variant="subtitle1" align="center">
                                                            {consumableItems[actionTargetID].quantityUnits}
                                                        </Typography>
                                                        :null}
                                                    </Grid>
                                                </React.Fragment>
                                                :null}
                                            </React.Fragment>
                                            :null}
                                        </Grid>
                                    </Box>
                                </Grid>
                                :null}
                            </React.Fragment>
                            }
                            {(mode === "update")&& currentTab==="2" &&
                            <React.Fragment>
                                <Grid item xs={3} sx={{p:2}}>
                                    <Typography variant="h6" align="center">
                                        Add User to Access
                                    </Typography>
                                </Grid>
                                <Grid item xs={9} sx={{p:2}}>
                                    <Autocomplete
                                    freeSolo
                                    id="AddUserAccess"
                                    disableClearable
                                    options={selectedNoPermissions}
                                    onChange={(event, newUID) => {
                                        setSelectedAddUserAccess(newUID);
                                    }}
                                    getOptionLabel={(option) => userTable[option].name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search Users"
                                            InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                            }}
                                        />
                                    )}/>
                                </Grid>
                                <Grid item xs={12} sx={{p:2}}>
                                    <TransferList
                                    left={selectedViewPermissions}
                                    setLeft={setSelectedViewPermissions}
                                    right={selectedEditPermissions}
                                    setRight={setSelectedEditPermissions}
                                    leftLabel="View Only"
                                    rightLabel="View & Edit"
                                    definitions={userTable}
                                    removal = {selectedNoPermissions}
                                    setRemoval = {setSelectedNoPermissions}
                                    />
                                </Grid>
                            </React.Fragment>
                            }
                            {(mode === "update")&& currentTab==="3" &&
                            <Box sx={{ height: 350, width: '100%' }}>
                                <DataGrid
                                    rows={revisionRows}
                                    columns={revisionColumns}
                                    disableRowSelectionOnClick
                                    />
                            </Box>
                            }
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {(mode === "update") ?
                                "Update Protocol":
                                "Add Protocol"
                                }
                            </Button>
                        </Grid>
                        {mode === "update" ? 
                        <Typography variant="h7" align="center">
                            Previously Modified on {props.protocolData.lastModification} by {props.protocolData.modificationName}
                        </Typography>
                        :null}
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
)
}

export default ProtocolsTabCard;