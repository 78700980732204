import { useState, useEffect, useContext } from 'react';
import React from 'react';
import { Box, Typography, Dialog, Alert, DialogTitle, DialogContent, Grid, Button, Divider} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingContext } from '../../../App';
import APIServerPath from '../../Constants/APIServerPath';
import {QRCodeSVG} from 'qrcode.react';
import axios from 'axios';

function InviteUserCard (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = useState(null);
    const [rows, setRows] = React.useState([]);
    const [selectedCode, setSelectedCode] = React.useState(null);
    const columns = [
        {
          field: 'code',
          headerName: 'Invite Code',
          flex: 1,
          editable: false,
        },
        {
          field: 'requester_name',
          headerName: 'Created By',
          flex: 1,
          editable: false,
        },
        {
          field: 'invited_name',
          headerName: 'Used By',
          flex: 1,
          editable: false,
        },
        {
          field: 'creationTime',
          headerName: 'Date Created',
          flex: 1,
          editable: false,
        },
        {
          field: 'active',
          headerName: 'Active',
          flex: 1,
          editable: false,
        },
    ];

    //create new association code
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append("lab_ID", localStorage.labID);
        //permission check here is redundent as the card opening itself is tied to localstorage permissions
        const response = client.post(
            'create_association_code', 
            formData,
            {headers}
            ).then(function (response) {
                if (response.data.msg) {//show error alert
                    setErrorMsg(response.data.msg);
                    setLoading(false);
                } else{//save token and name to cookies and redirect to dashboard
                    console.log(response.data[0]);
                    setSelectedCode(response.data[0]);
                    setErrorMsg(null);
                    setLoading(false);
                }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });
    };

    //retrieve existing association codes
    useEffect(()=> {
        setLoading(true);
        let formData = new FormData();
        formData.append("lab_ID", localStorage.labID);
        const response = client.post(
            'retrieve_association_codes', 
            formData,
            {headers}
            ).then(function (response) {
            if (response.data.msg) {//there should be no error messages from this query
                setErrorMsg(response.data.msg);
                setLoading(false);
            } else{//save new locationID and reset all form values
                setRows(response.data);
                setErrorMsg(null);
                setLoading(false);
            }
            }).catch(function (error) {
            console.log(error, "error")//implement redirecting to error pages
        });
    }, [selectedCode]);

    return (
        <React.Fragment>
            <Dialog
            open={props.open} 
            onClose={props.handleClose}
            fullWidth
            maxWidth="lg"
            >
                <DialogTitle id="scroll-dialog-title">Invite New Users</DialogTitle>
                <DialogContent>
                    <Grid 
                    container 
                    justifyContent="center"
                    alignItems="center"
                    sx={{ p: 2}}>
                        {errorMsg ? 
                        <Grid item xs={12} sx={{p:1}}>
                            <Alert severity="error">{errorMsg}</Alert>
                        </Grid>
                        :null}
                        <Grid item xs={9} />
                        <Grid item xs={3}>
                            <Button variant="outlined" onClick={handleSubmit}>Create New Code</Button>
                        </Grid>
                        <Grid item xs={9} sx={{p:2}}>
                            <Typography variant="h6" align="center">
                                Existing Invite Codes
                            </Typography>

                            <Box sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    disableRowSelectionOnClick
                                    onCellClick={(params, event, details)=> {
                                        //check permissions to modify user information
                                        //set selectedCode
                                        setSelectedCode(params.row);
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={3} sx={{p:2}}>
                            <Typography variant="h6" align="center">
                                QR Preview
                            </Typography>
                            {selectedCode?
                                <Box sx={{ height: 400, width: '100%' }}>
                                    <Divider sx={{mt:0,mb:2}}/>
                                    <Typography variant="subtitle1" align="center">
                                        Code: {selectedCode.code}
                                    </Typography>
                                    <Grid container justifyContent="center" sx={{mt:2}}>
                                        <QRCodeSVG value={selectedCode.code} />
                                    </Grid>
                                </Box>
                            :
                            <Box sx={{ height: 400, width: '100%' }}/>}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
)
}

export default InviteUserCard;