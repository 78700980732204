import React from 'react';
import { Box, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ConsumablesLogFilter = ({ filters, setFilters, fetchLogs }) => {
    return (
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <DatePicker
                selected={filters.startDate}
                onChange={(date) => setFilters({ ...filters, startDate: date })}
                customInput={<TextField label="Start Date" />}
            />
            <DatePicker
                selected={filters.endDate}
                onChange={(date) => setFilters({ ...filters, endDate: date })}
                customInput={<TextField label="End Date" />}
            />
            <FormControl sx={{ width: 200 }}>
                <InputLabel>Action Type</InputLabel>
                <Select
                    value={filters.actionType}
                    onChange={(e) => setFilters({ ...filters, actionType: e.target.value })}
                >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="ADDED ITEM">Added Item</MenuItem>
                    <MenuItem value="ADDED STOCK">Added Stock</MenuItem>
                    <MenuItem value="DISCARDED STOCK">Discarded Stock</MenuItem>
                    <MenuItem value="ADDED STOCK QTY">Added Stock Quantity</MenuItem>
                    <MenuItem value="SUBTRACTED STOCK QTY">Subtracted Stock Quantity</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ width: 200 }}>
                <InputLabel>Entity Type</InputLabel>
                <Select
                    value={filters.entityType}
                    onChange={(e) => setFilters({ ...filters, entityType: e.target.value })}
                >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="ITEM">Item</MenuItem>
                    <MenuItem value="STOCK">Stock</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default ConsumablesLogFilter;
