import { Grid, Box, Typography, Stack, LinearProgress } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import DraggableLocation from "../DraggableLocation";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { IsBoxDraggingContext } from "../../../pages/Locations";

function RackBox (props) {//props include vertical, boxWidth, filled (determines drawing box inside)
    const [progressColor, setProgressColor] = useState("#4BB543");
    const isDragging = useContext(IsBoxDraggingContext);

    useEffect(()=> {//handle progressbar color
        setProgressColor("#4BB543");
        if (props.used / props.capacity * 100 > 60) {//if % slots used >60%, override bar color to orange
            setProgressColor("#FF5F15");
        }//if % slots used >90%, override bar color to orange
        if (props.used / props.capacity * 100 > 90) {//if % slots used >90%, override bar color to red
            setProgressColor("#cc0000");
        }
    }, [props.used]);

    return (
        <Grid 
        item 
        xs={props.boxWidth}
        sx={{
            border: "1px solid black",
            position: "relative",
            width: "100%",
            "&::before": {
                display: "block",
                content: "''",
                paddingBottom: "50%"
            }
        }}>
            <Box
            style={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                p:3
            }}
            >
                {props.filled ?
                    props.mode === "selector"?
                        <Box
                        style={{
                            border: "4px solid DodgerBlue",
                            borderRadius: 12,
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }}
                        onClick={()=> {props.setLocation(props.locationID)}}
                        sx={{m:1, cursor: 'pointer'}}
                        >
                            <Stack>
                                <Typography variant="h6" align="center">
                                    {props.name}
                                </Typography>
                                <Box style={{color:"green"}}>
                                <LinearProgress 
                                variant="determinate"
                                value={props.used/props.capacity * 100}
                                sx={{
                                    height:5,
                                    borderRadius:2.5, 
                                    ml:-2, 
                                    mr:-2,
                                    '> span': { background: `${progressColor}` }
                                    }}/>
                                </Box>
                            </Stack>
                        </Box>
                    :
                        <DraggableLocation
                        locCoords={props.locCoords}
                        locationDataState={props.locationDataState}
                        dragState={props.dragState}
                        errorState={props.errorState}
                        setLocation={props.setLocation} 
                        mode={props.mode}
                        >
                            <Box
                            style={{
                                border: "4px solid DodgerBlue",
                                borderRadius: 12,
                                position: "absolute",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                            }}
                            onClick={()=> {props.setLocation(props.locationID)}}
                            sx={{m:1, cursor: 'pointer'}}
                            >
                                <Stack>
                                    <Typography variant="h6" align="center">
                                        {props.name}
                                    </Typography>
                                    <Box style={{color:"green"}}>
                                    <LinearProgress 
                                    variant="determinate"
                                    value={props.used/props.capacity * 100}
                                    sx={{
                                        height:5,
                                        borderRadius:2.5, 
                                        ml:-2, 
                                        mr:-2,
                                        '> span': { background: `${progressColor}` }
                                        }}/>
                                    </Box>
                                </Stack>
                            </Box>
                        </DraggableLocation>
                :
                isDragging && props.mode != "selector"?
                    <DraggableLocation
                    locCoords={props.locCoords}
                    locationDataState={props.locationDataState}
                    dragState={props.dragState}
                    errorState={props.errorState}
                    setLocation={props.setLocation} 
                    mode={props.mode}
                    >
                        <Box
                        style={{
                            border: "4px solid green",
                            borderRadius: 12,
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }}
                        sx={{m:1, cursor: 'pointer'}}
                        >
                            <AddTwoToneIcon sx={{ color:"green" }} fontSize="large"/>
                        </Box>
                    </DraggableLocation>
                :null
                }
            </Box>
        </Grid>
    )
}

export default RackBox;