import { useState, useEffect, useContext } from 'react';
import React from 'react';
import { Box, Typography, Dialog, Alert, DialogTitle, DialogContent, Grid, Button, TextField, Select, MenuItem, Divider, Checkbox, FormControlLabel} from '@mui/material';
import { LoadingContext } from '../../../App';
import APIServerPath from '../../Constants/APIServerPath';
import axios from 'axios';
import characterInputFilter from '../../../helperFunctions/characterInputFilter';
import numericInputFilter from '../../../helperFunctions/numericInputFilter';

function LabelTemplateTabCard (props) {
    const headers = { 'Authorization': `Bearer ${localStorage.access_token}`, 'Content-Type': 'multipart/form-data' };
    const client = axios.create({
      baseURL:APIServerPath
    });
    const mode = props.mode;
    const templateID = props.templateData.templateID;
    const setLoading = useContext(LoadingContext);
    const [errorMsg, setErrorMsg] = useState(null);

    //form data
    const [templateName, setTemplateName] = React.useState(props.templateData.templateName);
    const [pageHeight, setPageHeight] = React.useState(props.templateData.pageHeight);
    const [pageWidth, setPageWidth] = React.useState(props.templateData.pageWidth);
    const [noColumns, setNoColumns] = React.useState(props.templateData.noColumns);
    const [noRows, setNoRows] = React.useState(props.templateData.noRows);
    const [labelHeight, setLabelHeight] = React.useState(props.templateData.labelHeight);
    const [labelWidth, setLabelWidth] = React.useState(props.templateData.labelWidth);
    const [columnGap, setColumnGap] = React.useState(props.templateData.columnGap);
    const [rowGap, setRowGap] = React.useState(props.templateData.rowGap);
    const [leftMargin, setLeftMargin] = React.useState(props.templateData.leftMargin);
    const [rightMargin, setRightMargin] = React.useState(props.templateData.rightMargin);
    const [topMargin, setTopMargin] = React.useState(props.templateData.topMargin);
    const [bottomMargin, setBottomMargin] = React.useState(props.templateData.bottomMargin);
    const [labelLeftPadding, setLabelLeftPadding] = React.useState(props.templateData.labelLeftPadding);
    const [labelRightPadding, setLabelRightPadding] = React.useState(props.templateData.labelRightPadding);
    const [labelTopPadding, setLabelTopPadding] = React.useState(props.templateData.labelTopPadding);
    const [labelBottomPadding, setLabelBottomPadding] = React.useState(props.templateData.labelBottomPadding);
    const [cornerRadius, setCornerRadius] = React.useState(props.templateData.cornerRadius);
    const [qrSize, setQrSize] = React.useState(props.templateData.qrSize);
    const [qrFix, setQrFix] = React.useState(props.templateData.qrFix);
    const [units, setUnits] = React.useState(props.templateData.units);
    const [border, setBorder] = React.useState(props.templateData.border);

    //form settings
    const [splitPageMargins, setSplitPageMargins] = React.useState(0);
    const [customQrSettings, setCustomQrSettings] = React.useState(0);
    const [customPaddingSettings, setCustomPaddingSettings] = React.useState(0);
    const [customGaps, setCustomGaps] = React.useState(0);
    
    //Update user data
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.currentTarget);
        formData.append("lab_ID", localStorage.labID);
        formData.append("pageHeight", pageHeight);
        formData.append("pageWidth", pageWidth);
        formData.append("noColumns", noColumns);
        formData.append("noRows", noRows);
        formData.append("labelHeight", labelHeight);
        formData.append("labelWidth", labelWidth);
        formData.append("columnGap", columnGap);
        formData.append("rowGap", rowGap);
        formData.append("leftMargin", leftMargin);
        formData.append("rightMargin", rightMargin);
        formData.append("topMargin", topMargin);
        formData.append("bottomMargin", bottomMargin);
        formData.append("labelLeftPadding", labelLeftPadding);
        formData.append("labelRightPadding", labelRightPadding);
        formData.append("labelTopPadding", labelTopPadding);
        formData.append("labelBottomPadding", labelBottomPadding);
        formData.append("cornerRadius", cornerRadius);
        formData.append("qrSize", qrSize);
        formData.append("qrFix", qrFix);
        formData.append("units", units);
        formData.append("border", border);
        formData.append("templateName", templateName);
        if (mode === "update"){
            formData.append("templateID", templateID);
            const response = client.post(
                'update_label_template', 
                formData,
                {headers}
                ).then(function (response) {
                    if (response.data.msg) {//show error alert
                        setErrorMsg(response.data.msg);
                        setLoading(false);
                    } else{//save token and name to cookies and redirect to dashboard
                        setErrorMsg(null);
                        setLoading(false);
                        props.close();
                    }
                }).catch(function (error) {
                console.log(error, "error")//implement redirecting to error pages
            });
        } else {
            const response = client.post(
                'add_label_template', 
                formData,
                {headers}
                ).then(function (response) {
                    if (response.data.msg) {//show error alert
                        setErrorMsg(response.data.msg);
                        setLoading(false);
                    } else{//close card
                        setErrorMsg(null);
                        setLoading(false);
                        props.close();
                    }
                }).catch(function (error) {
                console.log(error, "error");//implement redirecting to error pages
            });
        }
    };

    //handle update mode
    useEffect(()=>{
        if (mode === "update") {
            setSplitPageMargins(1);
            setCustomQrSettings(1);
            setCustomPaddingSettings(1);
            if (columnGap != 0 && rowGap != 0) {
                setCustomGaps(1);
            }
        }    
    }, [mode]);

    //update right, and bottom margins to values of left, and top margins respectively upon disabling splitPageMargins
    useEffect(()=> {
        if (!splitPageMargins && mode === "add") {
            setRightMargin(leftMargin);
            setBottomMargin(topMargin);
        }
    }, [splitPageMargins]);

    //update QR fix and size upon update of custom QR code setting flag, or update on label height or T/B padding values
    useEffect(()=> {
        if (!customQrSettings  && mode === "add") {
            setQrFix("bl");
            let defaultQrSize = labelHeight * (1 - labelTopPadding / 100 - labelBottomPadding /100 );
            defaultQrSize = Math.round(defaultQrSize * 1000) / 1000
            setQrSize(defaultQrSize);
        }
    }, [customQrSettings, labelHeight, labelTopPadding, labelBottomPadding]);

    //update padding to default values upon deselection of setting flag
    useEffect(()=> {
        if (!customPaddingSettings  && mode === "add") {
            setLabelLeftPadding(5);
            setLabelRightPadding(5);
            setLabelTopPadding(5);
            setLabelBottomPadding(5);
        }
    }, [customPaddingSettings]);

    return (
        <React.Fragment>
            <Dialog
            open={templateID} 
            onClose={()=>{props.close();}}
            fullWidth
            required
            maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">
                    {(mode === "update") ?
                    "Update Label Template":
                    "Add Label Template"
                    }
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 600, width:'100%'}} component="form" noValidate onSubmit={handleSubmit} justifyContent="center" alignment="center">
                    <Grid 
                    container 
                    justifyContent="center"
                    alignItems="center"
                    sx={{ p: 1}}>
                        {errorMsg ? 
                        <Grid item xs={12} sx={{p:1}}>
                            <Alert severity="error">{errorMsg}</Alert>
                        </Grid>
                        :null
                        }
                        <Grid item xs={3} sx={{p:1}}>
                            <Typography variant="h7" align="center">
                                Template Name
                            </Typography>
                        </Grid>
                        <Grid item xs={5} sx={{p:1}}>
                            <TextField
                            id="template-name"
                            label="Name"
                            size="small"
                            value={templateName}
                            onChange={(event) => {
                                setTemplateName(event.target.value);
                            }}
                            fullWidth
                            required
                            />
                        </Grid>
                        <Grid item xs={2} sx={{p:1}}>
                            <Typography variant="h7" align="center">
                                Units
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{p:1}}>
                            <Select
                            labelId="select-units"
                            id="select-units"
                            size="small"
                            value={units}
                            label="Units"
                            onChange={(event) => {
                                setUnits(event.target.value);
                            }}
                            fullWidth
                            required
                            >
                                <MenuItem value={"mm"}>mm</MenuItem>
                                <MenuItem value={"in"}>in</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sx={{p:1}}>
                            <Divider textAlign="left">
                                <Typography variant="h6" align="center">
                                    Page Settings
                                </Typography>
                            </Divider>
                        </Grid>
                        <Grid item xs={2} sx={{p:1}}>
                            <Typography variant="h7" align="center">
                                Height
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{p:1}}>
                            <TextField
                            id="page-height"                              
                            label="Height"
                            size="small"
                            value={pageHeight}
                            onChange={(event) => {
                                let filteredValue = numericInputFilter(event.target.value, false, true);
                                setPageHeight(filteredValue);
                            }}
                            fullWidth
                            required
                            />
                        </Grid>
                        <Grid item xs={2} sx={{p:1}}>
                            <Typography variant="h7" align="center">
                                Width
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{p:1}}>
                            <TextField
                            id="page-width"
                            label="Width"
                            size="small"
                            value={pageWidth}
                            onChange={(event) => {
                                let filteredValue = numericInputFilter(event.target.value, false, true);
                                setPageWidth(filteredValue);
                            }}
                            fullWidth
                            required
                            />
                        </Grid>
                        {!splitPageMargins && (mode === "add") ?
                        <React.Fragment>
                            <Grid item xs={2} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    Left/Right Margin
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{p:1}}>
                                <TextField
                                id="left-margin"                              
                                label="Left/Right Margins"
                                size="small"
                                value={leftMargin}
                                onChange={(event) => {
                                    let filteredValue = numericInputFilter(event.target.value, false, true);
                                    setLeftMargin(filteredValue);
                                    setRightMargin(filteredValue);
                                }}
                                fullWidth
                                required
                                />
                            </Grid>
                            <Grid item xs={2} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    Top/Bot. Margin
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{p:1}}>
                                <TextField
                                id="top-margin" 
                                label="Top/Bottom Margins"
                                size="small"
                                value={topMargin}
                                onChange={(event) => {
                                    let filteredValue = numericInputFilter(event.target.value, false, true);
                                    setTopMargin(filteredValue);
                                    setBottomMargin(filteredValue);
                                }}
                                fullWidth
                                required
                                />
                            </Grid>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Grid item xs={2} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    Left Margin
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{p:1}}>
                                <TextField
                                id="left-margin"                              
                                label="Left Margins"
                                size="small"
                                value={leftMargin}
                                onChange={(event) => {
                                    let filteredValue = numericInputFilter(event.target.value, false, true);
                                    setLeftMargin(filteredValue);
                                }}
                                fullWidth
                                required
                                />
                            </Grid>
                            <Grid item xs={2} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    Top Margin
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{p:1}}>
                                <TextField
                                id="top-margin" 
                                label="Top Margins"
                                size="small"
                                value={topMargin}
                                onChange={(event) => {
                                    let filteredValue = numericInputFilter(event.target.value, false, true);
                                    setTopMargin(filteredValue);
                                }}
                                fullWidth
                                required
                                />
                            </Grid>
                            <Grid item xs={2} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    Right Margin
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{p:1}}>
                                <TextField
                                id="right-margin"                              
                                label="Right Margins"
                                size="small"
                                value={rightMargin}
                                onChange={(event) => {
                                    let filteredValue = numericInputFilter(event.target.value, false, true);
                                    setRightMargin(filteredValue);
                                }}
                                fullWidth
                                required
                                />
                            </Grid>
                            <Grid item xs={2} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    Bottom Margin
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{p:1}}>
                                <TextField
                                id="bottom-margin" 
                                label="Bottom Margins"
                                size="small"
                                value={bottomMargin}
                                onChange={(event) => {
                                    let filteredValue = numericInputFilter(event.target.value, false, true);
                                    setBottomMargin(filteredValue);
                                }}
                                fullWidth
                                required
                                />
                            </Grid>
                        </React.Fragment>
                        }
                        {(mode === "add") &&
                        <React.Fragment>
                            <Grid item xs={12} sx={{p:1}}>
                                <FormControlLabel 
                                control={
                                    <Checkbox
                                    checked={splitPageMargins}
                                    onChange={(event)=>{
                                        setSplitPageMargins(event.target.checked);
                                    }}
                                    />
                                } 
                                label="Set Top, Bottom, Left and Right margins individually" 
                                />
                            </Grid>
                        </React.Fragment>
                        }
                        <Grid item xs={2} sx={{p:1}}>
                            <Typography variant="h7" align="center">
                                # Rows
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{p:1}}>
                            <TextField
                            id="no-rows"                              
                            label="# Rows"
                            size="small"
                            value={noRows}
                            onChange={(event) => {
                                let filteredValue = numericInputFilter(event.target.value, false, false);
                                setNoRows(filteredValue);
                            }}
                            fullWidth
                            required
                            />
                        </Grid>
                        <Grid item xs={2} sx={{p:1}}>
                            <Typography variant="h7" align="center">
                                # Columns
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{p:1}}>
                            <TextField
                            id="no-columns"                              
                            label="# Columns"
                            size="small"
                            value={noColumns}
                            onChange={(event) => {
                                let filteredValue = numericInputFilter(event.target.value, false, false);
                                setNoColumns(filteredValue);
                            }}
                            fullWidth
                            required
                            />
                        </Grid>
                        {customGaps?
                        <React.Fragment>
                            <Grid item xs={2} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    Row Gap
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{p:1}}>
                                <TextField
                                id="row-gap"                              
                                label="Row Gap"
                                size="small"
                                value={rowGap}
                                onChange={(event) => {
                                    let filteredValue = numericInputFilter(event.target.value, false, true);
                                    setRowGap(filteredValue);
                                }}
                                fullWidth
                                required
                                />
                            </Grid>
                            <Grid item xs={2} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    Column Gap
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{p:1}}>
                                <TextField
                                id="column-gap"                              
                                label="Column Gap"
                                size="small"
                                value={columnGap}
                                onChange={(event) => {
                                    let filteredValue = numericInputFilter(event.target.value, false, true);
                                    setColumnGap(filteredValue);
                                }}
                                fullWidth
                                required
                                />
                            </Grid>
                        </React.Fragment>
                        :
                        null}
                        <Grid item xs={12} sx={{p:1}}>
                            <FormControlLabel 
                            control={
                                <Checkbox
                                checked={customGaps}
                                onChange={(event)=>{
                                    setCustomGaps(event.target.checked);
                                }}
                                />
                            } 
                            label="Set custom Column/Row Gaps. By default, the labels are evenly distributed column and row-wise with its value set to 0." 
                            />
                        </Grid>
                        <Grid item xs={12} sx={{p:1}}>
                            <Divider textAlign="left">
                                <Typography variant="h6" align="center">
                                    Label Settings
                                </Typography>
                            </Divider>
                        </Grid>
                        <Grid item xs={2} sx={{p:1}}>
                            <Typography variant="h7" align="center">
                                Height
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{p:1}}>
                            <TextField
                            id="label-height"                              
                            label="Height"
                            size="small"
                            value={labelHeight}
                            onChange={(event) => {
                                let filteredValue = numericInputFilter(event.target.value, false, true);
                                setLabelHeight(filteredValue);
                            }}
                            fullWidth
                            required
                            />
                        </Grid>
                        <Grid item xs={2} sx={{p:1}}>
                            <Typography variant="h7" align="center">
                                Width
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{p:1}}>
                            <TextField
                            id="label-width"
                            label="Width"
                            size="small"
                            value={labelWidth}
                            onChange={(event) => {
                                let filteredValue = numericInputFilter(event.target.value, false, true);
                                setLabelWidth(filteredValue);
                            }}
                            fullWidth
                            required
                            />
                        </Grid>
                        <Grid item xs={2} sx={{p:1}}>
                            <Typography variant="h7" align="center">
                                Border
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{p:1}}>
                            <Select
                            labelId="select-template-border"
                            id="select-template-border"
                            size="small"
                            value={border}
                            label="Label Border"
                            onChange={(event) => {
                                setBorder(event.target.value);
                            }}
                            fullWidth
                            required
                            >
                                <MenuItem value={0}>Off</MenuItem>
                                <MenuItem value={1}>On</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={2} sx={{p:1}}>
                            <Typography variant="h7" align="center">
                                Corner Radius
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{p:1}}>
                            <TextField
                            id="corner-radius"
                            label="Corner Radius"
                            size="small"
                            value={cornerRadius}
                            onChange={(event) => {
                                let filteredValue = numericInputFilter(event.target.value, false, true);
                                setCornerRadius(filteredValue);
                            }}
                            fullWidth
                            required
                            />
                        </Grid>
                        {customPaddingSettings?
                        <React.Fragment>
                            <Grid item xs={2} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    Left Padding
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sx={{p:1}}>
                                <TextField
                                id="left-padding"                              
                                label="Left Padding"
                                size="small"
                                value={labelLeftPadding}
                                onChange={(event) => {
                                    let filteredValue = numericInputFilter(event.target.value, false, false);
                                    if ( 100 < +filteredValue ) {
                                        filteredValue = "100";
                                    }
                                    setLabelLeftPadding(filteredValue);
                                }}
                                fullWidth
                                required
                                />
                            </Grid>
                            <Grid item xs={1} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    %
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    Top Padding
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sx={{p:1}}>
                                <TextField
                                id="top-padding"                              
                                label="Top Padding"
                                size="small"
                                value={labelTopPadding}
                                onChange={(event) => {
                                    let filteredValue = numericInputFilter(event.target.value, false, false);
                                    if ( 100 < +filteredValue ) {
                                        filteredValue = "100";
                                    }
                                    setLabelTopPadding(filteredValue);
                                }}
                                fullWidth
                                required
                                />
                            </Grid>
                            <Grid item xs={1} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    %
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    Right Padding
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sx={{p:1}}>
                                <TextField
                                id="right-padding"                              
                                label="Right Padding"
                                size="small"
                                value={labelRightPadding}
                                onChange={(event) => {
                                    let filteredValue = numericInputFilter(event.target.value, false, false);
                                    if ( 100 < +filteredValue ) {
                                        filteredValue = "100";
                                    }
                                    setLabelRightPadding(filteredValue);
                                }}
                                fullWidth
                                required
                                />
                            </Grid>
                            <Grid item xs={1} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    %
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    Bottom Padding
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sx={{p:1}}>
                                <TextField
                                id="bottom-padding"                              
                                label="Bottom Padding"
                                size="small"
                                value={labelBottomPadding}
                                onChange={(event) => {
                                    let filteredValue = numericInputFilter(event.target.value, false, false);
                                    if ( 100 < +filteredValue ) {
                                        filteredValue = "100";
                                    }
                                    setLabelBottomPadding(filteredValue);
                                }}
                                fullWidth
                                required
                                />
                            </Grid>
                            <Grid item xs={1} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    %
                                </Typography>
                            </Grid>
                        </React.Fragment>
                        :
                        null}
                        {(mode === "add")?
                        <Grid item xs={12} sx={{p:1}}>
                            <FormControlLabel 
                            control={
                                <Checkbox
                                checked={customPaddingSettings}
                                onChange={(event)=>{
                                    setCustomPaddingSettings(event.target.checked);
                                }}
                                />
                            } 
                            label="Set custom label padding. By default, the paddings are set to 5% of the label width and height." 
                            />
                        </Grid>
                        :null}
                        <Grid item xs={12} sx={{p:1}}>
                            <Divider textAlign="left">
                                <Typography variant="h6" align="center">
                                    QR Code Settings
                                </Typography>
                            </Divider>
                        </Grid>
                        {customQrSettings ?
                        <React.Fragment>
                            <Grid item xs={2} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    QR Location
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{p:1}}>
                                <Select
                                labelId="select-qr-fix"
                                id="select-qr-fix"
                                size="small"
                                value={qrFix}
                                label="QR Location"
                                onChange={(event) => {
                                    setQrFix(event.target.value);
                                }}
                                fullWidth
                                required
                                >
                                    <MenuItem value={"tl"}>Top Left</MenuItem>
                                    <MenuItem value={"bl"}>Bottom Left</MenuItem>
                                    <MenuItem value={"tr"}>Top Right</MenuItem>
                                    <MenuItem value={"br"}>Bottom Right</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={2} sx={{p:1}}>
                                <Typography variant="h7" align="center">
                                    QR Size
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{p:1}}>
                                <TextField
                                id="qr-size" 
                                label="QR Size"
                                size="small"
                                type="number"
                                value={qrSize}
                                onChange={(event) => {
                                    let filteredValue = numericInputFilter(event.target.value, false, true);
                                    setQrSize(filteredValue);
                                }}
                                fullWidth
                                required
                                />
                            </Grid>
                        </React.Fragment>
                        :
                        null}
                        {(mode === "add") &&
                        <React.Fragment>
                            <Grid item xs={12} sx={{p:1}}>
                                <FormControlLabel 
                                control={
                                    <Checkbox
                                    checked={customQrSettings}
                                    onChange={(event)=>{
                                        setCustomQrSettings(event.target.checked);
                                    }}
                                    />
                                } 
                                label="Set custom QR Settings. By default, QR codes are fixed to the bottom left, with the size of the label height (minus padding)" 
                                />
                            </Grid>
                        </React.Fragment>
                        }
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {(mode === "update") ?
                            "Update Template":
                            "Add Template"
                            }
                        </Button>
                    </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
)
}

export default LabelTemplateTabCard;